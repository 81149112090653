var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{ref:"crudContainerRef",attrs:{"fetch-fn":_vm.getIntegration,"scrollable":false,"create-fn":_vm.addIntegration,"columns":_vm.tableColumns,"show-no-data":false,"as-table":""},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
return [(!_vm.disabled)?_c(_vm.stickySearch ? 'MAffix' : 'div',{tag:"Component",attrs:{"offset-top":_vm.offsetTop},on:{"change":function($event){_vm.searchAffixed = $event}}},[_c('div',{class:{ 'pt-2': _vm.searchAffixed }},[_c('MRow',[_c('MCol',{staticClass:"text-right"},[_c('MButton',{attrs:{"variant":"neutral"},on:{"click":create}},[_vm._v(" "+_vm._s(_vm.$tc('add'))+" "+_vm._s(_vm.$tc('integration'))+" ")])],1)],1),_c('MDivider')],1)]):_vm._e()]}},{key:"form-header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('add'))+" "+_vm._s(_vm.$tc('integration'))+" ")]},proxy:true},{key:"form-items",fn:function(ref){
var integration = ref.item;
return [_c('IntegrationForm',{attrs:{"integration":integration,"group-id":_vm.groupId,"resource":_vm.resource}})]}},{key:"form-actions",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var item = ref.item;
var processing = ref.processing;
return [_c('MButton',{attrs:{"loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$tc('add'))+" ")]),_c('MButton',{staticClass:"ml-2",attrs:{"variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"jiraIssueKey",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('a',{attrs:{"href":item.browseUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.jiraIssueKey)+" ")])])]}},{key:"project",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.projectKey))])]}},{key:"issueType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.issueType))])]}},{key:"priority",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.priority))])]}},{key:"subject",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.subject))])]}},{key:"integrationType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.integrationType))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }