import Moment from 'moment'
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
    end: todayStr,
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
  },
  {
    id: createEventId(),
    title: 'Ashish',
    start: todayStr + 'T12:00:00',
  },
]

export const RESOURCES = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
  },
]

export function createEventId() {
  return String(eventGuid++)
}

export const initialEventAdapter = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      title: `${item.name}: ${item.subject}`,
      start:
        Moment.unix(item.startDate / 1000)
          // .startOf('day')
          // .tz('Asia/Calcutta')
          .utc()
          .unix() * 1000,
      end:
        Moment.unix(item.endDate / 1000)
          .endOf('day')
          // .tz('Asia/Calcutta')
          .utc()
          .unix() * 1000,
      backgroundColor: item.backgroundColor,
      borderColor: item.backgroundColor,
      extendedProps: {
        ...item,
      },
      allDay: false,
    }
  })
}
