<template>
  <a @click="handleTrigger">
    <slot>
      <MButton variant="default">
        {{ $tc('regenerate') }}
      </MButton>
    </slot>
    <FlotoDrawerForm width="40%" :open="isDrawerOpen" @cancel="cancelAction">
      <template v-slot:header>
        {{ `${$tc('recovery')} ${$tc('code', 2)}` }}
      </template>
      <MRow>
        <MCol>
          <div class="mb-2">
            Recovery codes are used to access your account when you cannot
            receive codes from your authentication app.
          </div>
          <div>
            We strongly recommend that you copy or download your recovery codes
            and keep them safe.
          </div>
          <div class="w-1/2 flex flex-col">
            <div
              class="mt-2 py-1 flex flex-col space-between rounded bordered border-1 solid"
            >
              <div
                v-for="chunkCode in mfaRecoveryCode"
                :key="chunkCode"
                class="px-1 flex flex-1"
              >
                <span
                  v-for="code in chunkCode"
                  :key="code"
                  class="px-1 rounded flex flex-1"
                >
                  {{ code }}
                </span>
              </div>
            </div>
            <div class="justify-center my-2 flex flex-1">
              <MButton variant="default" class="mx-1" @click="handleDownload">
                Download
              </MButton>
              <MButton variant="default" class="mx-1" @click="handleCopy">
                Copy
              </MButton>
            </div>
          </div>
        </MCol>
      </MRow>
      <template v-slot:actions="{ submit, hide }">
        <MButton id="cancel-btn" class="mx-1" @click="hide">
          {{ $t('close') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      :processing="confirmProcessing"
      @hide="showModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <MIcon name="key" class="w-full text-primary" size="2x" />
      </template>
      <template v-slot:message>
        {{
          $t('confirm_regenerate_item', {
            item: `${$tc('recovery')} ${$tc('code')}`,
          })
        }}
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
import Chunk from 'lodash/chunk'
import { mfaRegenerateRecoveyCodeApi } from '@src/modules/users/users-api'
import { authComputed } from '@state/modules/auth'
import { getDecryptedPassword } from '@utils/password'
export default {
  name: 'RegenerateRecoveryCodeActionDrawer',
  data() {
    return {
      isDrawerOpen: false,
      showModal: false,
      mfaRecoveryCode: [],
      copyText: '',
      confirmProcessing: false,
    }
  },
  computed: { ...authComputed },
  methods: {
    cancelAction() {
      this.isDrawerOpen = false
    },
    handleTrigger() {
      this.showModal = true
    },
    handleConfirmAction() {
      this.confirmProcessing = true
      mfaRegenerateRecoveyCodeApi(this.accessToken)
        .then((data) => {
          this.showModal = false
          this.isDrawerOpen = true
          const recoveryCodes = data.mfaRecoveryCode
          this.mfaRecoveryCode = Chunk(
            recoveryCodes.map((i) => getDecryptedPassword(i)),
            2
          )
          const text = recoveryCodes.map((i) => getDecryptedPassword(i))
          const copyText = text.reduce((result, code) => {
            return `${result ? `${result}\n${code}` : `${code}`}`
          }, '')
          this.copyText = copyText
        })
        .finally(() => {
          this.showModal = false
          this.confirmProcessing = false
        })
    },
    handleDownload() {
      window.open(
        `api/analytics/download/user/mfarecoverycode?mid=${this.accessToken}`,
        '__blank'
      )
    },
    handleCopy() {
      navigator.clipboard.writeText(this.copyText)
    },
  },
}
</script>
