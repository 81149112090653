var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{attrs:{"gutter":0}},[(!_vm.isPortalLogin)?_c('MCol',{staticClass:"px-2",attrs:{"size":12,"sm":6}},[_c('FlotoFormItem',{attrs:{"id":"do-not-disturb","label":_vm.$t('do_not_disturb')}},[_c('MSwitch',{attrs:{"size":"small","disabled":_vm.isDoNotDisturbDisabled && !_vm.data.doNotDisturb},model:{value:(_vm.data.doNotDisturb),callback:function ($$v) {_vm.$set(_vm.data, "doNotDisturb", $$v)},expression:"data.doNotDisturb"}})],1)],1):_vm._e(),(!_vm.isPortalLogin)?_c('MCol',{staticClass:"px-2",attrs:{"size":12,"sm":6}},[_c('FlotoFormItem',{attrs:{"id":"do-not-disturb","label":((_vm.$tc('schedule')) + " " + (_vm.$t('do_not_disturb')))}},[_c('MSwitch',{attrs:{"size":"small","disabled":_vm.isDoNotDisturbDisabled && !_vm.data.enableDndSchedule},model:{value:(_vm.data.enableDndSchedule),callback:function ($$v) {_vm.$set(_vm.data, "enableDndSchedule", $$v)},expression:"data.enableDndSchedule"}})],1)],1):_vm._e(),(_vm.data.enableDndSchedule)?_c('MCol',{staticClass:"px-2",attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"start-at-picker","label":_vm.$t('start_at'),"rules":{
        required: true,
        nonzero: true,
        // eslint-disable-next-line
        min_date: _vm.Moment().subtract(1, 'minutes').valueOf(),
      }}},[_c('FlotoDatePicker',{attrs:{"disabled":_vm.isDoNotDisturbDisabled,"min-date":_vm.Moment().valueOf()},model:{value:(_vm.data.startAt),callback:function ($$v) {_vm.$set(_vm.data, "startAt", $$v)},expression:"data.startAt"}})],1)],1):_vm._e(),(_vm.data.enableDndSchedule)?_c('MCol',{staticClass:"px-2",attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"start-at-picker","rules":{
        required: true,
        nonzero: true,
        // eslint-disable-next-line
        min_date: _vm.Moment(_vm.data.startAt).add(1, 'milliseconds').valueOf(),
      },"label":_vm.$t('end_at')}},[_c('FlotoDatePicker',{attrs:{"disabled":_vm.isDoNotDisturbDisabled,"min-date":_vm.data.startAt},model:{value:(_vm.data.endAt),callback:function ($$v) {_vm.$set(_vm.data, "endAt", $$v)},expression:"data.endAt"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }