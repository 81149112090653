<template>
  <TreeNode
    :node="node"
    :selected="node.state.selected || node.isActive"
    :tree="tree"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <TreeNodeToggler :state="toggleState" @click.stop="handleToggleNode" />
    <slot />
  </TreeNode>
</template>

<script>
import TreeNode from './tree-node.vue'
import TreeNodeToggler from './tree-node-toggler.vue'

export default {
  name: 'TreeNodeWrapper',
  components: {
    TreeNode,
    TreeNodeToggler,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    tree: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    toggleState() {
      const node = this.node
      const hasChildren = node.hasChildren()
      let toggleState
      if (
        (!hasChildren && node.loadOnDemand) ||
        (hasChildren && !node.state.open)
      ) {
        toggleState = 'closed'
      }
      if (hasChildren && node.state.open) {
        toggleState = 'opened'
      }
      return toggleState
    },
  },
  methods: {
    handleToggleNode() {
      const toggleState = this.toggleState
      if (toggleState === 'closed') {
        this.tree.openNode(this.node)
      } else if (toggleState === 'opened') {
        this.tree.closeNode(this.node)
      }
    },
  },
}
</script>
