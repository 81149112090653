<template>
  <FlotoCrudContainer
    as-table
    :fetch-fn="getAddress"
    :delete-fn="deleteFn"
    :create-fn="createFn"
    :update-fn="updateFn"
    :columns="tableColumns"
    :selectable="selectable"
    :max-allowed-selection="1"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-slot:add-controls="{ create, refreshList }">
      <slot name="control"></slot>
    </template>
    <template v-slot:form>
      <span></span>
    </template>
    <template v-slot:name="{ item, edit }">
      <td class="resource-link">
        <a v-if="item.canEdit" @click="edit">
          {{ item.name }}
        </a>
        <span v-else>{{ item.name }}</span>
      </td>
    </template>
    <template v-slot:streetLine="{ item }">
      <td class="text-ellipsis">
        <span v-if="item.streetLine">
          {{ item.streetLine }}
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:city="{ item }">
      <td class="text-ellipsis">
        <span v-if="item.city">
          {{ item.city }}
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:state="{ item }">
      <td class="text-ellipsis">
        <span v-if="item.state">
          {{ item.state }}
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:country="{ item }">
      <td class="text-ellipsis">
        <span v-if="item.country">
          {{ item.country }}
        </span>
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:actions="{ item, remove }"> </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getAddressApi } from '@modules/purchase-management/api/address-api.js'
export default {
  name: 'AddressSelectionList',
  props: {
    selectable: { type: Boolean, default: false },
    parentModule: { type: String, default: undefined },
    createFn: { type: Function, default: undefined },
    updateFn: { type: Function, default: undefined },
    deleteFn: { type: Function, default: undefined },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          name: `${this.$t('name')}`,
          key: 'name',
        },
        {
          name: `${this.$tc('street')}`,
          key: 'streetLine',
        },
        {
          name: `${this.$t('city')}`,
          key: 'city',
        },
        {
          name: `${this.$tc('state')}`,
          key: 'state',
        },
        {
          name: `${this.$t('country')}`,
          key: 'country',
        },
      ]
    },
  },
  methods: {
    getAddress(limit, offset, sort) {
      return getAddressApi({}, limit, offset, sort)
    },
  },
}
</script>
