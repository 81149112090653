<template>
  <MRow
    :gutter="0"
    class="justify-between items-center overflow-hidden flex-no-wrap"
  >
    <MCol class="min-w-0 flex-1">
      <component
        :is="internalLink && link ? (openInNewTab ? 'a' : 'FlotoLink') : 'div'"
        v-bind="
          internalLink && link
            ? {
                ...(openInNewTab ? {} : { to: hrefLink }),
                ...(openInNewTab ? { target: '_blank', href: hrefLink } : {}),
              }
            : {}
        "
      >
        <h5 class="subject-title font-normal text-ellipsis">
          <slot name="before-title"></slot> {{ name }}
          {{ subject ? subject : '' }}
          <span v-if="markResourceArchived">{{ `(${$tc('archived')})` }}</span>
        </h5>
        <small class="text-neutral text-ellipsis block">
          {{ `${ownerName ? `${$t('by')}: ${ownerName},` : ''}` }}
          {{ createdAt | datetime }}
        </small>
      </component>
    </MCol>
    <MCol
      v-if="externalLink && link"
      auto-size
      class="hover-action-container text-right"
      @click.stop
    >
      <FlotoLink target="_blank" class="icon" :to="link">
        <MIcon name="external-link" />
      </FlotoLink>
    </MCol>
  </MRow>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
export default {
  name: 'ResourceListTitle',
  props: {
    name: { type: String, default: '' },
    subject: { type: String, default: '' },
    createdAt: { type: Number, default: null },
    requesterName: { type: String, default: '' },
    requesterEmail: { type: String, default: '' },
    link: { type: Object, default: undefined },
    externalLink: { type: Boolean, default: false },
    userId: { type: Number, default: undefined },
    openInNewTab: { type: Boolean, default: false },
    // eslint-disable-next-line
    internalLink: { type: Boolean, default: true },
    markResourceArchived: { type: Boolean, default: false },
  },
  computed: {
    ...TechnicianComputed,
    hrefLink() {
      return this.$router.resolve(this.link).href
    },
    ownerName() {
      return (
        this.requesterName ||
        this.requesterEmail ||
        (this.userId ? this.technician(this.userId) || {} : {}).name
      )
    },
  },
}
</script>
