<template>
  <div>
    <AProgress
      id="progress"
      :percent="Number(value)"
      :type="type"
      :width="size"
      :format="format"
      v-bind="$attrs"
    />
  </div>
</template>
<script>
import AProgress from 'ant-design-vue/es/progress'
import 'ant-design-vue/es/progress/style'
export default {
  name: 'ProgressViewer',
  components: { AProgress },
  props: {
    type: { type: String, default: 'circle' },
    value: { type: [String, Number], default: 0 },
    size: { type: [String, Number], default: 40 },
    format: {
      type: Function,
      default: undefined,
    },
  },
}
</script>
