<template>
  <div class="p-2">
    <LMap ref="map" :zoom="zoom" :center="center" @dblclick="onMapClick">
      <LTileLayer
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
      />
      <!-- <VGeosearch v-if="allowSearch" :options="geosearchOptions"></VGeosearch> -->
      <template v-if="(allLocations || []).length">
        <LMarker
          v-for="marker in allLocations"
          :key="marker.id"
          :lat-lng.sync="marker.latLng"
          :icon="icon"
          @click="navigateToAsset(marker)"
        >
          <LTooltip
            v-if="allowTooltip"
            :content="assettooltipContent(marker)"
          />
        </LMarker>
      </template>
      <template v-if="allowLocationSelection">
        <LMarker
          v-if="position.lat && position.lng"
          draggable
          :lat-lng.sync="position"
          :icon="icon"
          @dragstart="dragging = true"
          @dragend="dragging = false"
        >
          <LTooltip
            v-if="allowTooltip && allowLocationSelection"
            :content="tooltipContent"
          />
        </LMarker>
      </template>
    </LMap>
  </div>
</template>
<script>
import { LMap, LMarker, LTileLayer, LTooltip } from 'vue2-leaflet'
import { icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import { flattenRecursive } from '@data/recursive'
import { AssetTypeComputed } from '@state/modules/asset-type'
export default {
  name: 'LeafletGeoMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    // VGeosearch,
  },
  props: {
    coordinates: {
      type: Object,
      default: () => {},
    },
    allLocations: {
      type: Array,
      default() {
        return []
      },
    },
    allowLocationSelection: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowTooltip: { type: Boolean, default: true },
    tooltipData: { type: String, default: undefined },
    allowSearch: { type: Boolean, default: false },
    allowAssetDetailView: { type: Boolean, default: false },
    resolveUserPosition: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      center: [0, 0],
      loading: false,
      userLocation: {},
      address: '',
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      icon: icon({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      }),
      zoom: 2,
      dragging: false,
      catchUserPosition: {},
      position: {
        ...this.coordinates,
      },
      link: {},
    }
  },
  computed: {
    ...AssetTypeComputed,
    tooltipContent() {
      if (this.dragging) return '...'
      if (this.loading) return 'Loading...'
      return `<strong>${(this.address || '').replace(
        ',',
        '<br/>'
      )}</strong> <hr/><strong>lat:</strong> ${
        (this.position || {}).lat
      }<br/> <strong>lng:</strong> ${(this.position || {}).lng}`
    },
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress()
        this.$emit('change', { position: value, address: this.address })
      },
    },
  },
  mounted() {
    this.getUserPosition()
    new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: true,
      showPopup: true,
      marker: {
        icon: this.icon,
      },
      updateMap: true,
      keepResult: true,
      autoClose: true,
      maxMarkers: 1,
      popupFormat: ({ result }) => `${result.label}<br/>
      <hr/>
      <strong>lat:</strong> ${result.y}
      <br/>
      <strong>lng:</strong> ${result.x}`,
    }).addTo(this.$refs.map.mapObject)

    this.$refs.map.mapObject.on('geosearch/showlocation', (value) => {
      this.position = {
        lat: value.location.y,
        lng: value.location.x,
      }
      this.address = value.address
    })
  },
  methods: {
    async getAddress() {
      this.loading = true
      let address
      try {
        const { lat, lng } = this.position
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        )
        if (result.status === 200) {
          const body = await result.json()
          address = body.display_name
        }
      } catch (e) {
        this.catchUserPosition = e
      }
      this.loading = false
      return address
    },
    onMapClick(value) {
      this.position = value.latlng
      this.$emit('currentPosition', {
        ...this.position,
        address: this.address,
      })
    },
    getUserPosition() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }
        },
        (err) => {
          this.catchUserPosition = err
        },
        { enableHighAccuracy: true }
      )
    },
    assettooltipContent(marker) {
      if (this.tooltipData) {
        return `<strong>${this.tooltipData}</strong>`
      }
      if (this.dragging) return '...'
      if (this.loading) return 'Loading...'
      if (marker.assetId) {
        return `<strong>Asset Details</strong>
        <br/>
        <hr/>
        <strong>Asset Id:</strong> ${marker.assetId}<br/>
        <strong>Asset Name:</strong> ${marker.assetName}<br/>
        <strong>Asset Type:</strong>
        ${this.getAssetNameById(marker.assetType)}<br/>
        ${
          marker.locationName
            ? `<strong>Asset Geolocation:</strong><br/> ${marker.locationName}`
            : ''
        }
        `
      }
      return ''
    },
    navigateToAsset(marker) {
      if (this.allowAssetDetailView) {
        this.$router.push(
          this.$modules.getModuleRoute('asset', 'view', {
            params: {
              id: marker.id,
              assetType: this.$constants.ASSET_HARDWARE,
            },
          })
        )
      }
    },
    getAssetNameById(id) {
      if (id) {
        const options = this.assetTypes
        const assetTypeName = flattenRecursive(options).find((i) => i.id === id)
        return assetTypeName.systemName
      }
      return ''
    },
  },
}
</script>
<style lang="less">
.vue2leaflet-map.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: 1;
}

.leaflet-control-geosearch form {
  position: absolute;
  top: 0;
  left: 36px;
  z-index: -1;
  display: none;
  height: auto;
  padding: 0 8px;
  margin: 0;
  background-color: var(--page-background-color);
  background-clip: padding-box;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}

.leaflet-control-geosearch form input {
  width: 100%;
  min-width: 200px;
  height: 30px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-indent: 8px;
  background-color: var(--page-background-color);
  border: none;
  border: none;
  border-radius: 0 4px 4px 0;
  outline: none;
}

.leaflet-bar a {
  display: block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: var(--page-text-color);
  text-align: center;
  text-decoration: none;
  background-color: var(--page-background-color);
  border-bottom: 1px solid #ccc;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  cursor: pointer;
  background-color: var(--action-dropdown-backgroud);
  border-color: #c6c6c6;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: var(--action-dropdown-backgroud);
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
  color: var(--page-text-color);
}

.leaflet-control-geosearch .results {
  background: var(--page-background-color);
}
</style>
