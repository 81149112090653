<template>
  <div
    id="kanban-item"
    class="single-kanban-item w-full"
    :class="{ 'cursor-pointer': !item.archived }"
    :style="
      color
        ? {
            borderLeft: `3px solid ${color}`,
          }
        : {}
    "
    @click="handleEdit"
  >
    <div class="flex">
      <h6 class="text-primary text-ellipsis flex-1">
        <ResourceListTitle
          :name="item.name"
          :subject="item.subject"
          external-link
          :internal-link="false"
          :link="
            !item.archived
              ? getDetailLinkForModule(
                  moduleName,
                  item.id,
                  $modules,
                  moduleName
                ).link
              : undefined
          "
        />
      </h6>
    </div>
    <div class="flex flex-1 items-center justify-between">
      <div class="flex">
        <FlotoPriorityPicker
          disabled
          placeholder="---"
          :value="item.priorityId"
          class="mx-1"
        >
          <template v-slot:trigger="slotData">
            <FlotoDropdownTrigger
              compact-view
              v-bind="slotData"
              :lable="$t('priority')"
              icon-name="priority"
              disabled
              @color="($event) => (color = $event)"
            />
          </template>
        </FlotoPriorityPicker>
        <FlotoDueDatePicker
          v-if="showDueBy(item)"
          class="mx-1"
          compact-view
          :value="
            moduleName === $constants.TASK
              ? (item.timeRange || {}).endTime || 0
              : item.dueBy
          "
          disabled
          :module-name="moduleName"
          :status-id="item.statusId"
          icon-size="lg"
        >
          <template v-slot:after-text>
            <div class="w-full text-ellipsis">
              {{ dueByComputed | datetime }}
            </div>
          </template>
        </FlotoDueDatePicker>
      </div>
      <div class="flex items-center">
        <div v-if="$constants.TASK && item.parentId" class="mx-1">
          <FlotoLink
            id="open-new-tab-link"
            target="_blank"
            :to="
              getDetailLinkForModule(
                item.moduleName,
                item.parentId,
                $modules,
                item.moduleName
              ).link
            "
            :disabled="item.archived"
          >
            {{ item.reference }}
          </FlotoLink>
        </div>
        <FlotoTechnicianPicker
          :size="35"
          :value="item.technicianId"
          disabled
          :group-id="item.groupId"
        >
          <template v-slot:trigger="slotData">
            <FlotoDropdownTrigger
              disabled
              compact-view
              v-bind="slotData"
              :lable="$tc('assignee')"
              icon-name="vector"
            >
              <template v-slot:icon>
                <FlotoUserAvatar
                  :size="25"
                  :avatar="(slotData.currentItem || {}).avatar"
                  :style="{
                    minWidth: `${24}px`,
                  }"
                />
              </template>
            </FlotoDropdownTrigger>
          </template>
        </FlotoTechnicianPicker>
      </div>
    </div>
    <div v-if="isEditing" class="editing-item">
      <!-- <MIcon name="pencil" size="lg" /> -->
    </div>
  </div>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
import { getDetailLinkForModule } from '@utils/get-module-detail-link'
import ResourceListTitle from '@components/resource/resource-list-title'
export default {
  name: 'KanbanItem',
  components: { ResourceListTitle },
  props: {
    item: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
  },
  data() {
    this.getDetailLinkForModule = getDetailLinkForModule
    return {
      color: undefined,
    }
  },
  computed: {
    ...StatusComputed,
    dueByComputed() {
      return Math.abs(this.item.dueBy)
    },
  },
  methods: {
    handleAction(action) {
      this.$emit(action.key)
    },
    handleEdit() {
      if (this.item.archived) {
        return
      }
      const editingItem = {
        ...this.item,
        ...(this.moduleName === this.$constants.REQUEST
          ? {
              requester: (this.item.requesterData || {}).name,
            }
          : {}),
      }
      this.$emit('edit', editingItem)
    },
    getItemStatus(item) {
      return (
        (this[`${this.$constants.TASK}Status`] || []).find(
          (s) => s.key === item.statusId
        ) || {}
      ).systemName
    },
    showDueBy(item) {
      if (this.moduleName === this.$constants.TASK) {
        return (
          !(
            item.closedAt &&
            this.getItemStatus(item) === 'Closed' &&
            item.closedAt < item.timeRange.endTime
          ) && item.timeRange.endTime
        )
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.single-kanban-item {
  background-color: var(--page-background-color);
  border: 1px solid var(--border-color);
  transition: box-shadow 0.2s linear;

  @apply flex flex-col w-full p-2 rounded mb-1 relative;

  &:hover {
    @apply shadow-lg;
  }

  .editing-item {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: var(--primary);
    background-color: var(--extra-faded-page-background-color);

    @apply absolute flex items-center
      justify-center rounded;
  }
}
</style>
