var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoDrawerForm',{attrs:{"open":_vm.isDrawerOpen},on:{"cancel":function($event){_vm.isDrawerOpen = false},"submit":_vm.handleFormSubmit},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{attrs:{"id":"edit-profile","variant":"neutral"},on:{"click":_vm.handleOpenProfile}},[_vm._v(" "+_vm._s(_vm.$t('edit_profile'))+" ")])]},proxy:true},{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.user.name || _vm.user.email)+" ")]},proxy:true},{key:"actions",fn:function(ref){
var hide = ref.hide;
var submit = ref.submit;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"update-btn","loading":_vm.processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":hide}},[_vm._v(_vm._s(_vm.$t('cancel')))])]}}])},[_c('div',{staticClass:"flex flex-col flex-1"},[_c('FlotoFixedView',{attrs:{"gutter":0}},[_c('div',{staticClass:"h-100 flex flex-col"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center"},[_c('div',[_c('FlotoUserAvatar',{attrs:{"size":70,"avatar":_vm.user.avatar}})],1),_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),(_vm.user.email)?_c('small',[_c('a',{attrs:{"href":("mailto:" + (_vm.user.email))}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])]):_c('small',[_vm._v(" "+_vm._s(_vm.user.userLogOnName)+" ")]),(_vm.user.vip)?_c('MTag',{staticClass:"ml-2",attrs:{"size":"large","variant":"orange","closable":false}},[_vm._v(" "+_vm._s(_vm.$t('vip'))+" ")]):_vm._e()],1)]),_c('MTab',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('MTabPane',{key:"profile",attrs:{"tab":_vm.$t('profile')}}),_c('MTabPane',{key:"preference",attrs:{"tab":_vm.$tc('preference', 2)}}),(!_vm.user.ldapUser && _vm.user.loginSource !== 'sso_login')?_c('MTabPane',{key:"security",attrs:{"tab":_vm.$tc('change_password')}}):_vm._e(),(!_vm.isPortalLogin && _vm.hasRequestModule)?_c('MTabPane',{key:"do_not_disturb",attrs:{"tab":("" + (_vm.$tc('do_not_disturb')))}}):_vm._e(),_c('MTabPane',{key:"delegate_approval",attrs:{"tab":("" + (_vm.$tc('delegate_approval')))}})],1)],1),_c('div',{staticClass:"h-100 overflow-hidden"},[_c('FlotoScrollView',[_c('div',{staticClass:"flex flex-col flex-wrap"},[(_vm.currentTab === 'profile')?_c('ProfileForm',{attrs:{"show-security":Boolean(
                    _vm.user.ldapUser && _vm.tenantPrefrences.selfServiceSetting.value
                  )},model:{value:(_vm.userFormData),callback:function ($$v) {_vm.userFormData=$$v},expression:"userFormData"}}):_vm._e(),(_vm.currentTab === 'preference')?_c('PreferenceForm',{on:{"passwordChange":_vm.handlePasswordChange},model:{value:(_vm.preferenceFormData),callback:function ($$v) {_vm.preferenceFormData=$$v},expression:"preferenceFormData"}}):_vm._e(),(_vm.currentTab === 'security')?_c('ChangePasswordForm',{model:{value:(_vm.securityFormData),callback:function ($$v) {_vm.securityFormData=$$v},expression:"securityFormData"}}):_vm._e(),(
                  _vm.currentTab === 'do_not_disturb' &&
                  !_vm.isPortalLogin &&
                  _vm.hasRequestModule
                )?_c('DoNotDisturbForm',{model:{value:(_vm.userFormData),callback:function ($$v) {_vm.userFormData=$$v},expression:"userFormData"}}):_vm._e(),(_vm.currentTab === 'delegate_approval')?_c('DelegateApprovalForm',{attrs:{"disabled":!_vm.user.allowDelegateApproval &&
                  (!_vm.user.superAdminRole ||
                    _vm.myAllowedModules.indexOf('admin.users') === -1)},model:{value:(_vm.delegateApprovalFormData),callback:function ($$v) {_vm.delegateApprovalFormData=$$v},expression:"delegateApprovalFormData"}}):_vm._e()],1)])],1)])])],1),_c('FlotoConfirmModal',{attrs:{"open":_vm.showModal,"prevent-auto-close-on-confirm":"","processing":false},on:{"cancel":_vm.handleConfirmCancel},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('MIcon',{staticClass:"w-full text-primary",attrs:{"name":"key","size":"2x"}})]},proxy:true},{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('unprotected_attachment_password'))+" ")]},proxy:true},{key:"actions",fn:function(ref){
                    var cancel = ref.cancel;
return [_c('MButton',{attrs:{"id":"ok-btn"},on:{"click":function($event){$event.stopPropagation();return cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }