var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{ref:"crudContainerRef",attrs:{"fetch-fn":_vm.getSlaHistory,"scrollable":false,"columns":_vm.tableColumns,"show-no-data":false,"as-table":""},scopedSlots:_vm._u([{key:"add-controls",fn:function(){return [_c('MRow',{staticClass:"mb-4",attrs:{"gutter":0}},[_c('MCol',{staticClass:"m-align-button",attrs:{"size":12}},[_c('span',{staticClass:"mx-1"},[_c('b',[_vm._v(" "+_vm._s(_vm.$tc('total'))+" "+_vm._s(_vm.$tc('slaModule.penalty'))+" "+_vm._s(_vm.$tc('amount'))+": ")]),_vm._v(" "+_vm._s(_vm.totalPenaltyAmount)+" ")])])],1)]},proxy:true},{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"slaName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.slaName))])]}},{key:"slaType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.slaType ? (_vm.slaTypeOptions.find(function (o) { return o.key === item.slaType; }) || {}).text : '---'))])]}},{key:"target",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.target ? (_vm.slaTargateTypeOptions.find(function (o) { return o.key === item.target; }) || {}) .text : '---'))])]}},{key:"targetStatus",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.targetStatus ? ( _vm.slaTargateStatusOptions.find( function (o) { return o.key === item.targetStatus; } ) || {} ).text : '---'))])]}},{key:"updatedBy",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('FlotoUserDrawer',{attrs:{"user-id":item.updatedBy}})],1)]}},{key:"elapsedTime",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("duration")(item.elapsedTime)))])]}},{key:"dueIn",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("duration")(item.dueIn)))])]}},{key:"slaPercentage",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('div',{staticClass:"flex flex-col"},[_vm._v(" "+_vm._s(item.slaPercentage)+"% "),_c('ProgressViewer',{attrs:{"value":item.slaPercentage,"type":"line","show-info":false,"stroke-color":_vm.strokeColor(item)}})],1)])]}},{key:"overdue",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.overdue ? _vm.$tc('yes') : _vm.$tc('no')))])]}},{key:"startTime",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("datetime")(item.startTime)))])]}},{key:"lastPauseTime",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("datetime")(!item.lastPauseTime ? '---' : item.lastPauseTime)))])]}},{key:"stopTime",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("datetime")(!item.stopTime ? '---' : item.stopTime)))])]}},{key:"pauseDuration",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("duration")(item.pauseDuration)))])]}},{key:"breachedDuration",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("duration")(item.breachedDuration)))])]}},{key:"penaltyAmount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.penaltyAmount)?_c('div',{staticClass:"flex"},[_vm._v(" "+_vm._s(("" + (item.penaltyAmount.toFixed(2))))+" "),_c('CurrencyPicker',{attrs:{"value":item.currencyId,"text-only":"","disabled":""}})],1):_c('div',[_vm._v(" --- ")])])]}},{key:"technicianGroup",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.technicianGroup)?_c('FlotoTechnicianGroupPicker',{staticClass:"cursor-default",attrs:{"value":item.technicianGroup,"text-only":"","placeholder":"---","disabled":""}}):_c('span',[_vm._v("---")])],1)]}},{key:"operationalHourType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.operationalHourType ? ( _vm.slaOperationalHourTypeOptions.find( function (o) { return o.key === item.operationalHourType; } ) || {} ).text : '---'))])]}},{key:"updateCount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.updateCount || 0))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }