<script>
import FormHelper from './form-helper'

export default {
  name: 'FormHelperProvider',
  provide() {
    const formScriptContext = {}
    Object.defineProperty(formScriptContext, 'form', {
      enumerable: true,
      get: () => {
        return this.formHelper
      },
    })
    return { formScriptContext }
  },
  data() {
    return {
      formHelper: new FormHelper(),
    }
  },
  render() {
    return (
      this.$scopedSlots.default && this.$scopedSlots.default(this.formHelper)
    )
  },
}
</script>
