<template>
  <MLayoutHeader class="header mainAppHeader absolute w-full headerCustom">
    <MRow :gutter="0">
      <MCol :size="6" class="flex items-center ant-col-sm-4 ant-col-md-6">
        <MTooltip v-if="loggedIn" placement="left">
          <template v-slot:trigger>
            <a
              hrem="javascript:;"
              class="px-2"
              @click="$emit('change', !pinned)"
            >
              <MIcon
                name="bars"
                type="fas"
                size="lg"
                :class="{
                  'text-neutral-dark': theme !== 'dark_theme',
                  'text-white': theme === 'dark_theme',
                }"
              />
            </a>
          </template>
          <span>{{ $t(pinned ? 'collapse' : 'expand') }}</span>
        </MTooltip>
        <component
          :is="linkbackRoute ? 'a' : 'FlotoLink'"
          v-bind="{
            ...(linkbackRoute
              ? {
                  href: linkbackRoute,
                  target: '_blank',
                }
              : {
                  to: homeRoute,
                }),
          }"
          class="m-hide"
        >
          <img :src="companyLogo" height="50" class="ml-4" />
        </component>
        <!-- <h2
          v-if="portalMode"
          style="font-size: 1.4rem"
          class="ml-4 inline-block text-primary mb-0 title-header"
        >
          {{ branding.customerPortalTitle }}
        </h2> -->
      </MCol>
      <MCol :size="6" class="text-right ant-col-sm-8 ant-col-md-6">
        <MRow :gutter="0" class="justify-end rightContainer items-center h-100">
          <!-- <template v-if="isPortalLogin">
            <div
              v-if="branding.email || branding.contactNumber"
              class="box text-primary flex items-center email-contact"
            >
              <div
                v-if="branding.email"
                class="flex w-full items-center justify-end mx-1"
              >
                <MIcon name="envelope" />
                <a :href="`mailto:${branding.email}`" class="ml-1">
                  {{ branding.email }}
                </a>
              </div>
              <div
                v-if="branding.contactNumber"
                class="flex w-full items-center justify-end mx-1"
              >
                <MIcon name="mobile-android" />
                <a :href="`tel:${branding.contactNumber}`" class="ml-1">
                  {{ branding.contactNumber }}
                </a>
              </div>
            </div>
          </template> -->
          <CreateDropdown v-if="loggedIn" :portal-mode="portalMode" />
          <FontSizeHandler
            v-if="getSingleTenantPrefrence('enableApplicatonAccessibility')"
          />
          <div v-if="hasCalendarAccess" id="calendar" class="box">
            <FlotoLink
              id="calendar-view-btn"
              class="text-neutral admin-page-link"
              :to="$modules.getModuleRoute('calendar')"
            >
              <MTooltip placement="top">
                <template v-slot:trigger>
                  <MIcon name="calendar-alt" size="lg" style="padding: 0 2px" />
                </template>
                {{ $tc('calendar') }}
              </MTooltip>
            </FlotoLink>
          </div>
          <div v-if="canAccessChat" id="chat" class="box">
            <MBadge :count="pendingCount">
              <FlotoLink
                class="text-neutral admin-page-link"
                :to="$modules.getModuleRoute('chat-manager')"
              >
                <MIcon name="comments" size="lg" />
              </FlotoLink>
            </MBadge>
          </div>
          <div v-if="canAccessMyChats" id="chat" class="box">
            <FlotoLink
              class="text-neutral admin-page-link"
              :to="$modules.getModuleRoute('support-portal', 'chat-history')"
            >
              <MIcon name="comments" size="lg" />
            </FlotoLink>
          </div>
          <div v-if="canAccessContactOps" id="contactOps-manager" class="box">
            <div style="position: relative">
              <FlotoLink
                style="display: inline-flex; padding: 0.5rem"
                class="text-neutral admin-page-link"
                :to="$modules.getModuleRoute('contactOps-manager')"
              >
                <MIcon name="phone-alt" size="lg" />
              </FlotoLink>
              <FlotoDot
                :bg="contactOpsChannelStatus.color"
                style="position: absolute; top: 0; right: -2px"
              />
            </div>
          </div>
          <div
            v-if="hasNotificationAccess"
            id="notification-support"
            class="box m-hide"
          >
            <NotificationSupport
              :count="notificationCount"
              @onUpdateCount="notificationCount = 0"
            />
          </div>
          <div v-if="hasAdminAccess" id="admin" class="box">
            <FlotoLink
              class="text-neutral admin-page-link"
              :to="$modules.getModuleRoute('admin')"
            >
              <MIcon name="cog" size="lg" />
            </FlotoLink>
            <!-- <a href="javascript:;" class="text-neutral">
              <MIcon name="info-circle" size="lg" />
            </a>
            <MBadge :count="2">
              <a href="javascript:;" class="text-neutral">
                <MIcon name="bell" size="lg" />
              </a>
            </MBadge> -->
          </div>
          <div
            v-if="loggedIn && !isCxoTechnician()"
            id="shortcut-helper"
            class="box m-hide"
          >
            <KeyboardShortcutHelper />
          </div>
          <div v-if="isPortalLogin" id="home" class="box">
            <FlotoLink
              :to="homeRoute"
              as-button
              shape="circle"
              variant="neutral-light"
            >
              <MIcon name="house" />
            </FlotoLink>
          </div>
          <div
            v-if="isPortalLogin && branding.helpViewEnable"
            id="help-view"
            class="box"
          >
            <MTooltip>
              <template v-slot:trigger>
                <MButton
                  variant="neutral-light"
                  :shadow="false"
                  shape="circle"
                  class="text-neutral md-text-base"
                >
                  <a
                    :href="
                      branding.helpViewType === 'url'
                        ? branding.helpViewUrlLink
                        : `/api/public/download/file/${branding.helpViewAttachment[0].refFileName}${getToken}`
                    "
                    target="_blank"
                  >
                    <MIcon name="info-circle" />
                  </a>
                </MButton>
              </template>
              {{ $tc('help') }}
            </MTooltip>
          </div>
          <div
            v-if="!isPortalLogin && branding.helpViewEnableForTechnician"
            id="help-view"
            class="box"
          >
            <MTooltip>
              <template v-slot:trigger>
                <MButton
                  variant="neutral-light"
                  :shadow="false"
                  shape="circle"
                  class="text-neutral md-text-base"
                >
                  <a
                    :href="
                      branding.helpViewTypeForTechnician === 'url'
                        ? branding.helpViewUrlLinkForTechnician
                        : `/api/public/download/file/${branding.helpViewAttachmentForTechnician[0].refFileName}${getToken}`
                    "
                    target="_blank"
                  >
                    <MIcon name="info-circle" />
                  </a>
                </MButton>
              </template>
              {{ $tc('help') }}
            </MTooltip>
          </div>
          <div v-if="loggedIn" id="user-dropdown" class="box">
            <UserDropdown
              :portal-mode="portalMode"
              :customer-portal-title="branding.customerPortalTitle"
              :technician-portal-title="branding.technicianPortalTitle"
            />
          </div>
          <div v-if="portalMode && !loggedIn" class="box flex">
            <FlotoLink
              as-button
              :to="
                $modules.getModuleRoute('auth', 'login', {
                  params: { portalLogin: true },
                })
              "
            >
              {{ $t('login') }}
            </FlotoLink>
            <FlotoLink
              v-if="allowRegistration"
              :to="$modules.getModuleRoute('auth', 'signup')"
              class="ml-2"
              block
              as-button
              variant="primary"
            >
              {{ $t('signup') }}
            </FlotoLink>
          </div>
        </MRow>
      </MCol>
    </MRow>
  </MLayoutHeader>
</template>

<script>
import BrandingMixin from '@src/mixins/branding'
import { isCxoTechnician } from '@utils/auth'
import { PreferenceComputed } from '@state/modules/preference'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import { authComputed, authMethods } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import Bus from '@utils/emitter'
import { transformNotification } from '@data/notification'
import { getDetailLinkForModule } from '@utils/get-module-detail-link'
import { getPendingCountApi } from '@components/chat/chat-api'
import { getNotificationCountApi } from '@components/notification-container/notification-support-api'
import { SsoConfigComputed } from '@state/modules/sso-config'
import {
  UserSecurityConfigMethods,
  UserSecurityConfigComputed,
} from '@state/modules/user-security-config'
import { getCoOpsChannelStatusApi } from '@modules/contactOps-manager/contactOps-manager-api'
import { channelStatusOptions } from '@components/contactOps/helpers/channel-statuses'
import UserDropdown from './user-dropdown'
import CreateDropdown from './create-dropdown'
import KeyboardShortcutHelper from './keyboard-shortcut-helper'
import NotificationSupport from '../notification-container/notification-support'
import { getDelegateApprovalApi } from '@components/layout/profile/api'
import Moment from 'moment'
import { TechnicianComputed } from '@state/modules/technician'
import { getGlobalUserApi } from '@/src/modules/users/users-api'
import FontSizeHandler from './font-size-handler.vue'

export default {
  name: 'Header',
  components: {
    UserDropdown,
    CreateDropdown,
    KeyboardShortcutHelper,
    NotificationSupport,
    FontSizeHandler,
  },
  mixins: [BrandingMixin],
  inject: {
    chatConfigContext: { config: {} },
    contactOpsConfigContext: { config: {} },
  },
  props: {
    portalMode: { type: Boolean, default: false },
    pinned: { type: Boolean, default: false },
  },
  data() {
    this.Moment = Moment
    this.isCxoTechnician = isCxoTechnician
    return {
      pendingCount: 0,
      notificationCount: 0,
      notification: {},
      contactOpsChannelStatus: {},
      fullUser: {},
      pendingNotifications: {},
    }
  },
  computed: {
    ...SsoConfigComputed,
    ...UserSecurityConfigComputed,
    ...SupportPortalConfigComputed,
    ...PreferenceComputed,
    ...authComputed,
    ...LicenseComputed,
    ...TechnicianComputed,
    getToken() {
      return `?mid=${this.loggedIn ? this.accessToken : this.publicAccessToken}`
    },
    canAccessChat() {
      if (this.isPortalLogin) {
        return false
      }
      if (!this.loggedIn) {
        return false
      }
      if (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) === -1
      ) {
        return false
      }
      if (isCxoTechnician()) {
        return false
      }
      const isChatEnabled = this.chatConfigContext.config.enabled
      const supportChat = this.chatEnabled
      return isChatEnabled && supportChat
    },
    canAccessMyChats() {
      if (!this.isPortalLogin) {
        return false
      }
      if (!this.loggedIn) {
        return false
      }
      if (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) === -1
      ) {
        return false
      }
      if (isCxoTechnician()) {
        return false
      }
      const isChatEnabled = this.chatConfigContext.config.enabled
      return isChatEnabled && this.chatConfigContext.config.viewUserChatHistory
    },
    canAccessContactOps() {
      if (this.isPortalLogin) {
        return false
      }
      if (isCxoTechnician()) {
        return false
      }
      const isContactOpsEnabled = this.contactOpsConfigContext.config.enabled
      const supportContactOps = this.contactOpsEnabled
      return isContactOpsEnabled && supportContactOps
    },
    hasAdminAccess() {
      if (this.portalMode) {
        return false
      }
      if (this.loggedIn && this.myAllowedModules.indexOf('admin') >= 0) {
        return true
      }
      return false
    },
    hasNotificationAccess() {
      if (
        this.loggedIn &&
        !isCxoTechnician() &&
        (this.myAllowedModules.indexOf('request') >= 0 ||
          this.myAllowedModules.indexOf('change') >= 0 ||
          this.myAllowedModules.indexOf('problem') >= 0 ||
          this.myAllowedModules.indexOf('release') >= 0 ||
          this.myAllowedModules.indexOf('asset') >= 0 ||
          this.myAllowedModules.indexOf('project') >= 0 ||
          this.myAllowedModules.indexOf('cmdb') >= 0)
      ) {
        return true
      }
      return false
    },
    hasCalendarAccess() {
      if (this.loggedIn && !this.isPortalLogin && !isCxoTechnician()) {
        return true
      }
      return false
    },
  },
  watch: {
    isPortalLogin: {
      immediate: true,
      handler: 'updateLoginDataBasedOnType',
    },
    canAccessContactOps: {
      immediate: true,
      handler: 'getCoOpsChannelStatus',
    },
  },
  created() {
    if (!this.isPortalLogin) {
      const globallyLocaleLoadedHandler = () => {
        this.getDelegateApprovals()
      }
      Bus.$once('app:globallyLocaleLoaded', globallyLocaleLoadedHandler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:globallyLocaleLoaded', globallyLocaleLoadedHandler)
      })
    }
    const handleChatCountUpdated = (payload) => {
      this.pendingCount = payload
    }
    const handleUiNotificationUpdated = (payload) => {
      if (payload.userId === this.user.id) {
        if (this.isPortalLogin && !payload.cPortalSupportedNotification) {
          return undefined
        }
        const message = (payload.title || '').split(' ').join('')
        if (
          Object.keys(this.pendingNotifications || {}).indexOf(message) >= 0
        ) {
          return undefined
        }
        this.pendingNotifications[message] = setTimeout(() => {
          delete this.pendingNotifications[message]
        }, 5000)
        this.$successNotification({
          message: this.$t(payload.title),
          // description: this.$t(payload.title),
          onClick: () => this.handleNotificationClick(payload),
        })
        return (this.notification = payload)
      }
    }
    const handleUiNotificationCountUpdated = (payload) => {
      if (payload.userId === this.user.id) {
        // this.$successNotification({
        //   message: this.$t('success'),
        //   description: this.$t('notification_sent_success'),
        // })
        // return (this.notificationCount = payload.count)
        this.notificationCount = payload.count
      }
    }
    const handelContactOpsChannelStausUpdate = (payload) => {
      this.contactOpsChannelStatus = payload || {}
    }
    Bus.$on('pending_chat_count_updated', handleChatCountUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('pending_chat_count_updated', handleChatCountUpdated)
    })
    Bus.$on('ui_notification_updated', handleUiNotificationUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('ui_notification_updated', handleUiNotificationUpdated)
    })
    Bus.$on('ui_notification_count_updated', handleUiNotificationCountUpdated)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'ui_notification_count_updated',
        handleUiNotificationCountUpdated
      )
    })
    Bus.$on(
      'contactOps_channel_status_update',
      handelContactOpsChannelStausUpdate
    )
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'contactOps_channel_status_update',
        handelContactOpsChannelStausUpdate
      )
    })
    this.fetchUserSecurityConfigs()
  },
  methods: {
    ...authMethods,
    ...UserSecurityConfigMethods,
    getDelegateApprovals() {
      getDelegateApprovalApi(this.user.id).then((data) => {
        this.getUserName(data.userId).then((userName) => {
          let message = ''
          if (
            data.enabled &&
            data.createdBy === this.user.id &&
            Moment().valueOf() > data.startTime
          ) {
            if (
              Moment().valueOf() > data.startTime &&
              Moment().valueOf() < data.endTime
            ) {
              message = `${this.$t('delegate_start_message', {
                user: userName,
                start_date: this.$options.filters.datetime(
                  data.startTime,
                  undefined,
                  false
                ),
              })} ${this.$t('delegate_end_message', {
                end_date: this.$options.filters.datetime(
                  data.endTime,
                  undefined,
                  false
                ),
              })}`
            }
            if (!data.endTime && Moment().valueOf() > data.startTime) {
              message = this.$t('delegate_start_message', {
                user: userName,
                start_date: this.$options.filters.datetime(
                  data.startTime,
                  undefined,
                  false
                ),
              })
            }
            this.$successNotification({
              message: `${this.$tc('delegated')} ${this.$tc('approval')}`,
              description: message,
            })
          }
        })
      })
    },
    getUserName(userId) {
      const promiseList = []
      if (userId) {
        const user = this.technician(userId)
        if (user && user.id) {
          promiseList.push(Promise.resolve(user.name))
        } else {
          promiseList.push(
            getGlobalUserApi(userId, { archived: true }).then((data) => {
              return data.name
            })
          )
        }
      }
      return Promise.all(promiseList)
    },
    handleNotificationClick(payload) {
      const notification = transformNotification(payload)
      if (notification.linkId) {
        const link = getDetailLinkForModule(
          notification.refModel,
          notification.linkId,
          this.$modules,
          notification.type
        )
        if (link.link) {
          return this.$router.push(link.link)
        }
      }
    },
    updateLoginDataBasedOnType() {
      if (this.loggedIn) {
        this.refreshUser()
        getPendingCountApi().then((data) => {
          this.pendingCount = data
        })
        getNotificationCountApi().then((data) => {
          this.notificationCount = data
        })
      }
    },
    getCoOpsChannelStatus(newValue, prevValue) {
      if (this.loggedIn && newValue && newValue !== prevValue) {
        getCoOpsChannelStatusApi().then((data) => {
          const channelData = data.currentCallData || {}
          if (data.currentCallData) {
            if (
              ('userId' in channelData &&
                this.user.id === channelData.userId) ||
              'userId' in channelData === false
            ) {
              Bus.$emit('contactops_channel_updated', channelData)
            }
          }
          if (data && data.status) {
            this.contactOpsChannelStatus = channelStatusOptions().find(
              (s) => s.key === data.status
            )
          }
        })
      }
    },
    onMenuPinnedChange() {
      this.$emit('menu-pin-change')
    },
  },
}
</script>

<style lang="less">
// .mainNavbar {
//   .@{ant-prefix}-layout-sider-children {
//     @apply flex-col flex;
//   }
//   .@{ant-prefix}-menu-item,
//   .@{ant-prefix}-menu-item * {
//     transition: none !important;
//   }
//   .@{ant-prefix}-layout-sider-trigger {
//     display: none;
//   }
//   &.@{ant-prefix}-layout-sider-has-trigger {
//     padding-bottom: 0;
//   }
// }
// .mainMenu {
//   flex: 1;
//   background: none;
//   border-right-width: 0;
//   .exclude-hover {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     span {
//       display: none;
//     }
//   }
//   .@{ant-prefix}-menu-item:not(.exclude-hover):hover,
//   .@{ant-prefix}-menu-item-selected {
//     // color: @primary;
//     background-color: fade(@white, 100%);
//     // stylelint-disable-next-line
//     &::after {
//       right: -5px;
//       width: 10px;
//       background-color: @secondary-yellow;
//       border-radius: 4px;
//       opacity: 1;
//       transform: none !important;
//     }
//   }
// }
// .mainAppHeader {
//   z-index: 10;
//   background: @white;
//   box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.3);
//   .rightContainer {
//     line-height: 0;
//   }
//   .box {
//     @apply px-6 border-r-2 border-l-0 border-t-0 border-b-0 border-neutral-lighter border-solid flex items-center justify-center;

//     height: calc(100% - 1rem);
//     &:last-child {
//       @apply border-r-0;
//     }
//     .@{ant-prefix}-badge {
//       @apply mr-2;
//       a {
//         @apply mr-0;
//       }
//     }
//   }
//   .admin-page-link {
//     &.router-link-active {
//       display: inline-flex;
//       align-items: center;
//       justify-content: center;
//       padding: 0.5rem;
//       color: @white;
//       background-color: @primary-color;
//       border-radius: 50%;
//     }
//   }
//   .email-contact {
//     @apply flex flex-col justify-center;
//     & div:nth-child(1) {
//       @apply text-base mb-1;
//     }
//     & div:nth-child(2) {
//       @apply text-xs;
//     }
//   }
// }
</style>
