<template>
  <component
    :is="asTable ? 'tbody' : 'div'"
    :class="{ 'flex flex-col': !asTable }"
  >
    <template v-if="!asTable">
      <SortableList
        :value="value"
        :disabled="!sortable"
        v-bind="sortableProps"
        tag="div"
        @update="handleChangeOrder"
      >
        <div v-for="(currentItem, index) in value" :key="currentItem.guid">
          <slot
            :item="currentItem"
            :index="index"
            :is-first-item="index === 0"
            :is-last-item="index === value.length - 1"
            :total="value.length"
            :remove="() => removeItem(currentItem.guid)"
            :add="addItem"
            :update="(payload) => updateItem(payload, currentItem.guid)"
          >
            Please Provide item to render
          </slot>
        </div>
      </SortableList>
    </template>
    <template v-else>
      <slot
        v-for="(currentItem, index) in value"
        :item="currentItem"
        :index="index"
        :is-first-item="index === 0"
        :is-last-item="index === value.length - 1"
        :total="value.length"
        :remove="() => removeItem(currentItem.guid)"
        :add="addItem"
        :update="(payload) => updateItem(payload, currentItem.guid)"
      >
        Please Provide item to render
      </slot>
      <slot name="additional-rows"></slot>
    </template>
    <slot
      v-if="canAdd && !disabled && (maxItems ? maxItems > value.length : true)"
      name="add-item"
      :add="addItem"
    >
      <div class="mt-2">
        <MButton outline :size="buttonSize" @click="addItem">
          <MIcon v-if="showIcon" class="mx-1" name="plus-circle" />
          {{ addBtnText }}
        </MButton>
      </div>
    </slot>
  </component>
</template>
<script>
import FindIndex from 'lodash/findIndex'
import { generateId } from '@utils/id'
import SortableList from '@components/sortable/sortable-list'

export default {
  name: 'MultipleFormItem',
  components: {
    SortableList,
  },
  model: { event: 'change' },
  props: {
    disabled: { type: Boolean, default: false },
    value: {
      type: [Array, String],
      default() {
        return []
      },
    },
    // eslint-disable-next-line
    showIcon: { type: Boolean, default: true },
    addBtnText: { type: String, default: 'Add' },
    maxItems: { type: Number, default: undefined },
    buttonSize: { type: String, default: undefined },
    // eslint-disable-next-line
    canAdd: { type: Boolean, default: true },
    asTable: { type: Boolean, default: false },
    sortable: { type: Boolean, default: false },
    sortableProps: {
      type: Object,
      default() {
        return {}
      },
    },
    sortKey: {
      type: String,
      default: 'order',
    },
    defaultData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    addItem(payload = {}) {
      if (this.maxItems && this.maxItems <= this.value.length) {
        return
      }
      const newItemOrder = this.value.length + 1
      const data = {
        ...payload,
        ...this.defaultData,
        ...(this.sortable ? { [this.sortKey]: newItemOrder } : {}),
      }
      this.$emit('change', [...this.value, { guid: generateId(), ...data }])
    },
    removeItem(guid) {
      this.$emit(
        'change',
        this.value.filter((i) => guid !== i.guid)
      )
    },
    updateItem(value, guid) {
      const index = FindIndex(this.value, { guid })
      if (index !== -1) {
        this.$emit('change', [
          ...this.value.slice(0, index),
          { ...this.value[index], ...value },
          ...this.value.slice(index + 1),
        ])
      }
    },
    handleChangeOrder($event) {
      const sortKey = this.sortKey
      this.$emit(
        'change',
        $event.items.map((i, index) => ({ ...i, [sortKey]: index + 1 }))
      )
      this.$emit(
        'order-change',
        $event.items.map((i, index) => ({ ...i, [sortKey]: index + 1 }))
      )
    },
  },
}
</script>
