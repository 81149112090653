<template>
  <MRow :gutter="0">
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="name"
        v-model="data.name"
        :label="$t('name')"
        rules="required"
      />
    </MCol>
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="contact-no"
        v-model="data.contactNo"
        :label="$t('contact_no')"
        :placeholder="$t('contact_no')"
        rules="contact_number"
      />
    </MCol>
    <template v-if="!isPortalLogin">
      <MCol :size="12" :sm="6" class="px-2">
        <FlotoFormItem id="location-picker" :label="$tc('location')">
          <FlotoLocationPicker
            v-model="data.location"
            :placeholder="$tc('location')"
            class="w-full"
          />
        </FlotoFormItem>
      </MCol>
      <MCol :size="12" :sm="6" class="px-2">
        <FlotoFormItem id="department-picker" :label="$tc('department')">
          <FlotoDepartmentPicker v-model="data.department" as-input />
        </FlotoFormItem>
      </MCol>
    </template>
    <MCol :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="timezone-picker"
        rules="required"
        :label="$t('timezone')"
      >
        <FlotoTimeZonePicker v-model="data.timezone" class="w-full" />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="px-2">
      <FlotoFormItem id="language-selector" :label="$t('language_preference')">
        <FlotoLanguageSelector v-model="data.userLanguage" />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="px-2">
      <FlotoFormItem
        rules="required"
        :label="`${$tc('date')} ${$tc('time')} ${$tc('format')}`"
      >
        <FormatSelector v-model="data.dateTimeFormat" format="dateTime" />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="px-2">
      <FlotoFormItem
        rules="required"
        :label="`${$tc('date')} ${$tc('format')}`"
      >
        <FormatSelector v-model="data.dateFormat" format="date" />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="px-2">
      <FlotoFormItem
        rules="required"
        :label="`${$tc('time')} ${$tc('format')}`"
      >
        <FormatSelector v-model="data.timeFormat" format="time" />
      </FlotoFormItem>
    </MCol>
    <MCol
      v-if="user.isSuperAdmin && enabledMfa && user.mfaEnrolled"
      class="px-2"
    >
      <FlotoFormItem
        id="regenerate-recovery-code"
        :label="`${$t('recovery')} ${$t('code')}`"
      >
        <RegenerateRecoveryCodeActionDrawer />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="user.userType === 'technician'" :size="12" class="px-2">
      <FlotoFormItem id="technician-picker" :label="$t('signature')">
        <FlotoRichEditor
          v-model="data.signature"
          :max-height="150"
          :affix-toolbar="false"
          :placeholder="$t('signature')"
        />
      </FlotoFormItem>
    </MCol>
    <MDivider v-if="showSecurity" />
    <SecurityQuestionAnswerList v-if="showSecurity" class="px-2" />
  </MRow>
</template>

<script>
import FormatSelector from '@components/date-format/format-selector'
import { UserSecurityConfigComputed } from '@state/modules/user-security-config'
import { getTenantPrefrencesApi } from '@modules/organization/tenant-prefrences-api'
import SecurityQuestionAnswerList from './security-question-answer-list'
import { authComputed } from '@state/modules/auth/'
import { LicenseComputed } from '@state/modules/license'
import RegenerateRecoveryCodeActionDrawer from './regenerate-recovery-code-action-drawer.vue'
export default {
  name: 'ProfileForm',
  components: {
    SecurityQuestionAnswerList,
    RegenerateRecoveryCodeActionDrawer,
    FormatSelector,
  },
  model: { event: 'change' },
  props: {
    value: { type: Object, required: true },
    showSecurity: { type: Boolean, default: false },
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    ...authComputed,
    ...LicenseComputed,
    ...UserSecurityConfigComputed,
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
  },
  created() {
    getTenantPrefrencesApi().then((data) => {
      this.formData = { ...data }
    })
  },
}
</script>
