<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="isDrawerOpen"
      @submit="handleFormSubmit"
      @cancel="cancelAction"
    >
      <template v-slot:header>
        {{ $tc('security_question_answer') }}
      </template>
      <div v-for="(item, index) of securityQuestions" :key="index + 1">
        <MRow>
          <MCol :size="12">
            <FlotoFormItem
              rules="required"
              :label="`${$tc('question')} ${$tc(index + 1)}`"
            >
              <FlotoDropdownPicker
                v-model="item.key"
                :options="questions"
                as-input
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol :size="6">
            <PasswordInput
              v-model="item.value"
              :placeholder="$tc('answer')"
              :label="$tc('answer')"
              :validation-label="$tc('answer')"
              rules="min:5|required|trim|short_text"
              :vid="`answer${index + 1}`"
            >
            </PasswordInput>
          </MCol>
          <MCol :size="6">
            <PasswordInput
              v-model="item.confirm_answer"
              :vid="`confirm${index + 1}`"
              :placeholder="$tc('confirm_answer')"
              :label="$tc('confirm_answer')"
              :validation-label="$tc('confirm_answer')"
              :rules="
                `min:5|short_text|trim|required|confirmed:answer${index + 1}`
              "
            >
            </PasswordInput>
          </MCol>
        </MRow>
      </div>
      <template v-slot:actions="{ hide, submit }">
        <MButton
          variant="primary"
          :loading="processing"
          class="ml-2"
          @click="submit"
        >
          {{ $t('update') }}
        </MButton>
        <MButton variant="default" class="ml-2" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { getQuestionsApi } from '@modules/it-infrastructure/it-infrastructure-api'
import PasswordInput from '@components/password-input'
import { updateUserProfileApi } from '@state/modules/auth/api'

export default {
  name: 'SecurityQuestionAnswerDrawer',
  components: { PasswordInput },
  props: {
    configuredQuestions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      questions: [],
      processing: false,
      securityQuestions: [],
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.getQuestions()
        this.isDrawerOpen = true
        this.securityQuestions = [
          {
            key: this.configuredQuestions.length
              ? this.configuredQuestions[0].name
              : '',
            value: '',
            confirm_answer: '',
          },
          {
            key: this.configuredQuestions.length
              ? this.configuredQuestions[1].name
              : '',
            value: '',
            confirm_answer: '',
          },
        ]
      }, 400)
    },
    cancelAction() {
      this.isDrawerOpen = false
    },

    getQuestions() {
      getQuestionsApi().then((data) => {
        this.questions = data.items.map((i) => ({ text: i.text, key: i.key }))
      })
    },

    handleFormSubmit() {
      this.processing = true
      let data = this.securityQuestions.map((q) => {
        return {
          key: q.key,
          value: q.value,
        }
      })
      updateUserProfileApi({
        securityQuestions: data,
        displaySecurityPopup: false,
      })
        .then(() => {
          this.$emit('refresh')
          this.isDrawerOpen = false
        })
        .finally((this.processing = false))
    },
  },
}
</script>
