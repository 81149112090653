<template>
  <div
    class="item-action"
    :class="{ 'sticky-action': disabled ? true : actionSticked }"
  >
    <slot :stick-action="stickAction" :unstick-action="unStickAction"></slot>
  </div>
</template>

<script>
export default {
  name: 'HoverableAction',
  props: { disabled: { type: Boolean, default: false } },
  data() {
    return {
      actionSticked: false,
    }
  },
  methods: {
    stickAction() {
      this.actionSticked = true
    },
    unStickAction() {
      this.actionSticked = false
    },
  },
}
</script>
