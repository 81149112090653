<template>
  <MTooltip placement="top">
    <template v-slot:trigger>
      <a href="javascript:;">
        <MPopover
          ref="popover"
          transition-name="slide-up"
          overlay-class-name="picker-overlay"
          placement="bottomLeft"
          @show="handleShow"
        >
          <template v-slot:trigger>
            <a
              href="javascript:;"
              :class="isSaved ? 'text-primary' : 'text-neutral-light'"
            >
              <MIcon name="star" :type="isSaved ? 'fas' : 'fal'" size="lg" />
            </a>
          </template>
          <div class="flex-1 overflow-hidden">
            <MCol class="p-4">
              <FlotoForm
                ref="form"
                class="h-100 min-h-0 flex flex-col"
                layout="vertical"
                @submit="handleSubmit"
              >
                <FlotoFormItem
                  v-model="data.name"
                  auto-focus
                  :placeholder="$t('name')"
                  :label="`${$t('save')} ${$t('search')}`"
                  :validation-label="$t('name')"
                  rules="required"
                />
                <FlotoFormItem :label="$t('visibility')">
                  <MRadioGroup
                    v-model="data.scope"
                    size="small"
                    :options="visibilityOptions"
                  />
                </FlotoFormItem>
                <FlotoFormItem
                  v-if="data.scope === 'technician_in_group'"
                  :label="$tc('group')"
                  rules="required"
                >
                  <FlotoTechnicianGroupPicker
                    v-model="data.group"
                    as-input
                    :placeholder="`${$t('select')} ${$tc('group')}`"
                  />
                </FlotoFormItem>
                <template v-slot:submit>
                  <div class="py-2">
                    <MCol class="text-right">
                      <MButton
                        :disabled="!data.name"
                        outline
                        size="small"
                        type="button"
                        class="mr-2"
                        @click="handleSaveAs"
                      >
                        {{ $t('save_as') }}
                      </MButton>
                      <MButton
                        v-if="search.canUpdate"
                        :disabled="!data.name"
                        outline
                        size="small"
                        class="mr-2"
                        type="submit"
                      >
                        {{ $t(search ? 'update' : 'save') }}
                      </MButton>
                      <MButton outline size="small" @click="cancel">
                        {{ $t('cancel') }}
                      </MButton>
                    </MCol>
                  </div>
                </template>
              </FlotoForm>
            </MCol>
          </div>
        </MPopover>
      </a>
    </template>
    {{ $t(searchLabel ? 'update' : 'save') }} {{ $t('search') }}
  </MTooltip>
</template>

<script>
export default {
  name: 'SaveSearch',
  props: {
    search: { type: Object, default: undefined },
    searchLabel: { type: String, default: undefined },
    isSaved: { type: Boolean, default: false },
  },
  data() {
    this.visibilityOptions = [
      { text: this.$t('my_self'), value: 'my_self' },
      {
        text: `${this.$t('all')} ${this.$tc('technician', 2)}`,
        value: 'all_technician',
      },
      {
        text: `${this.$tc('technician', 2)} ${this.$t('in')} ${this.$tc(
          'group'
        )}`,
        value: 'technician_in_group',
      },
    ]
    return {
      data: {
        name: (this.search || {}).canUpdate ? (this.search || {}).name : '',
        scope: (this.search || {}).scope || 'my_self',
        group: (this.search || {}).group,
      },
      useSaveAs: false,
    }
  },
  methods: {
    handleSaveAs() {
      this.useSaveAs = true
      this.$refs.form.submit()
    },
    handleSubmit() {
      this.$refs.popover.hide()
      this.$emit(this.useSaveAs ? 'save-as' : 'save', this.data)
      this.$nextTick(() => (this.useSaveAs = false))
    },
    cancel() {
      this.$refs.popover.hide()
    },
    handleShow() {
      this.data = {
        name: this.search.canUpdate ? this.search.name : '',
        scope: this.search.scope || 'my_self',
        group: this.search.group,
      }
    },
  },
}
</script>
