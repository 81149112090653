var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',_vm._b({ref:"list",attrs:{"as-table":"","scrollable":_vm.scrollable,"fetch-fn":_vm.fetchUserFn,"delete-fn":_vm.deleteUserFn,"columns":_vm.tableColumns,"selectable":_vm.selectable},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
return [(_vm.searchable)?_c('MRow',{staticClass:"items-center",attrs:{"gutter":0}},[_c('MCol',{staticClass:"flex items-center mb-4",attrs:{"size":12}},[_c('div',{staticClass:"flex-1"},[_c('FlotoSearchBar',{ref:"searchBar",attrs:{"allow-keywords":false,"allow-save-search":false,"persist-criteria":false,"module-name":_vm.$constants.USER,"exclude-search-params":_vm.excludedSearchParams},on:{"change":function($event){return _vm.applyCriteria($event, refreshList)}}})],1)])],1):_vm._e()]}},{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.email || '---')+" ")])]}},{key:"userLogOnName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.userLogOnName || '---')+" ")])]}},{key:"department",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('FlotoDepartmentPicker',{attrs:{"value":item.department,"placeholder":"---","disabled":"","text-only":""}})],1)]}},{key:"location",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.location)?_c('FlotoLocationPicker',{attrs:{"value":item.location,"placeholder":"---","size":"small","disabled":"","text-only":""}}):_c('span',[_vm._v("---")])],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
var remove = ref.remove;
return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",function(){return [_c('FlotoDeleteBtn',{class:{ invisible: !item.canDelete },attrs:{"message":_vm.$t('confirm_remove_item', { item: _vm.$tc('user') })},on:{"confirm":remove}})]},{"item":item,"stickAction":function () { return _vm.stickActionFor(item); },"unstickAction":function () { return _vm.unStickActionFor(item); }})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }