<template>
  <div class="ant-upload-list ant-upload-list-text">
    <div class="flex flex-wrap">
      <span v-for="file in fileList" :key="file.refFileName">
        <div
          class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text"
        >
          <div class="ant-upload-list-item-info">
            <span>
              <i
                aria-label="icon: paper-clip"
                class="anticon anticon-paper-clip"
              >
                <svg
                  viewBox="64 64 896 896"
                  data-icon="paper-clip"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""
                >
                  <path
                    d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"
                  ></path>
                </svg>
              </i>
              <a
                target="_blank"
                rel="noopener noreferrer"
                :title="file.realName"
                :href="file.url"
                class="ant-upload-list-item-name ant-upload-list-item-name-icon-count-1"
                >{{ file.realName }}</a
              >
              <span class="ant-upload-list-item-card-actions">
                <a
                  :title="$t('preview')"
                  :href="file.previewUrl"
                  target="_blank"
                >
                  <i aria-label="icon: eye-o" class="anticon anticon-eye-o">
                    <svg
                      viewBox="64 64 896 896"
                      data-icon="eye"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class=""
                    >
                      <path
                        d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                      ></path>
                    </svg>
                  </i>
                </a>
                <a
                  v-if="!disabled"
                  :title="$tc('delete')"
                  @click="() => $emit('remove', file)"
                >
                  <i
                    aria-label="icon: delete"
                    tabindex="-1"
                    class="anticon anticon-delete"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class=""
                    >
                      <path
                        d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                      ></path>
                    </svg>
                  </i>
                </a>
              </span>
            </span>
          </div>
        </div>
      </span>
      <!-- ************************ uploading file list **************************** -->
      <span v-for="file in uploadingFileList" :key="file.name">
        <div
          class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text"
        >
          <div class="ant-upload-list-item-info">
            <span>
              <i
                v-if="!file.error"
                aria-label="icon: loading"
                class="anticon anticon-loading"
                style="top: 3px"
                ><svg
                  viewBox="0 0 1024 1024"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="anticon-spin"
                >
                  <path
                    d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
                  ></path>
                </svg>
              </i>
              <a
                target="_blank"
                rel="noopener noreferrer"
                :title="file.name"
                class="ant-upload-list-item-name ant-upload-list-item-name-icon-count-1"
              >
                <span :class="{ 'text-secondary-red': file.error }">
                  {{ file.name }}
                </span>
              </a>
              <span class="ant-upload-list-item-card-actions">
                <a
                  :title="$tc('delete')"
                  @click="() => $emit('remove-uploading', file)"
                >
                  <i
                    aria-label="icon: delete"
                    tabindex="-1"
                    class="anticon anticon-delete"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class=""
                    >
                      <path
                        d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                      ></path>
                    </svg>
                  </i>
                </a>
              </span>
            </span>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileList',
  model: {
    event: 'change',
  },
  props: {
    fileList: {
      type: Array,
      default() {
        return []
      },
    },
    uploadingFileList: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: { type: Boolean, default: false },
  },
}
</script>
<style></style>
