var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',_vm._b({attrs:{"as-table":"","fetch-fn":_vm.getAddress,"delete-fn":_vm.deleteFn,"create-fn":_vm.createFn,"update-fn":_vm.updateFn,"columns":_vm.tableColumns,"selectable":_vm.selectable,"max-allowed-selection":1},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
var refreshList = ref.refreshList;
return [_vm._t("control")]}},{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[(item.canEdit)?_c('a',{on:{"click":edit}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"streetLine",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.streetLine)?_c('span',[_vm._v(" "+_vm._s(item.streetLine)+" ")]):_c('span',[_vm._v("---")])])]}},{key:"city",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.city)?_c('span',[_vm._v(" "+_vm._s(item.city)+" ")]):_c('span',[_vm._v("---")])])]}},{key:"state",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.state)?_c('span',[_vm._v(" "+_vm._s(item.state)+" ")]):_c('span',[_vm._v("---")])])]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.country)?_c('span',[_vm._v(" "+_vm._s(item.country)+" ")]):_c('span',[_vm._v("---")])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
var remove = ref.remove;
return undefined}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }