<template>
  <MRow :gutter="0">
    <MCol :size="6" class="px-2">
      <FlotoFormItem id="theme-code-input" :label="`${$tc('theme')}`">
        <FlotoDropdownPicker
          v-model="data.theme"
          :allow-clear="false"
          as-input
          searchable
          :placeholder="`${$tc('theme')}`"
          :options="themeCodeOptions"
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="hasRequestModule && !isPortalLogin" :size="6" class="px-2">
      <FlotoFormItem
        id="layout"
        :label="`${$tc('request')} ${$t('page_layout')}`"
      >
        <MRadioGroup v-model="data.layout" as-button :options="layoutOptions" />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="!isPortalLogin" :size="6" class="px-2">
      <FlotoFormItem id="pagesize" :label="$t('page_size')">
        <FlotoDropdownPicker
          v-model="data.pageSize"
          :allow-clear="false"
          as-input
          :options="pageSizeOptions"
        />
      </FlotoFormItem>
      <small class="text-neutral-light -mt-3" style="float: left">
        {{ $t('page_size_instruction') }}
      </small>
    </MCol>
    <MCol :size="6" class="px-2">
      <PasswordInput
        v-model="data.attachmentPassword"
        :validation-label="`${$tc('attachment')} ${$tc('password')}`"
        :label="`${$tc('attachment')} ${$tc('password')}`"
        :placeholder="`${$tc('attachment')} ${$tc('password')}`"
        :enabled-encryption="false"
        @update="handlePasswordChange"
      >
        <template v-slot:prefix>
          <MIcon name="unlock-alt" />
        </template>
      </PasswordInput>
    </MCol>
  </MRow>
</template>

<script>
import PasswordInput from '@components/password-input'
import { LicenseComputed } from '@state/modules/license'
import { authComputed } from '@state/modules/auth'
export default {
  name: 'PreferenceForm',
  components: { PasswordInput },
  model: { event: 'change' },
  props: { value: { type: Object, required: true } },
  data() {
    this.themeCodeOptions = [
      { text: `${this.$tc('light_theme')}`, key: 'light_theme' },
      { text: `${this.$tc('dark_theme')}`, key: 'dark_theme' },
    ]
    this.layoutOptions = [
      { text: this.$t('split_view'), value: 'split' },
      { text: this.$t('list_view'), value: 'list' },
    ]
    this.pageSizeOptions = [
      { text: `10 ${this.$t('items_per_page')}`, key: 10 },
      { text: `25 ${this.$t('items_per_page')}`, key: 25 },
      { text: `50 ${this.$t('items_per_page')}`, key: 50 },
      // { text: `100 ${this.$t('items_per_page')}`, key: 100 },
    ]
    return {}
  },
  computed: {
    ...authComputed,
    ...LicenseComputed,
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
  methods: {
    handlePasswordChange(changedPassword) {
      this.$emit('passwordChange', changedPassword)
    },
  },
}
</script>
