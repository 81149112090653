<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="searchable || scrollable"
    :module-name="moduleName"
    :immediate="!searchable"
    :fetch-fn="fetchTicketFn"
    :columns="tableColumns"
    :selectable="selectable"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-if="searchable" v-slot:add-controls="{ refreshList }">
      <MRow class="items-center">
        <MCol>
          <h2 class="font-normal inline-block mb-0">
            {{ $tc(moduleName, 2) }}
          </h2>
          <FlotoSearchSelector
            v-model="currentSelectedSearch"
            exclude-archived-spam-qual
            :allow-pin="false"
            :persist-search="false"
            :module-name="moduleName"
          />
        </MCol>
      </MRow>
      <MRow class="my-4">
        <MCol
          :class="{
            invisible:
              ((currentSelectedSearch || {}).systemName || '').indexOf(
                'spam'
              ) >= 0 ||
              ((currentSelectedSearch || {}).systemName || '').indexOf(
                'archive'
              ) >= 0 ||
              ((currentSelectedSearch || {}).systemName || '') ===
                'requests watched by me',
          }"
        >
          <FlotoSearchBar
            v-model="searchCriteria"
            :default-value="
              currentSelectedSearch ? currentSelectedSearch.qualifications : []
            "
            :should-notify-on-default-change="true"
            :allow-save-search="false"
            :persist-criteria="false"
            :module-name="moduleName"
            @change="refreshList"
          />
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:subject="{ item }">
      <td>
        <ResourceListTitle
          :name="item.name"
          :subject="item.subject"
          :requester-name="(item.requesterData || {}).name"
          :requester-email="(item.requesterData || {}).email"
          :created-at="item.createdAt"
          open-in-new-tab
          :link="
            $modules.getModuleRoute('ticket', 'view', {
              params: { id: item.id, ticketType: moduleName },
            })
          "
          v-bind="$attrs"
        />
      </td>
    </template>
    <template
      v-if="hiddenColumns.indexOf('assigned_to') < 0"
      v-slot:assigned_to="{ item }"
    >
      <td>
        <FlotoTechnicianPicker :value="item.technicianId" disabled />
      </td>
    </template>
    <template v-slot:status="{ item }">
      <td>
        <FlotoStatusPicker
          :module-name="moduleName"
          placeholder="---"
          :value="item.statusId"
          disabled
        />
      </td>
    </template>
    <template v-slot:priority="{ item }">
      <td>
        <FlotoPriorityPicker
          :value="item.priorityId"
          placeholder="---"
          disabled
        />
      </td>
    </template>
    <template
      v-if="hiddenColumns.indexOf('due_by') < 0"
      v-slot:due_by="{ item }"
    >
      <td>
        <FlotoDueDatePicker
          :value="item.dueBy"
          disabled
          :module-name="moduleName"
          :status-id="item.statusId"
        />
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stick-action="() => stickActionFor(item)"
          :unstick-action="() => unStickActionFor(item)"
        />
      </td>
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import Uniq from 'lodash/uniq'
import {
  transformSearchCriteriasForServer,
  transformSearchCriteriaForServer,
} from '@data/search'
import { searchTicketsApi } from '@modules/ticket/ticket-api'
import ResourceListTitle from '@components/resource/resource-list-title'

export default {
  name: 'TicketSelectionList',
  components: { ResourceListTitle },
  props: {
    moduleName: { type: String, required: true },
    fetchFn: { type: Function, default: undefined },
    hasActions: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return ['due_by', 'assigned_to']
      },
    },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
    hoverableActions: { type: Boolean, default: false },
  },
  data() {
    this.tableColumns = [
      {
        name: `${this.$t('subject')}`,
        key: 'subject',
        widthClass: 'xl:w-6/12 lg:w-5/12 md:w-4/12',
      },
      ...(this.hiddenColumns.indexOf('assigned_to') < 0
        ? [{ name: this.$t('assigned_to'), key: 'assigned_to' }]
        : []),
      { name: `${this.$t('status')}`, key: 'status', width: '160px' },
      { name: `${this.$t('priority')}`, key: 'priority', width: '80px' },
      ...(this.hiddenColumns.indexOf('due_by') < 0
        ? [{ name: this.$t('due_by'), key: 'due_by', width: '220px' }]
        : []),
      ...(this.hasActions
        ? [
            {
              name: this.$tc('action', 2),
              key: 'actions',
              width: '80px',
            },
          ]
        : []),
    ]
    return {
      searchCriteria: transformSearchCriteriasForServer(this.moduleName, []),
      actionSticked: [],
      currentSelectedSearch: null,
    }
  },
  methods: {
    fetchTicketFn(limit, offset) {
      const criterias = CloneDeep(this.searchCriteria)
      if (this.excludeItemsById.length) {
        const q = transformSearchCriteriaForServer(this.moduleName, {
          operator: { value: 'not_in' },
          paramName: 'id',
          value: this.excludeItemsById,
          valueType: 'long',
        })
        criterias.quals.push(q)
      }
      if (this.fetchFn) {
        return this.fetchFn(this.moduleName, criterias, limit, offset)
      } else {
        return searchTicketsApi(this.moduleName, criterias, limit, offset)
      }
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
  },
}
</script>
