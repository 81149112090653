<template>
  <Observer v-bind="$attrs" @on-change="onChange"> </Observer>
</template>

<script>
import Observer from 'vue-intersection-observer'
export default {
  name: 'IntersectionObserverCmp',
  components: {
    Observer,
  },
  methods: {
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      if (entry.isIntersecting) {
        this.$emit('isVisible', true)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
