<template>
  <MRow :gutter="0">
    <MCol>
      <input style="position: absolute;opacity: 0;" />
    </MCol>
    <MCol :size="12" class="px-2">
      <MRow>
        <MCol :size="12" :sm="6">
          <PasswordInput
            id="current-password"
            v-model="data.currentPassword"
            :placeholder="$t('current_password')"
            :label="$t('current_password')"
            rules="required"
          >
            <template v-slot:prefix>
              <MIcon name="unlock-alt" />
            </template>
          </PasswordInput>
        </MCol>
      </MRow>
    </MCol>
    <MCol :size="12" class="px-2">
      <MRow>
        <MCol :size="12" :sm="6">
          <PasswordInput
            id="new-password"
            v-model="data.password"
            :placeholder="$t('new_password')"
            :label="$t('new_password')"
            rules="required"
            vid="password"
          >
            <template v-slot:prefix>
              <MIcon name="unlock-alt" />
            </template>
          </PasswordInput>
        </MCol>
      </MRow>
    </MCol>
    <MCol :size="12" class="px-2">
      <MRow>
        <MCol :size="12" :sm="6">
          <PasswordInput
            id="confirm-password"
            v-model="data.confirmPassword"
            :placeholder="$t('confirm_password')"
            :label="$t('confirm_password')"
            rules="required|confirmed:password"
          >
            <template v-slot:prefix>
              <MIcon name="unlock-alt" />
            </template>
          </PasswordInput>
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import PasswordInput from '@components/password-input'
export default {
  name: 'ChangePasswordForm',
  components: { PasswordInput },
  model: { event: 'change' },
  props: { value: { type: Object, required: true } },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
