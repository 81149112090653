<script>
import { authComputed } from '@state/modules/auth'
import { getActiveTechniciansApi } from '@modules/users/users-api'

export default {
  name: 'ActiveTechniciansProvider',
  provide() {
    const activeTechniciansContext = {}
    Object.defineProperty(activeTechniciansContext, 'activeTechnicians', {
      enumerable: true,
      get: () => {
        return this.activeTechnicians
      },
    })
    return { activeTechniciansContext }
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      activeTechnicians: {},
    }
  },
  computed: {
    ...authComputed,
  },
  created() {
    if (!this.isPortalLogin && !this.disabled) {
      this.getActiveTechnicians()
    }
  },
  methods: {
    getActiveTechnicians() {
      getActiveTechniciansApi().then((data) => {
        this.activeTechnicians = data
      })
    },
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
</script>
