<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :fetch-fn="getResponseTemplates"
    :columns="tableColumns"
    selectable
    :max-allowed-selection="1"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-slot:add-controls="{ refreshList }">
      <MRow class="mb-2" :gutter="0">
        <MCol :size="6">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search')"
            @enterKey="refreshList"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
        </MCol>
      </MRow>
    </template>
    <template v-slot:createdBy="{ item }">
      <td class="text-ellipsis">
        <FlotoTechnicianPicker :value="item.createdBy" disabled />
        <small class="text-neutral pl-8">
          {{ item.createdAt | datetime(undefined, true) }}
        </small>
      </td>
    </template>
    <template v-slot:updatedBy="{ item }">
      <td class="text-ellipsis">
        <FlotoTechnicianPicker :value="item.updatedBy" disabled />
        <small class="text-neutral pl-8">
          {{ item.updatedAt | datetime(undefined, true) }}
        </small>
      </td>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getResponseTemplatesApi } from '@modules/response-template/response-template-api'

export default {
  name: 'ResponseTeplateList',
  props: {},
  data() {
    this.tableColumns = [
      { name: `${this.$t('name')}`, key: 'name' },
      {
        name: `${this.$t('created_by')}`,
        key: 'createdBy',
      },
      {
        name: `${this.$t('updated_by')}`,
        key: 'updatedBy',
      },
    ]
    return {
      searchTerm: '',
      searchCriteria: {},
    }
  },
  methods: {
    getResponseTemplates(limit, offset) {
      return getResponseTemplatesApi(
        { name: this.searchTerm, enabled: true },
        limit,
        offset
      )
    },
    refresh() {
      this.$refs.list.refresh()
    },
  },
}
</script>
