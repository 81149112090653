<template>
  <MMenu class="dropdown-picker-menu">
    <MMenuItem
      v-for="option in optionsComputed"
      :key="`${option.key || option.id || option.value}`"
      class="min-w-0"
    >
      <component
        :is="selectionComponent"
        :value="option.key"
        :checked="
          isItemChecked(
            'key' in option
              ? option.key
              : 'id' in option
              ? option.id
              : option.value
          )
        "
        class="flex items-center min-w-0"
        @[event]="
          toggleItem(
            'key' in option
              ? option.key
              : 'id' in option
              ? option.id
              : option.value
          )
        "
      >
        <div class="flex items-center min-w-0">
          <FlotoDot v-if="option.color" :bg="option.color" class="mx-1" />
          <FlotoUserAvatar
            v-if="option.avatar"
            size="small"
            :avatar="option.avatarType !== 'icon' ? option.avatar : undefined"
            class="mr-2"
          />
          <MTooltip>
            <template v-slot:trigger>
              <span class="text-ellipsis min-w-0">
                {{ option.text || option.name || option.label }}
              </span>
            </template>
            {{ option.text || option.name || option.label }}
          </MTooltip>
        </div>
      </component>
    </MMenuItem>
    <MMenuItem v-if="optionsComputed.length === 0" class="cursor-default">
      <FlotoNoData :col-size="8" />
    </MMenuItem>
  </MMenu>
</template>

<script>
export default {
  name: 'DropdownValues',
  model: {
    event: 'change',
  },
  props: {
    value: { type: [Array, String, Boolean], default: undefined },
    options: { type: Array, required: true },

    singleValueSelection: { type: Boolean, default: false },
  },
  computed: {
    optionsComputed() {
      if (this.singleValueSelection) {
        return this.options.filter((o) => o.singleValueSelection)
      }
      return this.options.filter((o) => !o.singleValueSelection)
    },
    selectionComponent() {
      if (this.singleValueSelection) {
        return 'MRadio'
      }
      return 'MCheckbox'
    },
    event() {
      if (this.singleValueSelection) {
        return 'click'
      }
      return 'change'
    },
  },
  watch: {
    singleValueSelection(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('change', undefined)
      }
    },
  },
  methods: {
    isItemChecked(v) {
      if (this.singleValueSelection) {
        return this.value === v
      }
      return (this.value || []).includes(v)
    },

    selectionChange(v) {
      this.$emit('change', v)
    },

    toggleItem(v) {
      if (this.singleValueSelection) {
        this.$emit('change', v)
      } else {
        if ((this.value || []).includes(v)) {
          this.$emit(
            'change',
            (this.value || []).filter((value) => value !== v)
          )
        } else {
          this.$emit('change', [...(this.value || []), v])
        }
      }
    },
  },
}
</script>
