<template>
  <a @click="triggerAction">
    <!-- <MIcon name="bell" class="action-menu-icon" /> -->
    <span class="ml-1 action-menu-text">
      {{ $t('allocate') }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'Allocate',
  methods: {
    triggerAction() {
      this.$emit('handle-allocate-action')
      this.$emit('trigger')
    },
  },
}
</script>
