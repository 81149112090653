<template>
  <FlotoContentLoader :loading="false">
    <MRow>
      <MCol :size="6">
        <h5>{{ $t('security_question_answer') }}</h5>
      </MCol>
      <MCol :size="6" class="text-right">
        <SecurityQuestionAnswerDrawer
          :configured-questions="securityQuestions"
          @refresh="getQuestions"
        >
          <template v-slot:trigger>
            <MButton variant="default">
              {{ $t('configure') }}
            </MButton>
          </template>
        </SecurityQuestionAnswerDrawer>
      </MCol>
    </MRow>
    <MDivider />
    <Mrow v-if="securityQuestions.length">
      <MCol>
        <SortableListItem
          v-for="(item, index) of securityQuestions"
          :key="item.key"
          disabled
          use-margin
          :use-seperator="true"
        >
          <strong>{{ $tc('question') }} {{ $tc(index + 1) + ' :' }} </strong>
          <Item :item="item" hide-default-action />
        </SortableListItem>
      </MCol>
    </Mrow>
    <MRow v-else :gutter="0">
      <MCol>
        <component
          :is="'h5'"
          class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
        >
          <MIcon name="info-circle" class="mr-2" />
          <slot name="message">{{ $t('no_question_answer_configured') }}</slot>
        </component>
      </MCol>
    </MRow>
  </FlotoContentLoader>
</template>

<script>
import { getUserProfileApi } from '@state/modules/auth/api'
import Item from '@modules/organization/components/single-item'
import SortableListItem from '@components/sortable/sortable-list-item'
import SecurityQuestionAnswerDrawer from './security-question-answer-drawer'

export default {
  name: 'SecurityQuestionAnswerList',
  components: { SortableListItem, Item, SecurityQuestionAnswerDrawer },
  data() {
    return {
      securityQuestions: [],
    }
  },
  created() {
    this.getQuestions()
  },
  methods: {
    getQuestions() {
      getUserProfileApi().then((data) => {
        this.securityQuestions = data.securityQuestions
      })
    },
  },
}
</script>
