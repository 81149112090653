var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MMenu',{staticClass:"dropdown-picker-menu"},[_vm._l((_vm.optionsComputed),function(option){return _c('MMenuItem',{key:("" + (option.key || option.id || option.value)),staticClass:"min-w-0"},[_c(_vm.selectionComponent,{tag:"component",staticClass:"flex items-center min-w-0",attrs:{"value":option.key,"checked":_vm.isItemChecked(
          'key' in option
            ? option.key
            : 'id' in option
            ? option.id
            : option.value
        )},on:_vm._d({},[_vm.event,function($event){return _vm.toggleItem(
          'key' in option
            ? option.key
            : 'id' in option
            ? option.id
            : option.value
        )}])},[_c('div',{staticClass:"flex items-center min-w-0"},[(option.color)?_c('FlotoDot',{staticClass:"mx-1",attrs:{"bg":option.color}}):_vm._e(),(option.avatar)?_c('FlotoUserAvatar',{staticClass:"mr-2",attrs:{"size":"small","avatar":option.avatarType !== 'icon' ? option.avatar : undefined}}):_vm._e(),_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',{staticClass:"text-ellipsis min-w-0"},[_vm._v(" "+_vm._s(option.text || option.name || option.label)+" ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(option.text || option.name || option.label)+" ")])],1)])],1)}),(_vm.optionsComputed.length === 0)?_c('MMenuItem',{staticClass:"cursor-default"},[_c('FlotoNoData',{attrs:{"col-size":8}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }