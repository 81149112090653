<template>
  <MLayoutSider
    :key="pinned"
    class="mainNavbar nav-bar-panel pt-1"
    :trigger="null"
    collapsible
    :class="{ 'fixed-navbar': pinned }"
    :collapsed="!pinned"
    :collapsed-width="collapsedWidth"
    :width="fullWidth"
  >
    <MMenu class="mainMenu" :selected-keys="selectedItem">
      <!-- <MMenuItem key="menupin" class="exclude-hover">
        <a hrem="javascript:;" @click="$emit('change', !pinned)">
          <MIcon name="bars" type="fas" size="lg" />
          <span>{{ $t(pinned ? 'collapse' : 'expand') }}</span>
        </a>
      </MMenuItem> -->
      <template v-for="menu in menuItems">
        <MSubMenu v-if="menu.submenuItems" :key="menu.key">
          <template v-slot:title>
            <MIcon
              v-if="!menu.customIcon"
              :name="menu.icon"
              size="lg"
              class="ml-1"
            />
            <CustomIcon v-else :name="menu.icon" />
            <span class="ml-1" :style="menu.textStyle">{{ menu.name }}</span>
          </template>
          <template v-for="submenu in menu.submenuItems">
            <MMenuItem
              v-if="submenu.key === 'separator'"
              :key="submenu.name"
              class="separator"
            >
            </MMenuItem>
            <MMenuItem v-else :key="submenu.key">
              <!-- for custom icon link -->
              <FlotoLink
                v-if="submenu.customIcon"
                :to="submenu.link"
                style="display: flex"
              >
                <CustomIcon
                  :name="submenu.icon"
                  :style="submenu.iconStyle"
                  class="mr-2"
                />
                <span>{{ submenu.name }}</span>
              </FlotoLink>
              <!-- for normal icon link -->
              <FlotoLink v-else :to="submenu.link">
                <MIcon
                  v-if="!submenu.customIcon"
                  :name="submenu.icon"
                  size="lg"
                  :style="submenu.iconStyle"
                />
                <span class="dropdown-margin">{{ submenu.name }}</span>
              </FlotoLink>
            </MMenuItem>
          </template>
        </MSubMenu>
        <MMenuItem v-else :key="menu.key">
          <FlotoLink :to="menu.link">
            <MIcon :name="menu.icon" size="lg" />
            <span :style="menu.textStyle">{{ menu.name }}</span>
          </FlotoLink>
        </MMenuItem>
      </template>
    </MMenu>
    <div class="flex items-center">
      <div class="flex justify-start">
        <img
          src="@assets/images/logo/motadata.png"
          :height="size"
          :width="size"
          class="self-center mb-4 mx-3"
        />
        <span v-if="pinned" class="ai-ops-text">ServiceOps</span>
      </div>
    </div>
  </MLayoutSider>
  <!-- </div> -->
</template>

<script>
import CustomIcon from '@components/custom-icon/custom-icon'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import {
  SupportPortalConfigComputed,
  SupportPortalConfigMethods,
} from '@state/modules/support-portal-config'
import { authComputed } from '@/src/state/modules/auth'
export default {
  name: 'FlotoNavBar',
  components: { CustomIcon },
  model: {
    event: 'change',
  },
  props: {
    portalMode: { type: Boolean, default: false },
    pinned: { type: Boolean, default: false },
  },
  data() {
    this.size = 30
    return {
      collapsedWidth: 50,
      fullWidth: 170,
    }
  },
  computed: {
    ...authComputed,
    ...LicenseComputed,
    ...PreferenceComputed,
    ...SupportPortalConfigComputed,
    menuItems() {
      let menuItems
      if (this.portalMode) {
        menuItems = [
          {
            key: 'request',
            name: this.$tc('request', 2),
            link: this.$modules.getModuleRoute('support-portal', 'my-requests'),
            icon: 'ticket-alt',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
          },
          {
            key: 'service-catalog',
            name: this.$tc('service', 2),
            link: this.$modules.getModuleRoute(
              'support-portal',
              'service-list'
            ),
            icon: 'shopping-cart',
            isModuleAvailable: (allAvailableModule) =>
              this.allowRequesterToAccessServiceRequest &&
              allAvailableModule.indexOf(this.$constants.SERVICE_CATALOG) >= 0,
          },
          {
            key: 'change',
            name: this.$tc('change', 2),
            link: this.$modules.getModuleRoute('support-portal', 'my-changes'),
            icon: 'change',
            isModuleAvailable: (allAvailableModule) =>
              this.allowReqToAccessMyChanges &&
              allAvailableModule.indexOf(this.$constants.CHANGE) >= 0,
          },
          {
            key: 'my-asset',
            name: this.$tc('my_assets'),
            link: this.$modules.getModuleRoute('support-portal', 'my-assets', {
              params: { assetType: this.$constants.ASSET },
            }),
            icon: 'laptop',
            isModuleAvailable: (allAvailableModule) => {
              return (
                this.allowReqToAccessMyAsset &&
                allAvailableModule.indexOf(this.$constants.ASSET) >= 0
              )
            },
          },
          {
            key: 'my-ci',
            name: this.$tc('my_ci'),
            link: this.$modules.getModuleRoute('support-portal', 'my-ci', {
              params: { assetType: this.$constants.CMDB },
            }),
            icon: 'ci',
            isModuleAvailable: (allAvailableModule) => {
              return (
                this.allowReqToAccessMyCi &&
                allAvailableModule.indexOf(this.$constants.CMDB) >= 0
              )
            },
          },
          {
            key: 'knowledge',
            name: this.$tc('knowledge'),
            link: this.$modules.getModuleRoute(
              'support-portal',
              'all-articles'
            ),
            icon: 'lightbulb-on',
            isModuleAvailable: () => {
              return this.allowRequesterToAccessKB
            },
          },
          {
            key: 'my-approvals',
            name: this.$tc('my_approval', 2),
            link: this.$modules.getModuleRoute(
              'support-portal',
              'my-approvals'
            ),
            icon: 'my-approval',
            isModuleAvailable: () => {
              return this.allowRequesterToAccessMyApprovals
            },
          },
          {
            key: 'my-team',
            name: this.$tc('my_team'),
            link: this.$modules.getModuleRoute('support-portal', 'my-team'),
            icon: 'users',
            textStyle: { marginLeft: '4px' },
            checkUserAcessCondition: (user) => user.allowDelegateApprovalTeam,
          },
        ]
      } else {
        menuItems = [
          {
            key: 'dashboard',
            name: this.$tc('dashboard'),
            link: this.$modules.getModuleRoute('dashboard'),
            icon: 'tachometer-alt',
          },
          {
            key: 'request',
            name: this.$tc('request', 2),
            link: this.$modules.getModuleRoute('ticket', undefined, {
              params: { ticketType: this.$constants.REQUEST },
            }),
            moduleKey: this.$constants.REQUEST,
            icon: 'ticket-alt',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
          },
          {
            key: 'problem',
            name: this.$tc('problem', 2),
            link: this.$modules.getModuleRoute('ticket', undefined, {
              params: { ticketType: this.$constants.PROBLEM },
            }),
            moduleKey: this.$constants.PROBLEM,
            icon: 'problem',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.PROBLEM) >= 0,
          },
          {
            key: 'change',
            name: this.$tc('change', 2),
            link: this.$modules.getModuleRoute('ticket', undefined, {
              params: { ticketType: this.$constants.CHANGE },
            }),
            moduleKey: this.$constants.CHANGE,
            icon: 'change',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.CHANGE) >= 0,
          },
          {
            key: 'release',
            name: this.$tc('release', 2),
            link: this.$modules.getModuleRoute('ticket', undefined, {
              params: { ticketType: this.$constants.RELEASE },
            }),
            moduleKey: this.$constants.RELEASE,
            icon: 'box-full',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.RELEASE) >= 0,
          },
          {
            key: 'asset',
            name: this.$tc('asset', 2),
            link: this.$modules.getModuleRoute('asset', undefined, {
              params: { assetType: this.$constants.ASSET_HARDWARE },
            }),
            moduleKey: this.$constants.ASSET,
            icon: 'laptop',
            textStyle: { marginLeft: '0' },
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.ASSET) >= 0 ||
              allAvailableModule.indexOf(this.$constants.CONTRACT) >= 0 ||
              allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
            // @TODO set active on navigation
            submenuItems: [
              {
                key: 'asset_hardware',
                name: this.$tc('asset_hardware', 2),
                link: this.$modules.getModuleRoute('asset', undefined, {
                  params: { assetType: this.$constants.ASSET_HARDWARE },
                }),
                moduleKey: this.$constants.ASSET,
                icon: 'hardware-asset',
                //    isModuleAvailable: (allAvailableModule) =>
                // allAvailableModule.indexOf(this.$constants.ASSET) >= 0 ||
                isModuleEnabledForUser: (userAllowedModules) =>
                  userAllowedModules.indexOf('asset.hardware_software_asset') >=
                  0,
              },
              {
                key: 'asset_software',
                name: this.$tc('asset_software', 2),
                link: this.$modules.getModuleRoute('asset', undefined, {
                  params: { assetType: this.$constants.ASSET_SOFTWARE },
                }),
                moduleKey: this.$constants.ASSET,
                icon: 'software-asset',
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.hardware_software_asset') >=
                  0,
              },
              {
                key: 'asset_non_it',
                name: this.$tc('asset_non_it', 2),
                link: this.$modules.getModuleRoute('asset', undefined, {
                  params: { assetType: this.$constants.ASSET_NON_IT },
                }),
                moduleKey: this.$constants.ASSET,
                icon: 'non-it-asset',
                iconStyle: { marginTop: '6px', maxHeight: '24px' },
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.non_it_asset') >= 0,
              },
              {
                key: 'asset_consumable',
                name: this.$tc('asset_consumable', 2),
                link: this.$modules.getModuleRoute('asset', undefined, {
                  params: { assetType: this.$constants.ASSET_CONSUMABLE },
                }),
                moduleKey: this.$constants.ASSET,
                icon: 'consumable-asset',
                iconStyle: { marginTop: '6px', maxHeight: '24px' },
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.consumable_asset') >= 0,
              },
              {
                key: 'separator',
                name: 'asset-separator',
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.hardware_software_asset') >=
                  0,
              },
              {
                key: 'software-license',
                name: this.$tc('software_license', 2),
                link: this.$modules.getModuleRoute('software-license'),
                moduleKey: this.$constants.ASSET,
                icon: 'software-license',
                // iconStyle: { marginTop: '6px', maxHeight: '24px' },
                // TODO :: Add both conditions
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.software_license_can_view') >=
                  0,
              },
              {
                key: 'software-meter',
                name: `${this.$tc('software')} ${this.$tc('meter')}`,
                link: this.$modules.getModuleRoute('asset', 'software-meter', {
                  params: { assetType: this.$constants.ASSET_SOFTWARE },
                }),
                moduleKey: this.$constants.ASSET,
                icon: 'software-meter',
                // iconStyle: { marginTop: '6px', maxHeight: '24px' },
                // TODO :: Add both conditions
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
                isModuleEnabledForUser: (myAllowedModules) =>
                  myAllowedModules.indexOf('asset.hardware_software_asset') >=
                  0,
              },
              {
                key: 'separator',
                name: 'contract-separator',
                isModuleAvailable: (allAvailableModule) =>
                  allAvailableModule.indexOf(this.$constants.CONTRACT) >= 0 ||
                  allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
              },
              {
                key: 'contract',
                name: this.$tc('contract', 2),
                link: this.$modules.getModuleRoute('contract'),
                moduleKey: this.$constants.CONTRACT,
                icon: 'handshake',
                isModuleAvailable: (allAvailableModule) => {
                  return (
                    allAvailableModule.indexOf(this.$constants.CONTRACT) >= 0
                  )
                },
              },
              {
                key: 'purchase',
                name: this.$tc('purchase', 2),
                link: this.$modules.getModuleRoute('purchase'),
                moduleKey: this.$constants.PURCHASE,
                icon: 'shopping-cart',
                isModuleAvailable: (allAvailableModule) =>
                  allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
              },
            ],
          },
          {
            key: 'cmdb',
            name: this.$tc('cmdb'),
            link: this.$modules.getModuleRoute('cmdb', undefined, {
              params: { ciType: this.$constants.CMDB },
            }),
            moduleKey: this.$constants.CMDB,
            icon: 'ci',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.CMDB) >= 0,
          },
          {
            key: 'patches',
            name: this.$tc('patch', 2),
            link: this.$modules.getModuleRoute('patch'),
            moduleKey: this.$constants.PATCH,
            icon: 'shield-alt',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.PATCH) >= 0,
            // @TODO set active on navigation
            submenuItems: [
              {
                key: 'patch',
                name: this.$tc('patch', 2),
                link: this.$modules.getModuleRoute('patch'),
                moduleKey: this.$constants.PATCH,
                icon: 'shield-alt',
                isModuleAvailable: (allAvailableModule) =>
                  allAvailableModule.indexOf(this.$constants.PATCH) >= 0,
              },
              {
                key: 'remote-deployment',
                name: this.$tc('patch_remote_deployment', 2),
                link: this.$modules.getModuleRoute(
                  'remote-deployment',
                  undefined,
                  {
                    params: {
                      configType: this.$constants.PATCH_REMOTE_DEPLOYMENT,
                    },
                  }
                ),
                moduleKey: this.$constants.PATCH_REMOTE_DEPLOYMENT,
                icon: 'patch-deployment',
                // TODO: Apply License (Kiran)
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.PATCH) >= 0,
              },
              {
                key: 'patch-computer',
                name: this.$tc('computer', 2),
                link: this.$modules.getModuleRoute('computer', undefined, {
                  params: { configType: this.$constants.PATCH_COMPUTERS },
                }),
                moduleKey: this.$constants.AGENT,
                icon: 'computer',
                // TODO: Apply License (Kiran)
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.PATCH) >= 0,
              },
              {
                key: 'auto-patch-test',
                name: this.$tc('auto_patch_test'),
                link: this.$modules.getModuleRoute('patch', 'auto-patch-test'),
                moduleKey: this.$constants.PATCH,
                icon: 'auto-patch-test',
              },
              {
                key: 'automatic-patch-deployment',
                name: this.$tc('automatic_patch_deployment', 2),
                link: this.$modules.getModuleRoute(
                  'patch',
                  'automatic-patch-deployment'
                ),
                moduleKey: this.$constants.AGENT,
                icon: 'auto-patch-deploy',
                // TODO: Apply License (Kiran)
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.PATCH) >= 0,
              },
            ],
          },
          {
            key: 'packages',
            name: this.$tc('package', 2),
            link: this.$modules.getModuleRoute('patch'),
            moduleKey: this.$constants.DEPLOYMENT,
            icon: 'cube',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.DEPLOYMENT) >= 0,
            // @TODO set active on navigation
            submenuItems: [
              {
                key: 'packages-deployment',
                name: this.$tc('packages_remote_deployment', 2),
                link: this.$modules.getModuleRoute(
                  'remote-deployment',
                  undefined,
                  {
                    params: {
                      configType: this.$constants.PACKAGES_REMOTE_DEPLOYMENT,
                    },
                  }
                ),
                moduleKey: this.$constants.DEPLOYMENT,
                icon: 'cube',
                isModuleAvailable: (allAvailableModule) =>
                  allAvailableModule.indexOf(this.$constants.DEPLOYMENT) >= 0,
              },
              {
                key: 'registry-deployment',
                name: this.$tc('registry_remote_deployment', 2),
                link: this.$modules.getModuleRoute(
                  'remote-deployment',
                  undefined,
                  {
                    params: {
                      configType: this.$constants.REGISTRY_REMOTE_DEPLOYMENT,
                    },
                  }
                ),
                moduleKey: this.$constants.DEPLOYMENT,
                icon: 'registry-deployment',
                isModuleAvailable: (allAvailableModule) =>
                  allAvailableModule.indexOf(this.$constants.DEPLOYMENT) >= 0,
              },
              {
                key: 'packages-computer',
                name: this.$tc('computer', 2),
                link: this.$modules.getModuleRoute('computer', undefined, {
                  params: { configType: this.$constants.PACKAGES_COMPUTERS },
                }),
                moduleKey: this.$constants.AGENT,
                icon: 'computer',
                // TODO: Apply License (Kiran)
                // isModuleAvailable: (allAvailableModule) =>
                //   allAvailableModule.indexOf(this.$constants.DEPLOYMENT) >= 0,
              },
            ],
          },
          {
            key: 'project',
            name: this.$tc('project', 2),
            link: this.$modules.getModuleRoute('project'),
            moduleKey: this.$constants.PROJECT,
            icon: 'project-diagram',
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.PROJECT) >= 0,
          },
          {
            key: 'knowledge',
            name: this.$tc('knowledge'),
            link: this.$modules.getModuleRoute('knowledge'),
            moduleKey: this.$constants.KNOWLEDGE,
            icon: 'lightbulb-on',
          },
          {
            key: 'reports',
            name: this.$tc('report', 2),
            link: this.$modules.getModuleRoute('reports'),
            icon: 'file-chart-line',
            moduleKey: this.$constants.REPORT,
            textStyle: { marginLeft: '5px' },
            iconStyle: { marginLeft: '3px' },
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.REQUEST) >= 0 ||
              allAvailableModule.indexOf(this.$constants.ASSET) >= 0 ||
              allAvailableModule.indexOf(this.$constants.PROJECT) >= 0 ||
              allAvailableModule.indexOf(this.$constants.PATCH) >= 0 ||
              allAvailableModule.indexOf(this.$constants.CMDB) >= 0,
          },
          {
            key: 'my-approval',
            name: this.$tc('my_approval', 2),
            link: this.$modules.getModuleRoute('my-approval'),
            icon: 'my-approval',
            textStyle: { marginLeft: '0' },
          },
          {
            key: 'my-tasks',
            name: this.$tc('my_tasks'),
            link: this.$modules.getModuleRoute('my-tasks'),
            icon: 'tasks',
            textStyle: { marginLeft: '4px' },
            isModuleAvailable: (allAvailableModule) =>
              allAvailableModule.indexOf(this.$constants.TASK) >= 0,
          },
          {
            key: 'my-team',
            name: this.$tc('my_team'),
            link: this.$modules.getModuleRoute('my-team'),
            icon: 'users',
            textStyle: { marginLeft: '4px' },
            isModuleEnabledForUser: (userAllowedModules) =>
              userAllowedModules.indexOf('admin.users') >= 0 ||
              this.user.superAdminRole ||
              this.user.allowDelegateApprovalTeam,
          },
        ]
      }

      const availableFilterFn = (m) => {
        if (this.user.cxoUser && !this.portalMode) {
          if (
            m.key === 'dashboard' &&
            this.user.roleNames.indexOf('Dashboard Viewer') >= 0
          ) {
            return true
          }
          if (
            m.key === 'reports' &&
            this.user.roleNames.indexOf('Report Viewer') >= 0
          ) {
            return true
          }
          return false
        }
        if (m.isModuleAvailable) {
          return m.isModuleAvailable(this.availableModulesInLicense)
        }
        if (m.isModuleEnabledForUser) {
          return m.isModuleEnabledForUser(this.myAllowedModules)
        }
        if (m.checkUserAcessCondition) {
          return m.checkUserAcessCondition(this.user)
        }
        return true
      }
      const myModulesFilterFn = (m) => {
        if (m.moduleKey && m.isModuleAvailable) {
          if (m.moduleKey === this.$constants.ASSET) {
            return (
              this.myAllowedModules.indexOf(this.$constants.ASSET) >= 0 ||
              this.myAllowedModules.indexOf(this.$constants.CONTRACT) >= 0 ||
              this.myAllowedModules.indexOf(this.$constants.PURCHASE) >= 0
            )
          }
          return this.myAllowedModules.indexOf(m.moduleKey) >= 0
        }
        return true
      }
      const myModulesSubModuleFilterFn = (m) => {
        if (m.moduleKey && m.isModuleAvailable) {
          return this.myAllowedModules.indexOf(m.moduleKey) >= 0
        }
        return true
      }
      return menuItems
        .map((menu) => ({
          ...menu,
          ...(menu.submenuItems
            ? { submenuItems: menu.submenuItems.filter(availableFilterFn) }
            : {}),
        }))
        .filter(availableFilterFn)
        .filter(myModulesFilterFn)
        .map((menu) => ({
          ...menu,
          ...(menu.submenuItems
            ? {
                submenuItems: menu.submenuItems.filter(
                  myModulesSubModuleFilterFn
                ),
              }
            : {}),
        }))
    },
    selectedItem() {
      if (this.portalMode) {
        if (this.$route.path.indexOf('knowledge') >= 0) {
          return ['knowledge']
        }
        if (this.$route.path.indexOf('service') >= 0) {
          return ['service-catalog']
        }
        if (this.$route.path.indexOf('approval') >= 0) {
          return ['my-approvals']
        }
        if (this.$route.path.indexOf('request') >= 0) {
          return ['request']
        }
        if (this.$route.path.indexOf('asset') >= 0) {
          return ['my-asset']
        }
        if (this.$route.path.indexOf('ci') >= 0) {
          return ['my-ci']
        }
        if (this.$route.path.indexOf('change') >= 0) {
          return ['change']
        }
        if (this.$route.path.indexOf('my-team') >= 0) {
          return ['my-team']
        }
        return []
      }
      if (this.$route.path.indexOf('software-license') >= 0) {
        return ['software-license']
      }
      if (this.$route.path.indexOf('software-meter') >= 0) {
        return ['software-meter']
      }
      if (this.$route.path.indexOf('patch_computers') >= 0) {
        return ['patch-computer']
      }
      if (this.$route.path.indexOf('packages_computers') >= 0) {
        return ['packages-computer']
      }
      if (this.$modules.getCurrent().getName() === 'ticket') {
        return [this.$route.params.ticketType]
      }
      if (
        this.$modules.getCurrent().getName() === 'asset' &&
        this.$route.params.assetType
      ) {
        return [this.$route.params.assetType]
      }
      if (this.$modules.getCurrent().getName() === 'service-catalog') {
        return [this.$constants.REQUEST]
      }
      if (this.$route.name.indexOf('automatic-patch-deployment') >= 0) {
        return ['automatic-patch-deployment']
      }
      if (this.$route.name.indexOf('auto-patch-test') >= 0) {
        return ['auto-patch-test']
      }
      if (this.$route.path.indexOf('packages_remote_deployment') >= 0) {
        return ['packages-deployment']
      }
      if (this.$route.path.indexOf('registry_remote_deployment') >= 0) {
        return ['registry-deployment']
      }
      return [this.$modules.getCurrent().getName()]
    },
  },
  methods: {
    ...SupportPortalConfigMethods,
  },
}
</script>

<style lang="less">
.mainMenu {
  padding-top: @layout-header-height + 10;
  // .@{ant-prefix}-menu-item:not(.exclude-hover):hover,
  // .@{ant-prefix}-menu-item-selected,
  // .@{ant-prefix}-menu-submenu-selected {
  //   // color: @primary;
  //   background-color: fade(@white, 100%) !important;
  //   // stylelint-disable-next-line
  //   &::after {
  //     right: -5px;
  //     width: 10px;
  //     background-color: @secondary-yellow;
  //     border-radius: 4px;
  //     opacity: 1;
  //     transform: none !important;
  //   }
  // }
}
</style>
