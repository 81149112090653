<template>
  <HighChart
    ref="chartContainer"
    :options="options"
    :constructor-type="'ganttChart'"
    :update-args="updateArgs"
  />
</template>
<script>
import { Chart as HighChart } from 'highcharts-vue'
import Gantt from 'highcharts/modules/gantt'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighCharts from 'highcharts'
Gantt(HighCharts)
HighchartsExporting(HighCharts)
export default {
  name: 'GanttChart',
  components: {
    HighChart,
  },
  props: {
    options: { type: Object, required: true },
  },
  computed: {
    updateArgs() {
      return [true, true, true, { duration: 1000 }]
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.$refs.chartContainer.chart.reflow()
    })
    this.resizeObserver.observe(this.$el)
  },
  beforeDestroy() {
    this.resizeObserver.disconnect()
  },
}
</script>
