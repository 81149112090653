<template>
  <FlotoFixedView :gutter="0">
    <div class="flex min-h-0 flex-col flex-1 mx-4 pb-4 min-w-0">
      <div class="flex flex-col min-w-0">
        <FlotoPageHeader :back-link="backLink">
          <template v-slot:title>
            {{ $tc(moduleName, 2) }}
          </template>
          <template v-slot:title-append>
            <FlotoSearchSelector
              v-if="!searchTitle"
              id="search-selector"
              :module-name="moduleName"
              allow-pin
              persist-search
              @change="handleSearchChange($event)"
            />
            <div
              v-else
              class="inline-block ml-4 text-neutral cursor-pointer md-text-base flex-1"
            >
              {{ searchTitle }}
            </div>
          </template>
          <template v-slot:after-title>
            <KanbanColumnSelector
              class="mx-1"
              :available-columns="columns"
              :module-name="moduleName"
              @refresh="refreshList"
            >
            </KanbanColumnSelector>
            <MCol auto-size class="flex items-center text-right justify-end">
              <MTooltip>
                <template v-slot:trigger>
                  <MButton
                    id="refresh-btn"
                    shape="circle"
                    variant="neutral-lighter"
                    :shadow="false"
                    class="mr-1"
                    @click="refreshList"
                  >
                    <MIcon name="sync" />
                  </MButton>
                </template>
                {{ $t('refresh') }}
              </MTooltip>
            </MCol>
            <MTooltip placement="topRight">
              <template v-slot:trigger>
                <MButton
                  id="list-btn"
                  shape="circle"
                  variant="neutral-lighter"
                  :shadow="false"
                  @click="
                    (e) => {
                      $emit('viewChange', 'list')
                      e.target.blur()
                    }
                  "
                >
                  <MIcon name="list" />
                </MButton>
              </template>
              {{ $t('list_view') }}
            </MTooltip>
          </template>
        </FlotoPageHeader>
      </div>
      <MRow v-if="!searchTitle" class="mb-2 flex flex-col min-w-0">
        <MCol
          :class="{
            invisible:
              isSpamQualExist(currentSelectedSearch.qualifications) ||
              isArchivedQualExist(currentSelectedSearch.qualifications) ||
              (currentSelectedSearch.systemName || '') ===
                'requests watched by me',
          }"
        >
          <slot name="search" :refresh-list="refreshList">
            <FlotoSearchBar
              id="search-bar"
              :module-name="moduleName"
              :persist-criteria="true"
              :default-value="currentSelectedSearch.qualifications"
              @change="refreshList"
            />
          </slot>
        </MCol>
      </MRow>
      <div class="flex flex-col flex-1 min-h-0">
        <KanbanCore
          :key="kanbanCoreKey"
          :module-name="moduleName"
          :fetch-fn="getItems"
          :update-fn="updateFn"
        />
      </div>
    </div>
  </FlotoFixedView>
</template>

<script>
import {
  transformSearchCriteriasForServer,
  isArchivedQualExist,
  isSpamQualExist,
} from '@data/search'
import { PageComputed, PageMethods } from '@state/modules/page'
import KanbanCore from './kanban-core'
import KanbanColumnSelector from '@components/crud/kanban-column-selector'
import { StatusComputed } from '@state/modules/status'
export default {
  name: 'KanbanView',
  components: { KanbanCore, KanbanColumnSelector },
  props: {
    moduleName: { type: String, required: true },
    fetchFn: { type: Function, required: true },
    updateFn: { type: Function, required: true },
    searchTitle: { type: String, default: null },
    backLink: { type: Object, default: undefined },
  },
  data() {
    this.isArchivedQualExist = isArchivedQualExist
    this.isSpamQualExist = isSpamQualExist
    return {
      kanbanCoreKey: 1,
    }
  },
  computed: {
    ...PageComputed,
    ...StatusComputed,
    columns() {
      let statuses = (this[`${this.moduleName}Status`] || []).filter(
        (i) => !i.archived
      )
      if (this.moduleName === this.$constants.TASK) {
        statuses = statuses.filter(
          (s) => (s.systemName || '').toLowerCase() !== 'cancelled'
        )
      }
      return statuses
    },
    searchCriterias() {
      return this[`${this.moduleName}SearchCriterias`]
    },
    isSearchDirty() {
      return this[`${this.moduleName}SearchDirty`]
    },
    currentSelectedSearch() {
      return this[`${this.moduleName}SelectedSearch`] || {}
    },
    ticketPageData() {
      return this[`${this.moduleName}PageData`] || {}
    },
  },
  methods: {
    ...PageMethods,
    getItems(statusId, limit, offset) {
      const qualifications = transformSearchCriteriasForServer(
        this.moduleName,
        this.searchCriterias
      )
      return this.fetchFn(statusId, qualifications, limit, offset)
    },
    handleSearchChange(search) {
      this.refreshList()
    },
    refreshList() {
      this.kanbanCoreKey++
    },
  },
}
</script>
