<template>
  <FlotoDrawer :open="isOpen" width="40%" @hide="isOpen = false">
    <template v-slot:trigger>
      <a class="text-neutral admin-page-link" @click="handleOpenDrawer">
        {{ $t('preference_setting') }} <MIcon name="cog" size="lg" />
      </a>
    </template>
    <template v-slot:title>
      {{ $t('preference_settings') }}
    </template>
    <MRow :gutter="0">
      <MCol :size="12">
        <MRow>
          <MCol>
            <div class="text-neutral">
              <span class="mr-2">
                {{ $t('show_on_screen_notification') }}
              </span>
              <MSwitch
                size="small"
                :checked="showUiNotification"
                @change="handleToggleNotifications"
              />
            </div>
          </MCol>
        </MRow>
        <MRow class="mb-4 mt-4" :gutter="0">
          <MCol
            v-for="moduleName in columnGroups"
            :key="moduleName"
            :size="12"
            class="mb-4"
          >
            <h4 class="text-primary">{{ $tc(moduleName) }}</h4>
            <MDivider class="my-2" />
            <MRow
              v-for="(notification, index) in allNotifications[moduleName]"
              :key="index"
            >
              <MCol class="mb-1">
                <MCheckbox
                  :checked="
                    availableNotification.indexOf(
                      notification.webNotificationType
                    ) >= 0
                  "
                  @change="changeSelectedColumn($event, notification)"
                >
                  {{ notification.name }}
                </MCheckbox>
              </MCol>
            </MRow>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
    <template v-slot:actions="{ hide }">
      <MButton class="mr-2" @click="handleSubmit">
        {{ $t('update') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('close') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { getSupportedNotificationsApi } from './notification-support-api'

export default {
  name: 'NotificationSetting',
  data() {
    return {
      isOpen: false,
      allNotifications: {},
      availableNotification: [],
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...authComputed,
    columnGroups() {
      if (this.isPortalLogin) {
        return Object.keys(this.allNotifications).filter(
          (n) => n === 'support_portal'
        )
      } else {
        return Object.keys(this.allNotifications).filter(
          (n) => n !== 'support_portal'
        )
      }
    },
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
    hasProblemModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.PROBLEM) >= 0
      )
    },
    hasChangeModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.CHANGE) >= 0
    },
    hasReleaseModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.RELEASE) >= 0
      )
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
  },
  created() {
    this.availableNotification = this.webNotificationPrefs
  },
  methods: {
    ...PreferenceMethods,
    handleOpenDrawer() {
      this.isOpen = true
      this.getSupportedNotifications()
    },
    getSupportedNotifications() {
      return getSupportedNotificationsApi().then((data) => {
        this.allNotifications = data
      })
    },
    changeSelectedColumn(isSelected, item) {
      if (isSelected) {
        this.availableNotification = [
          ...this.availableNotification,
          item.webNotificationType,
        ]
      } else {
        this.availableNotification = this.availableNotification.filter(
          (n) => n !== item.webNotificationType
        )
      }
    },
    handleSubmit() {
      this.updatePreference({
        webNotificationPrefs: this.availableNotification,
      }).then(() => {
        this.isOpen = false
      })
    },
    handleToggleNotifications() {
      this.updatePreference({
        showUiNotification: !this.showUiNotification,
      })
    },
  },
}
</script>
