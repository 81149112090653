<template>
  <div ref="columnContainer" class="flex flex-col min-h-0 flex-1">
    <div ref="scrollContainer" class="flex flex-col min-h-0 overflow-y-auto">
      <!-- <SortableList
        disabled
        tag="div"
        :value="items"
        class="flex-no-wrap flex-1"
        handle=".drag-handle"
        :component-data="{ props: { gutter: 0 } }"
        @drag-start="$emit('start-dragging')"
        @drag-end="$emit('stop-dragging')"
      > -->
      <div
        v-for="(singleItem, index) in items"
        :key="singleItem.id"
        class="task-unit cursor-default"
        :size="4"
      >
        <KanbanItem
          :item="singleItem"
          :module-name="moduleName"
          :is-editing="editingItem && editingItem.id === singleItem.id"
          @edit="handleItemEdit"
          @delete="confirmDeleteTask(singleItem.id)"
        />
      </div>
      <IntersectionObserverCmp
        v-if="$refs.scrollContainer && items.length > 0"
        :root="$refs.scrollContainer"
        :threshold="1"
        @isVisible="handleVisible"
      />
      <!-- </SortableList> -->
    </div>
    <div v-if="loading" class="py-2 w-full justify-center items-center flex">
      Loading...
    </div>
    <KanbanEditModal
      v-if="editingItem"
      :module-name="moduleName"
      :editing-item="editingItem"
      :processing="processing"
      @change="editingItem = $event"
      @cancel="editingItem = null"
      @submit="handleSubmit"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
import FindIndex from 'lodash/findIndex'
// import SortBy from 'lodash/sortBy'
// import SortableList from '@components/sortable/sortable-list'
import IntersectionObserverCmp from '@components/intersection-observer'
import KanbanItem from './kanban-item'
import KanbanEditModal from './kanban-edit-modal'

export default {
  name: 'KanbanColumn',
  components: { KanbanItem, IntersectionObserverCmp, KanbanEditModal },
  props: {
    fetchFn: { type: Function, required: true },
    updateFn: { type: Function, required: true },
    moduleName: { type: String, required: true },
    columnKey: { type: [Number, String], required: true },
    pageSize: { type: Number, default: 10 },
  },
  data() {
    return {
      items: [],
      loading: true,
      processing: false,
      total: null,
      activePageSize: this.pageSize,
      currentPage: 1,
      editingItem: null,
    }
  },
  computed: {
    offset() {
      return this.getOffset(this.currentPage)
    },
    hasMoreItem() {
      return this.currentPage < Math.ceil(this.total / this.activePageSize)
    },
  },
  created() {
    this.getItems(1)
  },
  methods: {
    getOffset(pageNo) {
      return this.activePageSize * (pageNo - 1)
    },
    handleVisible() {
      if (this.items.length >= this.total) {
        return
      }
      this.getItems(this.currentPage + 1)
    },
    getItems(pageNo) {
      return this.fetchFn(this.activePageSize, this.getOffset(pageNo)).then(
        ({ items, total }) => {
          const allItems = (this.items || []).concat(items)
          this.items = allItems
          this.total = total
          this.currentPage = pageNo
          this.loading = false
          this.processing = false
          this.$emit('total-items', {
            [this.columnKey]: this.total,
          })
        }
      )
    },
    handleItemEdit(item) {
      this.editingItem = item
    },
    handleDelete() {
      this.$emit('refresh-column', this.editingItem.statusId)
      this.editingItem = null
    },
    handleSubmit() {
      this.processing = true
      const index = FindIndex(this.items, { id: this.editingItem.id })
      if (index !== -1) {
        const prevData = this.items[index]
        const statusIsUpdated = prevData.statusId !== this.editingItem.statusId
        this.updateFn(this.editingItem)
          .then((data) => {
            if (statusIsUpdated) {
              this.items = [
                ...this.items.slice(0, index),
                ...this.items.slice(index + 1),
              ]
              this.total = this.total - 1
              this.$emit('total-items', {
                [this.columnKey]: this.total,
              })
              this.$emit('refresh-column', data.statusId)
            } else {
              this.items = [
                ...this.items.slice(0, index),
                this.editingItem,
                ...this.items.slice(index + 1),
              ]
            }
          })
          .finally(() => {
            this.editingItem = null
            this.processing = false
          })
      }
    },
  },
}
</script>
