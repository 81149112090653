import api from '@api'
import {
  transformRequestIntegrationForList,
  transformRequestIntegration,
  transformJiraMetadata,
  transformJiraForServer,
} from '@data/integration'
import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
const __tc = getRootPluaralTranslator()
const __t = getRootTranslator()

export function getIntegrationApi(id, pageSize, offset) {
  return api
    .get(`request/${id}/integration/`, {
      params: {
        offset: offset || 0,
        size: pageSize || 1000,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRequestIntegrationForList),
      }
    })
}

export function getJiraIssueInfoApi() {
  return api
    .get('/app/integration/jira/metadata')
    .then((data) => transformJiraMetadata(data))
}

export function createJiraApi(id, jira) {
  return api
    .post(`/request/${id}/jira/issue`, transformJiraForServer(jira), {
      message: __t('created_successfully', {
        resource: __tc('integration'),
      }),
    })
    .then((data) => transformRequestIntegration(data))
}
