<template>
  <MRow :gutter="0">
    <MCol v-if="!isPortalLogin" :size="12" :sm="6" class="px-2">
      <FlotoFormItem id="do-not-disturb" :label="$t('do_not_disturb')">
        <MSwitch
          v-model="data.doNotDisturb"
          size="small"
          :disabled="isDoNotDisturbDisabled && !data.doNotDisturb"
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="!isPortalLogin" :size="12" :sm="6" class="px-2">
      <FlotoFormItem
        id="do-not-disturb"
        :label="`${$tc('schedule')} ${$t('do_not_disturb')}`"
      >
        <MSwitch
          v-model="data.enableDndSchedule"
          size="small"
          :disabled="isDoNotDisturbDisabled && !data.enableDndSchedule"
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="data.enableDndSchedule" :size="6" class="px-2">
      <FlotoFormItem
        id="start-at-picker"
        :label="$t('start_at')"
        :rules="{
          required: true,
          nonzero: true,
          // eslint-disable-next-line
          min_date: Moment().subtract(1, 'minutes').valueOf(),
        }"
      >
        <FlotoDatePicker
          v-model="data.startAt"
          :disabled="isDoNotDisturbDisabled"
          :min-date="Moment().valueOf()"
        />
      </FlotoFormItem>
    </MCol>
    <MCol v-if="data.enableDndSchedule" :size="6" class="px-2">
      <FlotoFormItem
        id="start-at-picker"
        :rules="{
          required: true,
          nonzero: true,
          // eslint-disable-next-line
          min_date: Moment(data.startAt).add(1, 'milliseconds').valueOf(),
        }"
        :label="$t('end_at')"
      >
        <FlotoDatePicker
          v-model="data.endAt"
          :disabled="isDoNotDisturbDisabled"
          :min-date="data.startAt"
        />
      </FlotoFormItem>
    </MCol>
  </MRow>
</template>
<script>
import Moment from 'moment'
import { authComputed } from '@state/modules/auth/'
import { getTenantPrefrencesApi } from '@modules/organization/tenant-prefrences-api'

export default {
  name: 'DoNotDisturbForm',
  props: {
    value: { type: Object, required: true },
  },
  data() {
    this.Moment = Moment
    return {
      isDoNotDisturbDisabled: false,
    }
  },
  computed: {
    ...authComputed,
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
  created() {
    getTenantPrefrencesApi().then((data) => {
      this.isDoNotDisturbDisabled = !data.allowTechnicianToChangeDoNotDisturb
    })
  },
}
</script>
