var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 items-center"},[_c('MRow',{style:({ flex: 1, alignItems: 'center' })},[_c('MCol',{attrs:{"size":12}},[_c('div',{staticClass:"flex flex-grow justify-between items-start min-w-0"},[_c('FlotoUserAvatar',{staticClass:"mt-2 mr-3 mb-0",staticStyle:{"min-width":"30px"},attrs:{"show-tooltip":"","size":35,"user-id":_vm.item.performerType === 'user' ? _vm.item.performerId : 0}}),_c('div',{staticClass:"flex flex-col flex-1 min-w-0"},[_c('div',{staticClass:"flex items-center mr-1 mt-1 cursor-pointer",class:_vm.item.read ? 'font-normal' : ' font-semibold'},[_c('NotificationTitle',{attrs:{"text":_vm.item.title,"links":_vm.item.links}})],1),_c('div',{staticClass:"flex mt-1 justify-between text-neutral-light font-normal"},[_c('div',{staticClass:"md-text-xs"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.createdTime))+" ("+_vm._s(_vm._f("timeago")(_vm.item.createdTime))+") ")]),(
                !_vm.isPortalLogin &&
                ((_vm.item.metadata || {}).objectData || {}).statusId
              )?_c('div',{style:({ marginRight: '-36px' })},[_c('FlotoStatusPicker',{attrs:{"disabled":true,"value":((_vm.item.metadata || {}).objectData || {}).statusId,"module-name":_vm.getModuleName}})],1):_vm._e()])]),(!_vm.disabled)?_c('HoverableAction',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var stickAction = ref.stickAction;
              var unstickAction = ref.unstickAction;
return [_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_remove_item', {
                  item: ("" + (_vm.$tc(_vm.type))),
                })},on:{"trigger":stickAction,"cancel":unstickAction,"confirm":function($event){return _vm.$emit('remove', _vm.item)}}},[_c('MIcon',{staticClass:"cursor-pointer text-neutral-light",attrs:{"name":"times"}})],1)]}}],null,false,2245007656)}):_vm._e(),_c('MTooltip',{attrs:{"placement":"left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"ml-2 mt-1 cursor-pointer",class:_vm.item.read ? 'text-neutral-lighter' : 'text-primary',attrs:{"name":"circle","type":_vm.item.read ? 'fas' : 'fad'},on:{"click":function($event){return _vm.$emit('markAsRead', _vm.item)}}})]},proxy:true}])},[_c('span',[_vm._v(_vm._s(_vm.item.read ? _vm.$tc('mark_as_unread') : _vm.$tc('mark_as_read')))])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }