var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.selectionComponent,_vm._b({ref:"list",tag:"component",attrs:{"as-table":"","scrollable":_vm.searchable || _vm.scrollable,"module-name":_vm.moduleName,"immediate":!_vm.isEnableSearchSelector || !_vm.searchable,"fetch-fn":_vm.fetchTicketFn,"update-fn":_vm.updateTicketFn,"columns":_vm.tableColumns,"selectable":_vm.selectable,"max-allowed-selection":_vm.maxAllowedSelection,"default-selected-item-ids":_vm.defaultSelectedItemIds,"default-sort":"createdTime"},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
return [_vm._t("search-selector",function(){return [_c('MRow',{staticClass:"items-center"},[_c('MCol',[_c('h2',{staticClass:"font-normal inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.moduleName === _vm.$constants.CMDB ? _vm.$tc('ci', 2) : _vm.$tc(_vm.moduleName, 2))+" ")]),_c('FlotoSearchSelector',{class:{ invisible: _vm.hideSearchSelector },attrs:{"exclude-archived-spam-qual":"","allow-pin":false,"persist-search":false,"module-name":_vm.moduleName},model:{value:(_vm.currentSelectedSearch),callback:function ($$v) {_vm.currentSelectedSearch=$$v},expression:"currentSelectedSearch"}})],1)],1)]}),_c('MRow',{staticClass:"my-4"},[_c('MCol',{class:{
          invisible:
            ((_vm.currentSelectedSearch || {}).systemName || '').indexOf(
              'spam'
            ) >= 0 ||
            ((_vm.currentSelectedSearch || {}).systemName || '').indexOf(
              'archive'
            ) >= 0,
        }},[_c('FlotoSearchBar',_vm._b({attrs:{"allow-save-search":false,"persist-criteria":false,"module-name":_vm.moduleName},on:{"change":function($event){return _vm.applyCriteria($event, refreshList)}}},'FlotoSearchBar',Object.assign({}, _vm.searchBarProps,
            (_vm.isEnableSearchSelector
              ? {
                  defaultValue: _vm.currentSelectedSearch
                    ? _vm.currentSelectedSearch.qualifications
                    : [],
                }
              : {}),
            (_vm.isEnableSearchSelector ? { value: _vm.searchCriteria } : {}),
            (_vm.isEnableSearchSelector
              ? { shouldNotifyOnDefaultChange: true }
              : {})),false))],1)],1)]}}:null,(_vm.allowBulkAction)?{key:"bulk-actions",fn:function(slotData){return [_c('BulkActions',_vm._b({attrs:{"resource-id":_vm.resourceId,"module-name":_vm.moduleName,"mode":"license_installation"},on:{"refresh":function($event){return _vm.$emit('refresh')}}},'BulkActions',slotData,false))]}}:null,{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"displayName",fn:function(ref){
          var item = ref.item;
return [_c('td',[(_vm.moduleName === _vm.$constants.CMDB)?_c('ResourceListTitle',{attrs:{"name":item.ciName,"subject":item.ciDisplayName,"created-at":0,"open-in-new-tab":"","link":_vm.getCmdbLink(item),"internal-link":_vm.linkable}}):_c('ResourceListTitle',{attrs:{"name":item.name,"subject":item.displayName,"created-at":0,"open-in-new-tab":"","link":_vm.getAssetLink(item),"internal-link":_vm.linkable}})],1)]}},(_vm.hiddenColumns.indexOf('assetTypeId') < 0)?{key:"assetTypeId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('div',{staticClass:"flex"},[_c('AssetTypeIcon',{staticClass:"mr-2",attrs:{"asset-type-id":item.assetTypeId,"size":"md"}}),_c('FlotoAssetTypePicker',{attrs:{"value":item.assetTypeId,"disabled":""}})],1)])]}}:null,(_vm.hiddenColumns.indexOf('ciTypeId') < 0)?{key:"ciTypeId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('div',{staticClass:"flex"},[_c('AssetTypeIcon',{staticClass:"mr-2",attrs:{"ci-type-id":item.ciTypeId,"size":"md"}}),_c('FlotoCITypePicker',{attrs:{"value":item.ciTypeId,"disabled":""}})],1)])]}}:null,{key:"statusId",fn:function(ref){
          var item = ref.item;
return [_c('td',[(_vm.moduleName === _vm.$constants.CMDB)?_c('FlotoStatusPicker',{attrs:{"module-name":_vm.$constants.CMDB,"placeholder":"---","value":item.ciStatusId,"disabled":""}}):_c('FlotoStatusPicker',{attrs:{"module-name":_vm.$constants.ASSET,"placeholder":"---","value":item.statusId,"disabled":""}})],1)]}},{key:"impactId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoImpactPicker',{attrs:{"value":item.impactId,"disabled":"","placeholder":"---"}})],1)]}},{key:"ipAddress",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.ipAddress || '---')+" ")])]}},{key:"hostName",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.hostName || '---')+" ")])]}},{key:"managedById",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoTechnicianPicker',{attrs:{"value":item.managedById,"group-id":item.managedByGroupId,"disabled":""}})],1)]}},{key:"ignoreVersion",fn:function(ref){
          var item = ref.item;
          var update = ref.update;
return [_c('td',{attrs:{"align":"center"}},[_c('MSwitch',{staticClass:"mr-2",attrs:{"checked":item.ignoreVersion,"size":"small"},on:{"change":function($event){return update(item)}}})],1)]}},{key:"assignedUserIds",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoRequesterPicker',{attrs:{"multiple":"","as-tag":"","value":item.assignedUserIds || undefined,"disabled":"","placeholder":"---","dropdown-size":"small"}})],1)]}},{key:"managedByGroupId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoTechnicianGroupPicker',{attrs:{"value":item.managedByGroupId,"disabled":""}})],1)]}},{key:"version",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.version || '---')+" ")])]}},{key:"swTypeId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('SoftwareTypePicker',{attrs:{"value":item.swTypeId,"placeholder":"---","as-input":"","size":"small","disabled":""}})],1)]}},{key:"swCategoryId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoCategoryPicker',{staticClass:"w-full",attrs:{"value":item.swCategoryId,"module-name":_vm.$constants.ASSET_SOFTWARE,"placeholder":"---","size":"small","disabled":""}})],1)]}},(_vm.hiddenColumns.indexOf('lastDiscoveredTime') < 0)?{key:"lastDiscoveredTime",fn:function(ref){
          var item = ref.item;
return [_c('td',[(item.lastDiscoveredTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetime")(item.lastDiscoveredTime))+" ")]):_c('span',[_vm._v("---")])])]}}:null,{key:"barcodeAndQrcode",fn:function(ref){
          var item = ref.item;
return [_c('td',[(item.barCode || _vm.tenantPrefrences.qrCodeSupport)?_c('span',[_c('BarcodeQrcodeRenderer',{attrs:{"asset":item,"module-name":item.model}})],1):_c('span',[_vm._v("---")])])]}},{key:"createdTime",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("datetime")(item.createdAt))+" ")])]}},{key:"actions",fn:function(ref){
          var item = ref.item;
          var remove = ref.remove;
return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",function(){return [_c('div',{staticClass:"flex justify-left items-center"}),_c('FlotoDeleteBtn',{attrs:{"disabled":_vm.disabled,"message":_vm.$t('confirm_delete_item', {
              item: ("" + (_vm.$tc('asset'))),
            })},on:{"confirm":remove}})]},{"item":item,"stickAction":function () { return _vm.stickActionFor(item); },"unstickAction":function () { return _vm.unStickActionFor(item); }})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'component',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }