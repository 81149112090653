<template>
  <div class="flex flex-col">
    <MMenu class="dropdown-picker-menu">
      <MMenuItem v-for="option in options" :key="option.value">
        <MRadio
          :value="option.value"
          :checked="option.value === timeType"
          class="flex items-center"
          @click="handleValueChange(option)"
        >
          {{ option.text }}
        </MRadio>
      </MMenuItem>
    </MMenu>
    <FlotoFormItem
      v-if="
        timeType === 'custom' && durationOperators.indexOf(operator.value) >= 0
      "
      rules="required|nonzero"
      vid="daytimepicker"
      :validation-label="$t('value')"
    >
      <FlotoDaytimePicker
        :key="`${operator.value}-${timeType}`"
        ref="dayTimeRef"
        v-model="fromValue"
        :allowed-units="
          onlyDaysdurationOperators.indexOf(operator.value) >= 0
            ? ['days']
            : ['days', 'hours']
        "
        :placeholder="$t('select')"
        as-input
        @timeUnit="handleDaytimeUnitChange"
      />
    </FlotoFormItem>
    <FlotoFormItem
      v-else-if="timeType === 'custom'"
      vid="fromDate"
      rules="required|nonzero"
      :validation-label="$t('value')"
    >
      <FlotoDatePicker
        v-model="fromValue"
        :allow-clear="false"
        :max-date="toSelectedValue"
        :show-time="false"
        :get-popup-container="getPopupContainer"
        :placeholder="$t('select')"
      />
    </FlotoFormItem>
    <template
      v-if="
        timeType === 'custom' && betweenOperators.indexOf(operator.value) >= 0
      "
    >
      <div class="mb-4 flex items-center justify-center">
        {{ $t('to') }}
      </div>
      <FlotoFormItem
        v-if="
          timeType === 'custom' &&
          durationOperators.indexOf(operator.value) >= 0
        "
        rules="required|nonzero"
        vid="todaytimepicker"
        :validation-label="$t('value')"
      >
        <FlotoDaytimePicker
          :key="`${operator.value}-${timeType}`"
          ref="toDayTimeRef"
          v-model="toSelectedValue"
          :allowed-units="
            onlyDaysdurationOperators.indexOf(operator.value) >= 0
              ? ['days']
              : ['days', 'hours']
          "
          :placeholder="$t('select')"
          as-input
          @timeUnit="handleToDaytimeUnitChange"
        />
      </FlotoFormItem>
      <FlotoFormItem
        v-else
        vid="toDate"
        rules="required|nonzero"
        :validation-label="$t('value')"
      >
        <FlotoDatePicker
          v-model="toSelectedValue"
          :allow-clear="false"
          :min-date="fromValue"
          :show-time="false"
          :get-popup-container="getPopupContainer"
          :placeholder="$t('select')"
        />
      </FlotoFormItem>
    </template>
  </div>
</template>

<script>
import {
  durationOperators,
  betweenOperators,
  onlyDaysdurationOperators,
} from '@data/operator'
export default {
  name: 'CreatedTime',
  props: {
    type: { type: String, required: true },
    moduleName: { type: String, required: true },
    value: { type: [Number, String], default: undefined },
    toValue: { type: [Number, String], default: undefined },
    operator: { type: [Object, String], required: true },
  },
  data() {
    this.durationOperators = durationOperators
    this.betweenOperators = betweenOperators
    this.onlyDaysdurationOperators = onlyDaysdurationOperators
    this.options = (
      this.type === 'dueBy'
        ? this.getOverDueOptions()
        : this.getCreatedAtOptions()
    ).map((o) => o)
    let timeType
    if (this.value) {
      timeType = /^[a-z]/.test(this.value) ? this.value : 'custom'
    } else {
      timeType =
        (this.options[0] || {}).value ||
        (this.type === 'dueBy' ? 'overdue' : 'last_1_hours')
      this.$emit('update:value', timeType)
    }
    return {
      timeType,
      fromValue: this.value,
      toSelectedValue: this.toValue,
    }
  },
  watch: {
    fromValue(newValue) {
      this.$emit('update:value', newValue)
    },
    toSelectedValue(newValue) {
      this.$emit('update:toValue', newValue)
    },
    timeType: {
      handler(newValue, prevValue) {
        if (newValue !== prevValue && newValue === 'custom') {
          this.fromValue = 0
          this.toSelectedValue = 0
        }
      },
    },
    'operator.value': {
      handler(newValue, prevValue) {
        if (['between', ...durationOperators].indexOf(newValue) >= 0) {
          this.timeType = 'custom'
          this.fromValue = 0
          this.toSelectedValue = 0
        }
        if (newValue !== prevValue && this.timeType === 'custom') {
          this.fromValue = 0
          this.toSelectedValue = 0
        }
      },
    },
  },
  methods: {
    handleDaytimeUnitChange(unit) {
      if (this.$refs.toDayTimeRef && this.$refs.toDayTimeRef.setTimeUnit) {
        this.$refs.toDayTimeRef.setTimeUnit(unit)
      }
    },
    handleToDaytimeUnitChange(unit) {
      if (this.$refs.dayTimeRef && this.$refs.dayTimeRef.setTimeUnit) {
        this.$refs.dayTimeRef.setTimeUnit(unit)
      }
    },
    getPopupContainer() {
      return document.body
    },
    getOverDueOptions() {
      return [
        { text: this.$t('overdue'), value: 'overdue' },
        { text: this.$t('today'), value: 'today' },
        { text: this.$t('tomorrow'), value: 'tomorrow' },
        { text: this.$t('this_week'), value: 'this_week' },
        { text: this.$t('this_month'), value: 'this_month' },
        { text: this.$t('custom'), value: 'custom' },
      ]
    },
    getCreatedAtOptions() {
      if (this.moduleName === this.$constants.CONTRACT) {
        return [
          { text: this.$t('this_week'), value: 'this_week' },
          { text: this.$t('this_month'), value: 'this_month' },
          { text: this.$t('last_week'), value: 'last_week' },
          { text: this.$t('last_month'), value: 'last_month' },
          { text: this.$t('custom'), value: 'custom' },
        ]
      }
      return [
        ...(this.moduleName !== this.$constants.PURCHASE &&
        this.moduleName !== this.$constants.SOFTWARE_LICENSE &&
        this.type === 'createdTime'
          ? [
              { text: this.$t('last_1_hours'), value: 'last_1_hours' },
              { text: this.$t('last_8_hours'), value: 'last_8_hours' },
              { text: this.$t('last_24_hours'), value: 'last_24_hours' },
            ]
          : []),
        { text: this.$t('today'), value: 'today' },
        { text: this.$t('this_week'), value: 'this_week' },
        { text: this.$t('this_month'), value: 'this_month' },
        ...(this.moduleName === this.$constants.USER_SURVEY
          ? [{ text: this.$t('last_week'), value: 'last_week' }]
          : []),
        { text: this.$t('custom'), value: 'custom' },
      ]
    },
    handleValueChange($event) {
      this.timeType = $event.value
      if (this.timeType !== 'custom') {
        this.$emit('update:value', this.timeType)
      } else {
        const v = this.fromValue || undefined
        if (/^[a-z]/.test(v)) {
          this.fromValue = undefined
        }
        this.$emit('update:value', /^[a-z]/.test(v) ? undefined : v)
      }
    },
  },
}
</script>
