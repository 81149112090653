<template>
  <div class="flex flex-1 justify-start kanban-view min-h-0">
    <div class="flex-1 flex min-h-0 h-full overflow-x-auto">
      <div
        v-for="(column, index) in columns"
        :key="column.title"
        class="px-2 py-2 column rounded mx-1 bg-neutral-lightest flex flex-col flex-1 min-h-0"
        :class="{ 'ml-0': index === 0, 'mr-0': columns.length === index + 1 }"
      >
        <h5 class="flex items-center">
          <div class="text-ellipsis">
            <FlotoDot v-if="column.color" :bg="column.color" />
            {{ column.text }}
          </div>
          <small class="flex flex-1 mx-1 justify-between">
            <div>
              {{ totalItemsData[column.key] ? totalItemsData[column.key] : '' }}
            </div>
            <MTooltip>
              <template v-slot:trigger>
                <MIcon
                  class="cursor-pointer text-neutral"
                  name="sync"
                  @click="refreshColumn(column.key)"
                />
              </template>
              {{ $tc('refresh') }}
            </MTooltip>
          </small>
        </h5>
        <KanbanColumn
          :key="statusUpdateCounts[column.key] || 1"
          :module-name="moduleName"
          :column-key="column.key"
          :fetch-fn="
            (limit, offset) => getColumnItems(column.key, limit, offset)
          "
          :update-fn="updateFn"
          @start-dragging="$emit('start-dragging')"
          @stop-dragging="$emit('stop-dragging')"
          @total-items="countTotalItems"
          @refresh-column="refreshColumn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import KanbanColumn from './kanban-column'

export default {
  name: 'KanbanCore',
  components: { KanbanColumn },
  props: {
    moduleName: { type: String, required: true },
    fetchFn: { type: Function, required: true },
    updateFn: { type: Function, required: true },
  },
  data() {
    return {
      totalItemsData: {},
      statusUpdateCounts: {},
    }
  },
  computed: {
    ...StatusComputed,
    ...PreferenceComputed,
    columns() {
      let statuses = (this[`${this.moduleName}Status`] || []).filter(
        (i) => !i.archived
      )
      if (this.moduleName === this.$constants.TASK) {
        statuses = statuses.filter(
          (s) => (s.systemName || '').toLowerCase() !== 'cancelled'
        )
      }
      let filteredStatuses = statuses
      const kanbanColumns = this.defaultKanbanStatusColumns(this.moduleName)
      if ((kanbanColumns || []).length) {
        filteredStatuses = statuses.filter((status) => {
          return kanbanColumns.indexOf(status.key) >= 0
        })
        return filteredStatuses
      }
      return filteredStatuses
    },
  },
  methods: {
    ...PreferenceMethods,
    getColumnItems(columnKey, limit, offset) {
      return this.fetchFn(columnKey, limit, offset)
    },
    countTotalItems($event) {
      this.totalItemsData = {
        ...this.totalItemsData,
        ...$event,
      }
    },
    refreshColumn(statusId) {
      this.statusUpdateCounts = {
        ...this.statusUpdateCounts,
        ...(this.statusUpdateCounts[statusId]
          ? {
              [statusId]: this.statusUpdateCounts[statusId] + 1,
            }
          : { [statusId]: 2 }),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.kanban-view {
  .column {
    width: 420px;
    min-width: 420px;
  }
}
</style>
