<template>
  <MRow>
    <MCol>
      <MRow>
        <MCol>
          <FlotoFormItem
            v-model="data.noteName"
            auto-focus
            :label="$tc('name')"
            :placeholder="$t('name')"
            rules="required|min:1|max:100"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol>
          <FlotoFormItem
            v-model="data.noteDescription"
            :label="$t('description')"
            rules="required|description"
            type="textarea"
            :rows="5"
            :placeholder="$t('description')"
          />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'PlainTextNoteRuleForm',
  model: { event: 'change' },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>
