var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',_vm._b({ref:"list",attrs:{"as-table":"","scrollable":_vm.searchable || _vm.scrollable,"module-name":_vm.moduleName,"immediate":!_vm.searchable,"fetch-fn":_vm.fetchTicketFn,"columns":_vm.tableColumns,"selectable":_vm.selectable},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
return [_c('MRow',{staticClass:"items-center"},[_c('MCol',[_c('h2',{staticClass:"font-normal inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.moduleName, 2))+" ")]),_c('FlotoSearchSelector',{attrs:{"exclude-archived-spam-qual":"","allow-pin":false,"persist-search":false,"module-name":_vm.moduleName},model:{value:(_vm.currentSelectedSearch),callback:function ($$v) {_vm.currentSelectedSearch=$$v},expression:"currentSelectedSearch"}})],1)],1),_c('MRow',{staticClass:"my-4"},[_c('MCol',{class:{
          invisible:
            ((_vm.currentSelectedSearch || {}).systemName || '').indexOf(
              'archive'
            ) >= 0,
        }},[_c('FlotoSearchBar',{attrs:{"default-value":_vm.currentSelectedSearch ? _vm.currentSelectedSearch.qualifications : [],"should-notify-on-default-change":true,"allow-save-search":false,"persist-criteria":false,"module-name":_vm.moduleName},on:{"change":refreshList},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1)],1)]}}:null,{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"displayName",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('ResourceListTitle',_vm._b({attrs:{"name":item.name,"subject":item.displayName,"user-id":item.createdBy,"created-at":item.createdAt,"open-in-new-tab":"","link":_vm.$modules.getModuleRoute('contract', 'view', {
            params: { id: item.id },
          })}},'ResourceListTitle',_vm.$attrs,false))],1)]}},{key:"contractTypeId",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ContractTypePicker',{attrs:{"value":item.contractTypeId,"disabled":""}})],1)]}},{key:"contractStatus",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ContractStatusPicker',{attrs:{"value":item.contractStatus,"disabled":""}})],1)]}},{key:"contractNumber",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.contractNumber || '---')+" ")])]}},{key:"activationStartTime",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.activationStartTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetime")(item.activationStartTime,undefined, false))+" ")]):_c('span',[_vm._v("---")])])]}},{key:"activationEndTime",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.activationEndTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetime")(item.activationEndTime,undefined, false))+" ")]):_c('span',[_vm._v("---")])])]}},(_vm.hiddenColumns.indexOf('renewalDate') < 0)?{key:"renewalDate",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.renewalDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetime")(item.renewalDate,undefined, false))+" ")]):_c('span',[_vm._v("---")])])]}}:null,(_vm.hiddenColumns.indexOf('vendorId') < 0)?{key:"vendorId",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(_vm.vendorName(item))+" ")])]}}:null,{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",null,{"item":item,"stickAction":function () { return _vm.stickActionFor(item); },"unstickAction":function () { return _vm.unStickActionFor(item); }})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }