import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformHierarchyConfig,
  transformHierarchyConfigForServer,
} from '@/src/components/hierarchy-config/hierarchy-config'
import { isLoggedIn } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function updateHierarchyConfigApi(moduleName, data) {
  return api
    .patch(
      `${moduleName}/hierarchyManagement`,
      transformHierarchyConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc(moduleName)} ${__rootTc('configuration', 2)}`,
        }),
      }
    )
    .then(transformHierarchyConfig)
}

export function getHierarchyConfigApi(moduleName) {
  return api
    .get(
      `${isLoggedIn() ? '/' : '/public/'}${moduleName}/hierarchyManagement`,
      {
        loader: false,
        notify: false,
      }
    )
    .then(transformHierarchyConfig)
}
