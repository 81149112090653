<template>
  <FlotoCrudContainer as-table :fetch-fn="getScenarios" :columns="tableColumns">
    <template v-slot:actions="{ item }">
      <td :class="{ 'hover-action-container': hoverableActions }">
        <slot name="action" :item="item" />
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getScenariosForResourceApi } from '@modules/scenario/scenario-api'

export default {
  name: 'ScenarioSelectionList',
  props: {
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
    hoverableActions: { type: Boolean, default: false },
  },
  data() {
    this.tableColumns = [
      { name: `${this.$t('name')}`, key: 'name' },
      { name: `${this.$t('description')}`, key: 'description' },
      {
        name: `${this.$tc('action', 2)}`,
        key: 'actions',
        width: '120px',
      },
    ]
    return {}
  },
  methods: {
    getScenarios(limit, offset) {
      return getScenariosForResourceApi(
        this.moduleName,
        this.resourceId,
        limit,
        offset
      )
    },
  },
}
</script>
