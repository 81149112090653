<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { authComputed } from '@state/modules/auth'
import Moment from 'moment'
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  props: {
    events: {
      type: [Array, Object, Function],
      default: () => [],
    },
    calendarOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    this.defaultOption = {
      eventDisplay: 'block',
      buttonText: {
        today: this.$tc('today'),
      },
      headerToolbar: {
        left: 'title',
        right: 'today prev,next',
      },
      initialView: 'dayGridMonth',
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short',
      },
      editable: false,
      selectable: false,
      selectMirror: false,
      dayMaxEvents: false,
      weekends: true,
      // contentHeight: 650,
      stickyHeaderDates: true,
      views: {
        week: {
          dayHeaderFormat: function (data) {
            return Moment(data.date.marker).format('ddd D')
          },
          titleFormat: {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            titleRangeSeparator: '-',
          },
        },
      },
    }
    return {}
  },
  computed: {
    ...authComputed,
    options() {
      return {
        ...this.defaultOption,
        timeZone: this.user.timezone,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentTimezonePlugin,
        ],
        ...this.calendarOptions,
      }
    },
  },
  methods: {
    getView() {
      return this.$refs.fullCalendar.getApi().view
    },
    refresh() {
      return this.$refs.fullCalendar.getApi().refetchEvents()
    },
  },
}
</script>

<template>
  <FullCalendar ref="fullCalendar" :options="options">
    <template v-slot:eventContent="arg">
      <slot name="eventContent" :event="arg">
        <b>{{ arg.timeText }}</b>
        <i>{{ arg.event.title }}</i>
      </slot>
    </template>
  </FullCalendar>
</template>

<style lang="less">
.fc .fc-popover {
  z-index: 885;
}

.fc-popover-body {
  max-height: 450px;
  overflow: auto;
}

.fc-direction-ltr {
  .fc-daygrid-event.fc-event-start,
  .fc-daygrid-event.fc-event-end {
    margin-left: 2px;
    color: var(--active-text-color);
    background-color: var(--primary-alt);
    border-color: var(--white-regular);
    box-shadow: none;
  }

  .fc-button-group > .fc-button:not(:first-child) {
    margin-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.fc-timegrid-more-link {
  // background: var(--neutral-lighter-button-bg);
  color: var(--primary);
}

.fc-theme-standard {
  .fc-popover-header {
    color: var(--primary);
  }

  td,
  th {
    border: 1px solid var(--border-color) !important;
  }

  .fc-scrollgrid {
    border: 1px solid var(--border-color) !important;
  }
}

.fc {
  .fc-scroller {
    background: var(--page-background-color);
  }

  .fc-daygrid-day-top {
    flex-direction: row;
  }

  .fc-button-primary {
    color: var(--fc-button-text-color, #fff) !important;
    background-color: var(--primary-button-bg) !important;
    border: none !important;
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    color: var(--fc-button-text-color, #fff) !important;
    background-color: var(--primary-alt) !important;
  }

  .fc-button-primary:not(:disabled):active:focus,
  .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none !important;
  }

  .fc-button-primary:focus {
    box-shadow: none !important;
  }

  .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    color: var(--primary);
  }

  .fc-timegrid-axis {
    background: var(--page-background-color);
  }

  .fc-scrollgrid-sync-inner {
    color: var(--page-text-color);
    // background: var(--page-background-color);
  }

  .fc-timegrid-col.fc-day-today,
  .fc-daygrid-day.fc-day-today {
    background-color: var(--table-active-row-bg);
  }

  .fc-v-event {
    background: var(--primary-alt);
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
  }
}
</style>
