import api from '@api'

function slaHistory(sla) {
  return {
    id: sla.id,
    slaId: sla.slaId,
    slaName: sla.slaName,
    slaType: sla.slaType,
    target: sla.target,
    targetStatus: sla.targetStatus,
    updatedBy: sla.updatedBy,
    elapsedTime: sla.elapsedTime,
    dueIn: sla.dueIn,
    slaPercentage: sla.slaPercentage,
    overdue: sla.overdue,
    startTime: sla.startTime,
    lastPauseTime: sla.lastPauseTime,
    stopTime: sla.stopTime,
    pauseDuration: sla.pauseDuration,
    breachedDuration: sla.breachedDuration,
    technicianGroup: sla.technicianGroup,
    operationalHourType: sla.operationalHourType,
    penaltyAmount: sla.penaltyAmount,
    currencyId: sla.currencyId,
    updateCount: sla.updateCount,
    dueBy: sla.dueBy,
  }
}

export function getSlaHistory(moduleName, id, pageSize, offset) {
  return api
    .post(
      `${moduleName}/${id}/slahistory/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: pageSize || 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(slaHistory),
        total: data.totalCount,
      }
    })
}

export function getSlaTotalPenaltyApi(moduleName, id) {
  return api.get(`${moduleName}/${id}/slahistory/totalpenalty`)
}
