import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformNotification,
  transformNotificationForServer,
} from '@data/notification'
import { isPortalLogin } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getNotificationsApi(pageSize, offset) {
  return api
    .get(`${isPortalLogin() ? '/cportal' : ''}/webnotification`, {
      params: {
        size: pageSize === undefined ? 1000 : pageSize,
        offset: offset || 0,
        drawer: true,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformNotification),
        total: data.totalCount,
      }
    })
}

export function removeNotificationsApi(data) {
  return api.delete(
    `${isPortalLogin() ? '/cportal' : ''}/webnotification/${data.id}`,
    {
      message: __rootT('removed_successfully', {
        resource: __rootTc('notifications'),
      }),
    }
  )
}

export function markAsReadNotificationsApi(data) {
  const updateData = transformNotificationForServer(data)
  return api
    .patch(
      `${isPortalLogin() ? '/cportal' : ''}/webnotification/${data.id}`,
      { read: updateData.read },
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('notifications'),
        }),
      }
    )
    .then(transformNotification)
}

export function markAllAsReadNotificationsApi() {
  return api
    .post(`${isPortalLogin() ? '/cportal' : ''}/webnotification/markAllAsRead`)
    .then(transformNotification)
}

export function getNotificationCountApi() {
  return api.get(`${isPortalLogin() ? '/cportal' : ''}/webnotification/count`)
}
export function clearAllNotificationsApi() {
  return api.delete(
    `${isPortalLogin() ? '/cportal' : ''}/webnotification/clear`,
    {
      message: __rootT('removed_successfully', {
        resource: __rootTc('notifications'),
      }),
    }
  )
}
export function getSupportedNotificationsApi() {
  return api.get(
    `${
      isPortalLogin() ? '/cportal' : ''
    }/webnotification/supportedNotifications`
  )
}
