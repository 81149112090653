<template>
  <FlotoCrudContainer
    ref="crudContainer"
    :fetch-fn="baseFetchFn"
    :immediate="immediate"
    :columns="columns"
    :prepend-new-item="prependNewItem"
    v-bind="$attrs"
    @toggle-select-all="handleToggleSelectAll"
    @prepend-item="handleAddNewItem"
    @append-item="handleAddNewItem"
    @remove-item="handleRemoveItem"
    @update-item="handleUpdateItem"
    @selection-change="$emit('selection-change', $event)"
    v-on="listeners"
  >
    <template v-slot:add-controls="slotData">
      <slot
        name="add-controls"
        v-bind="slotData"
        :filter="filterResults"
        :search-term="searchTerm"
        :reset-filter="resetFilter"
      />
    </template>
    <template v-slot:error="errorSlotData">
      <slot v-bind="errorSlotData" :reset="fetchData">
        <ErrorShower :error="error" @remove-error="reset" />
      </slot>
    </template>
    <template v-for="(_, slotName) in slots" v-slot:[slotName]="slotScopeData">
      <slot :name="slotName" v-bind="slotScopeData" />
    </template>
  </FlotoCrudContainer>
</template>

<script>
import FindIndex from 'lodash/findIndex'
import Omit from 'lodash/omit'
import OrderBy from 'lodash/orderBy'
import Throttle from 'lodash/throttle'
import { searchList } from '@utils/arr'

export default {
  name: 'PaginatedCrud',
  props: {
    fetchFn: { type: Function, required: true },
    searchThresold: { type: Number, default: 0.2 },
    immediate: { type: Boolean, default: false },
    columns: { type: Array, default: undefined },
    defaultSearchTerm: { type: String, default: undefined },
    prependNewItem: { type: Boolean, default: false },
    sortFns: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      error: null,
      items: [],
      searchTerm: this.defaultSearchTerm,
    }
  },
  computed: {
    slots() {
      return Omit(this.$scopedSlots, ['add-controls'])
    },
    listeners() {
      return Omit(this.$listeners, [
        'toggle-select-all',
        'prepend-item',
        'append-item',
        'remove-item',
        'update-item',
        'selection-change',
      ])
    },
  },
  created() {
    this.refresh = Throttle(this.refresh, 750)
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    showCreateForm(defaultValue) {
      this.$refs.crudContainer.showCreateForm(defaultValue)
    },
    // getSelectedItems() {
    //   const items = [...this.items]
    //   const selection = [...(this.$refs.crudContainer.getSelection() || [])]
    //   return items.filter((i) => selection.indexOf(i.id) >= 0)
    // },
    handleToggleSelectAll(isSelected) {
      // this.$refs.crudContainer.setSelection(
      //   isSelected ? this.items.map(({ id }) => id) : []
      // )
    },
    fetchData(refreshCurrentPage = false) {
      this.error = null
      this.$refs.crudContainer.startLoading()
      return this.fetchFn().then((response) => {
        this.items = Object.freeze(response)
        // this.$refs.crudContainer.setSelection([])
        if (refreshCurrentPage) {
          return this.$refs.crudContainer.refreshCurrentPage()
        }
        return this.$refs.crudContainer.refresh()
      })
    },
    filterResults(searchTerm) {
      this.searchTerm = searchTerm
      this.refresh()
    },
    refresh() {
      this.fetchData()
    },
    refreshCurrentPage() {
      this.fetchData(true)
    },
    resetFilter() {
      this.searchTerm = undefined
      this.$refs.crudContainer.refresh()
    },
    baseFetchFn(pageSize, offset, sort) {
      if (this.error) {
        return Promise.reject(this.error)
      }
      let items = this.items

      // apply filter to items
      if (this.searchTerm && this.columns && this.columns.length) {
        const keysToUse = this.columns
          .filter((column) => column.searchable)
          .map((c) => c.key)
        if (keysToUse.length) {
          items = searchList(
            items,
            this.searchTerm,
            keysToUse,
            this.searchThresold
          )
        }
      }

      // apply sorting to items
      if (sort) {
        const sortOrder = sort.indexOf('-') >= 0 ? 'asc' : 'desc'
        if (this.sortFns[sort]) {
          items = this.sortFns[sort](items, sortOrder)
        } else {
          // default sorting functionality
          const sortColumn = sort.replace('-', '')
          items = OrderBy(
            items,
            // provide case insenstive sorting
            [(item) => (item[sortColumn] || '').toLowerCase()],
            [sortOrder]
          )
        }
      }

      return Promise.resolve({
        items: items.slice(offset, offset + pageSize),
        total: items.length,
      })
    },
    handleAddNewItem(item) {
      if (this.prependNewItem) {
        this.items = Object.freeze([item, ...this.items])
      } else {
        this.items = Object.freeze([...this.items, item])
      }
    },
    handleUpdateItem(item) {
      const i = FindIndex(this.items, { id: item.id })
      if (i !== -1) {
        this.items = Object.freeze([
          ...this.items.slice(0, i),
          item,
          ...this.items.slice(i + 1),
        ])
      }
    },
    handleRemoveItem(item) {
      this.items = Object.freeze(this.items.filter((i) => i.id !== item.id))
    },
  },
}
</script>
