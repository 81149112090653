<template>
  <FlotoContentLoader :loading="loading">
    <MRow>
      <MCol>
        <MRow>
          <MCol :size="6">
            <FlotoFormItem
              v-model="resource.subject"
              disabled
              :label="$tc('subject')"
            />
          </MCol>
        </MRow>
        <MRow>
          <MCol>
            <FlotoFormItem :label="$tc('description')">
              <FlotoRichEditor
                v-model="resource.description"
                :no-bg-white="true"
                disabled
                class="ant-input-disabled"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol>
            <FlotoFormItem :label="$tc('project')" rules="required">
              <FlotoDropdownPicker
                v-model="integration.projectKey"
                as-input
                :options="projectOptions"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol>
            <FlotoFormItem :label="$tc('issue_type')" rules="required">
              <FlotoDropdownPicker
                v-model="integration.issueType"
                as-input
                :options="issueTypeOptions"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol>
            <FlotoFormItem :label="$tc('priority')" rules="required">
              <FlotoDropdownPicker
                v-model="integration.priority"
                as-input
                :options="priorityOptions"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </FlotoContentLoader>
</template>

<script>
import { getJiraIssueInfoApi } from './api'
export default {
  name: 'IntegrationForm',
  props: {
    integration: { type: Object, required: true },
    resource: { type: Object, required: true },
    groupId: { type: Number, default: 0 },
  },
  data() {
    return {
      formData: {},
      metadata: {},
      loading: false,
    }
  },
  computed: {
    projectOptions() {
      const options = this.metadata.projects ? this.metadata.projects : []
      return options.map((o) => ({ key: o.key, text: o.name }))
    },
    issueTypeOptions() {
      const options = this.metadata.issueTypes ? this.metadata.issueTypes : []
      return options.map((o) => ({ key: o.id, text: o.description }))
    },
    priorityOptions() {
      const options = this.metadata.priorities ? this.metadata.priorities : []
      return options.map((o) => ({ key: o.id, text: o.name }))
    },
  },
  created() {
    this.loading = true
    getJiraIssueInfoApi().then((metadata) => {
      this.metadata = metadata
      this.loading = false
    })
  },
}
</script>
