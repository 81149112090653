import Bus from '@utils/emitter'
import { getCurrentUser } from '@utils/auth'
import store from '@state/store'
import UniqBy from 'lodash/uniqBy'
import api from '@api'
export default class FormHelper {
  _form = null

  _isFormAborted = false

  _formAbortMessage = null

  _users = []

  getValue(field) {
    if (this._form) {
      return new Promise((resolve) => {
        const timer = setTimeout(() => {
          resolve(null)
        }, 1500)
        Bus.$once('form:helper:get:value:response', (value) => {
          clearTimeout(timer)
          resolve(value)
        })
        Bus.$emit('form:helper:get:value', { field })
      })
    }
    return Promise.resolve(null)
  }

  getUser(id) {
    if (this._form && (this._users || []).length) {
      return this._users.find((r) => r.id === id) || {}
    }
  }

  setValue(field, value) {
    if (this._form) {
      Bus.$emit('form:helper:set:value', { field, value })
    }
  }

  clearValue(field) {
    this.setValue(field, undefined)
  }

  setOptions(field, options) {
    if (this._form) {
      Bus.$emit('form:helper:set:options', { field, options })
    }
  }

  apiCall(method, url, data) {
    return api[method](url, data)
  }

  hideOptions(fieldKey, optionIds) {
    if (this._form) {
      Bus.$emit('form:helper:hidden:options', {
        fieldKey,
        actionKey: 'hiddenOptionsKeys',
        actionValue: optionIds,
      })
    }
  }

  showOptions(fieldKey, optionIds) {
    if (this._form) {
      Bus.$emit('form:helper:visible:options', {
        fieldKey,
        actionKey: 'visibleOptionsKeys',
        actionValue: optionIds,
      })
    }
  }

  enable(field) {
    if (this._form) {
      Bus.$emit('form:helper:set:enable', { field, value: false })
    }
  }

  disable(field) {
    if (this._form) {
      Bus.$emit('form:helper:set:enable', { field, value: true })
    }
  }

  setForm(form) {
    this._form = form
  }

  abort(message = null) {
    this._isFormAborted = true
    this._formAbortMessage = message
  }

  resetAbort() {
    this._formAbortMessage = null
    this._isFormAborted = false
  }

  getAbortMessage() {
    return this._formAbortMessage
  }

  canSubmit() {
    return this._isFormAborted !== true
  }

  prepairFielValueDetails(item) {
    if (item) {
      const userFields = (
        (store.getters['form/user/form'] || {}).fields || []
      ).filter((f) => !f.isSystemField)
      const fieldMap = {}
      userFields.forEach((f) => {
        fieldMap[f.id] = f.title.toLowerCase()
      })
      const fieldValueDetails = {}
      Object.keys(item.fieldValueDetails || {}).map((key) => {
        fieldValueDetails[fieldMap[key]] = item.fieldValueDetails[key]
      })
      return fieldValueDetails
    }
    return {}
  }

  getLoggedInUser() {
    const user = getCurrentUser()
    const fieldValueDetails = this.prepairFielValueDetails(user)
    return {
      ...user,
      ...fieldValueDetails,
    }
  }

  addRequester(item) {
    const fieldValueDetails = this.prepairFielValueDetails(item)
    const users = [...this._users, { ...item, ...fieldValueDetails }]
    this._users = UniqBy(users, 'id')
  }

  // invalidateField(fieldName, error) {
  //   if (this._form && this._form.$refs.observer.refs[fieldName]) {
  //     this._form.$refs.observer.refs[fieldName].setErrors(
  //       error ? [error] : ['Invalid value']
  //     )
  //   }
  // }
}
