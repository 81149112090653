<template>
  <a
    :class="{
      'mx-2': iconName !== 'question',
      'ml-2': iconName === 'question',
    }"
    v-on="$listeners"
  >
    <MIcon
      :style="{ visibility: iconName === 'question' ? 'hidden' : 'visible' }"
      type="fal"
      :name="iconName"
      class="text-neutral"
    />
  </a>
</template>

<script>
export default {
  name: 'TreeNodeToggle',
  props: {
    state: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    iconName() {
      const state = this.state
      if (state === 'closed') {
        return 'chevron-right'
      } else if (state === 'opened') {
        return 'chevron-down'
      }
      return 'question'
    },
  },
}
</script>
