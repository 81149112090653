<template>
  <a @click="trigger">
    <slot name="trigger" />
    <FlotoDrawerForm
      :open="showForm"
      width="60%"
      @submit="handleFormSubmit"
      @cancel="showForm = false"
    >
      <template v-slot:header>
        {{ $t('locate_on_map') }}
      </template>
      <MRow :gutter="0">
        <MCol :size="12">
          <LeafletGeoMap
            :all-locations="
              (preparedLocations || []).length ? preparedLocations : []
            "
            :coordinates="coordinates || {}"
            style="width: 100%; height: 765px"
            :allow-location-selection="allowLocationSelection"
            :allow-tooltip="allowTooltip"
            :allow-search="allowSearch"
            :tooltip-data="tooltipData"
            :allow-asset-detail-view="allowAssetDetailView"
            @change="handleCurrentPosition"
          />
        </MCol>
      </MRow>
      <template v-slot:actions="{ hide, submit }">
        <MButton class="mr-2" :loading="processing" @click="submit">
          {{ $t('done') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { getGeolocationCoordinatesApi } from '../modules/asset/asset-api'
import LeafletGeoMap from '@src/components/map/leaflet-geo-map.vue'
export default {
  name: 'GeolocationDrawer',
  components: { LeafletGeoMap },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    disabled: { type: Boolean, default: false },
    selectedItemIds: {
      type: Array,
      default() {
        return []
      },
    },
    getLocations: { type: Boolean, default: false },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    allowLocationSelection: { type: Boolean, default: false },
    coordinates: {
      type: Object,
      default() {
        return {}
      },
    },
    allLocations: {
      type: Array,
      default() {
        return []
      },
    },
    // eslint-disable-next-line
    allowTooltip: { type: Boolean, default: true },
    allowSearch: { type: Boolean, default: false },
    allowAssetDetailView: { type: Boolean, default: false },
    tooltipData: { type: String, default: undefined },
  },
  data() {
    return {
      processing: false,
      currentPosition: {},
      showForm: false,
      preparedLocations: [],
    }
  },
  created() {
    if (this.getLocations) {
      this.getGeolocationCoordinates()
    } else {
      this.preparedLocations = this.allLocations
    }
  },
  methods: {
    trigger() {
      this.$emit('trigger')
      setTimeout(() => {
        this.showForm = true
      }, 400)
    },
    handleFormSubmit() {
      this.$emit('getGeolocationDetails', this.currentPosition)
      this.showForm = false
    },
    handleCurrentPosition(position) {
      const detailAddress = position.address.split(',')
      this.currentPosition = {
        ...this.location,
        name: detailAddress[0],
        description: position.address,
        latitude: position.position.lat,
        longitude: position.position.lng,
      }
    },
    getGeolocationCoordinates() {
      let filters = {}
      if (this.selectedItemIds) {
        filters.ids = this.selectedItemIds
      }
      return getGeolocationCoordinatesApi(filters).then((data) => {
        this.preparedLocations = data.items.map((i) => ({
          id: i.id,
          latLng: {
            lat: i.latitude,
            lng: i.longitude,
          },
          locationName: i.locationName,
          assetId: i.name,
          assetType: i.assetTypeId,
          assetName: i.displayName,
        }))
      })
    },
  },
}
</script>
