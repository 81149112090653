<template>
  <div
    class="flex flex-col"
    :class="{
      'bordered-title': bordered,
    }"
  >
    <ResourceListTitle
      :name="resource.name"
      :subject="resource.subject"
      :created-at="resource.createdAt"
      :requester-name="resource.requesterData.name"
      :requester-email="resource.requesterData.email"
      open-in-new-tab
      :link="useLink && link"
      :user-id="resource.technicianId"
    />
    <div class="mt-2 flex">
      <div class="flex-1 flex items-center">
        <MTooltip>
          <template v-slot:trigger>
            <MIcon name="status" size="lg" class="mr-2 text-neutral-light" />
          </template>
          {{ $t('status') }}
        </MTooltip>
        <ProjectStatusPicker
          v-if="moduleName === $constants.PROJECT"
          disabled
          :value="resource.projectStatus"
        />
        <FlotoStatusPicker
          v-else
          :module-name="moduleName"
          :value="resource.statusId"
          disabled
        />
      </div>
      <div class="flex-1 flex items-center">
        <MTooltip>
          <template v-slot:trigger>
            <MIcon name="priority" size="lg" class="mr-2 text-neutral-light" />
          </template>
          {{ $t('priority') }}
        </MTooltip>
        <FlotoPriorityPicker
          :value="resource.priorityId"
          disabled
          placeholder="---"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectStatusPicker from '@components/data-picker/project-status-picker'
import ResourceListTitle from './resource-list-title'

export default {
  name: 'ResourceListTitleWithStatus',
  components: { ResourceListTitle, ProjectStatusPicker },
  props: {
    moduleName: { type: String, required: true },
    resource: { type: Object, required: true },
    useLink: { type: Boolean, default: false },
    link: { type: [String, Object], default: undefined },
    bordered: { type: Boolean, default: false },
  },
}
</script>

<style lang="less" scoped>
.bordered-title {
  @apply p-2 rounded;

  border: 1px solid var(--border-color);
}
</style>
