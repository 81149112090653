<script>
import Pick from 'lodash/pick'
import Uniq from 'lodash/uniq'
import IntersectionWith from 'lodash/intersectionWith'
import IsEqual from 'lodash/isEqual'
import { PreferenceComputed } from '@state/modules/preference'
import {
  getSuggestedFieldValueApi,
  smartSuggestionsApi,
} from '@modules/smart-suggestions/smart-suggestions-api'
import { LicenseComputed } from '@state/modules/license'

export default {
  name: 'SuggestedFieldValuesProvider',
  provide() {
    const suggestedFieldValuesContext = {
      name: 'suggestedFieldValue',
      getsuggestedFieldValues: this.getsuggestedFieldValues,
    }
    Object.defineProperty(suggestedFieldValuesContext, 'suggestedFieldValues', {
      enumerable: true,
      get: () => {
        return this.suggestedFieldValues
      },
    })
    return { suggestedFieldValuesContext }
  },
  props: {
    resourceList: { type: Array, required: true },
    moduleName: { type: String, required: true },
    checkListingColumnsCondition: { type: Boolean, default: false },
  },
  data() {
    this.allowedModules = [this.$constants.REQUEST]
    this.fieldMap = {
      category: 'categoryId',
      technician_group: 'groupId',
    }
    return {
      suggestedFieldValues: [],
      smartSuggestionEnabledFields: [],
    }
  },
  computed: {
    ...Pick(PreferenceComputed, ['defaultListingColumns']),
    ...LicenseComputed,
  },
  watch: {
    resourceList: {
      immediate: true,
      handler: 'getsuggestedFieldValues',
    },
    smartSuggestionEnabledFields: {
      immediate: true,
      handler: 'getsuggestedFieldValues',
    },
  },
  created() {
    this.getSuggestions()
  },
  methods: {
    getsuggestedFieldValues() {
      if (!this.aiEnabled) {
        return
      }
      if (this.allowedModules.indexOf(this.moduleName) === -1) {
        return
      }
      const isAvailable = IntersectionWith(
        this.smartSuggestionEnabledFields,
        this.defaultListingColumns(this.moduleName),
        IsEqual
      )
      const isQulified = this.checkListingColumnsCondition
        ? isAvailable.length
        : true
      if (isQulified) {
        const resourceIds = []
        this.smartSuggestionEnabledFields.forEach((field) => {
          this.resourceList.forEach((resource) => {
            if (!resource[field]) {
              resourceIds.push(resource.id)
            }
          })
        })
        const ids = Uniq(resourceIds)
        getSuggestedFieldValueApi(this.moduleName, ids).then((data) => {
          this.suggestedFieldValues = data
        })
      }
    },
    getSuggestions() {
      if (!this.aiEnabled) {
        return
      }
      if (this.allowedModules.indexOf(this.moduleName) === -1) {
        return
      }
      return smartSuggestionsApi(this.moduleName).then((data) => {
        this.smartSuggestionEnabledFields = data.fields.map((f) => {
          return this.fieldMap[f]
        })
      })
    },
  },
  render() {
    return this.$scopedSlots.default && this.$scopedSlots.default({})
  },
}
</script>
