<template>
  <BulkActionMenu :actions="actions">
    <template v-slot:allocate="{ hide }">
      <Allocate
        :module-name="moduleName"
        @handle-allocate-action="handleAllocateAction"
        @trigger="hide"
      />
    </template>
  </BulkActionMenu>
</template>

<script>
import BulkActionMenu from '@components/bulk-action-menu'
import { updateSoftwareLicenseApi } from '@modules/software-license/software-license-api'
import Allocate from './allocate'

export default {
  name: 'BulkActions',
  components: {
    BulkActionMenu,
    Allocate,
  },
  props: {
    moduleName: { type: String, required: true },
    resourceId: { type: Number, default: undefined },
    mode: { type: String, default: undefined },
    selectedItemIds: { type: Array, required: true },
    items: { type: Array, required: true },
    toggleSelectAll: { type: Function, required: true },
    refreshList: { type: Function, required: true },
  },
  computed: {
    selectedItems() {
      return this.selectedItemIds.map((id) =>
        (this.items || []).find((i) => i.id === id)
      )
    },
    actions() {
      let actions = []
      if (this.mode === 'license_installation') {
        actions = ['allocate']
      }
      return actions
    },
  },
  methods: {
    unselectAll() {
      this.toggleSelectAll()
    },
    handleAllocateAction() {
      return updateSoftwareLicenseApi({
        id: this.resourceId,
        allocatedAssetIds: this.selectedItemIds,
        appendAllocatedAssetIds: true,
      }).then(() => {
        this.$emit('refresh')
        this.refreshList()
      })
    },
  },
}
</script>
