<template>
  <div class="flex flex-1 flex-col">
    <div v-if="activeEmoji.description" class="text-netural-lightest font-thin">
      <MTag :closable="false">
        {{ activeEmoji.description }}
      </MTag>
    </div>
    <div class="flex cursor-pointer">
      <template v-for="scale in ratingScales">
        <div
          :key="scale.scaleNumber"
          :class="{
            active:
              ratingType === 'star'
                ? scale.scaleNumber <= value
                : scale.scaleNumber === value,
          }"
          class="sigle-emoji"
          @click="handleChange(scale.scaleNumber)"
        >
          <MTooltip>
            <template v-slot:trigger>
              <img
                v-if="ratingType === 'custom'"
                :src="scale.emojiFileSrc"
                style="height: 22px"
              />
              <span v-else>{{ scale.emoji }}</span>
            </template>
            {{ scale.description }}
          </MTooltip>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { MTooltip } from '../../ui'

export default {
  name: 'RatingPicker',
  components: { MTooltip },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    ratingScales: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    ratingType: { type: String, required: true },
  },
  computed: {
    activeEmoji() {
      return this.ratingScales.find((s) => s.scaleNumber === this.value) || {}
    },
  },
  methods: {
    handleChange(value) {
      if (this.disabled) {
        return
      }
      this.$emit('change', value)
    },
  },
}
</script>
<style lang="less" scoped>
.sigle-emoji {
  @apply mr-2;

  font-size: 22px;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
}
</style>
