<template>
  <a @click="trigger">
    <slot>
      <MIcon name="filter" />
    </slot>
    <FlotoDrawer
      :open="isDrawerOpen"
      width="65%"
      :wrap-style="{ zIndex: 1052 }"
      @hide="handleHide"
    >
      <template v-slot:title>
        {{ $tc(type, 2) }}
      </template>
      <UserList
        searchable
        selectable
        scrollable
        :type="type"
        :fetch-fn="fetchUserFn"
        v-bind="$attrs"
        :max-allowed-selection="multiple ? undefined : 1"
        @selection-change="selectionChange"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          :disabled="!selectedItems.length"
          variant="primary"
          class="mr-2"
          @click="handleAddAction"
        >
          {{ $t('add') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>
<script>
import UserList from '@components/item-selection-list/user-list'
import { getUsersApi } from '@modules/users/users-api'

export default {
  name: 'UserSelectionDrawer',
  components: { UserList },
  props: {
    type: { type: String, required: true },
    multiple: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      selectedItems: [],
    }
  },
  methods: {
    fetchUserFn(type, searchCriterias, limit, offset) {
      const filter = {}
      if (this.hiddenOptionsKeys && this.hiddenOptionsKeys.length) {
        filter.excludedIds = this.hiddenOptionsKeys
      }
      if (this.visibleOptionsKeys && this.visibleOptionsKeys.length) {
        filter.ids = this.visibleOptionsKeys
      }
      return getUsersApi(type, filter, searchCriterias, limit, offset).then(
        (data) => {
          const filterData = this.$attrs['filter-data'] || {}
          if (
            Object.keys(filterData).length &&
            filterData.key &&
            filterData.value
          ) {
            data.items = data.items.filter(
              (o) => o[filterData.key] === filterData.value
            )
          }
          if (this.$attrs['group-id'] && this.type === 'technician') {
            data.items = data.items.filter(
              (o) => (o.groups || []).indexOf(this.$attrs['group-id']) >= 0
            )
          }
          return data
        }
      )
    },
    handleAddAction() {
      const selectedItemIds = this.selectedItems.map((i) => i.id) || []
      const value = this.multiple ? selectedItemIds : selectedItemIds[0]
      this.$emit(
        'submit',
        value,
        (this.selectedItems || []).filter((e) => e)
      )
      this.isDrawerOpen = false
      this.selectedItems = []
    },
    selectionChange(items) {
      this.selectedItems = items
    },
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 400)
      this.$emit('trigger')
    },
    handleHide() {
      setTimeout(() => {
        this.isDrawerOpen = false
        this.selectedItems = []
      }, 400)
    },
  },
}
</script>
