var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoDrawer',{attrs:{"open":_vm.isOpen,"scrolled-content":false},on:{"hide":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{on:{"click":_vm.handleOpen}},[_c('MBadge',{attrs:{"count":_vm.count}},[_c('MButton',{staticClass:"text-neutral md-text-base",attrs:{"variant":"neutral-light","shadow":false,"shape":"circle"}},[_c('MIcon',{attrs:{"name":"bell"}})],1)],1)],1)]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('notifications'))+" ")]},proxy:true},{key:"actions",fn:function(ref){
var hide = ref.hide;
return [_c('MButton',{attrs:{"variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}])},[_c('MRow',{staticClass:"h-100"},[_c('MCol',{staticClass:"flex flex-col h-100"},[_c('div',{staticClass:"mr-2 flex-col flex"},[_c('div',{staticClass:"flex justify-end mb-2"},[_c('NotificationSetting')],1)]),_c('FlotoScrollView',[_c('div',{staticClass:"mr-2 flex-col flex"},[_c('MRow',{staticClass:"flex-1 text-left",attrs:{"gutter":0}},[_c('MCol',{staticClass:"flex flex-col"},[_c('FlotoCrudContainer',{ref:"crudContainer",attrs:{"fetch-fn":_vm.getNotifications,"per-page":20,"scrollable":false,"update-fn":_vm.updateNotifications,"delete-fn":_vm.deleteNotifications,"paging":false},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('MRow',{staticClass:"mb-2",attrs:{"gutter":0}},[_c('MCol',{staticClass:"flex justify-end",attrs:{"size":12}},[_c('div',{staticStyle:{"display":"inline-flex"}},[_c('a',{staticClass:"text-right mb-1 md-text-xs",on:{"click":_vm.markAllAsRead}},[_vm._v(" "+_vm._s(_vm.$tc('mark_all_as_read'))+" ")]),_c('span',{staticClass:"mx-1 text-primary"},[_vm._v("|")]),_c('a',{staticClass:"text-right mb-1 md-text-xs",on:{"click":_vm.clearAllNotification}},[_vm._v(" "+_vm._s(_vm.$tc('clear_all'))+" ")])])])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
var update = ref.update;
var remove = ref.remove;
return [_c('div',{staticClass:"hierarchy-item flex items-center"},[_c('div',{staticClass:"notification-item-container flex flex-1 items-center min-w-0 mb-2 mr-0 with-bg"},[_c('Item',{attrs:{"item":item,"type":"notification"},on:{"remove":function($event){return remove(item)},"markAsRead":function($event){return _vm.markAsRead(item, update)}}})],1)])]}}])})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }