<template>
  <div>
    <template v-for="(part, index) in notificationParts">
      <FlotoLink
        v-if="part.link"
        :key="part.text"
        target="_blank"
        :to="part.link"
      >
        {{ part.text }}
      </FlotoLink>
      <FlotoUserDrawer v-else-if="part.user" :key="index" :user="part.user" />
      <FlotoTaskDrawer
        v-else-if="part.task"
        :key="index"
        :task-id="part.task.id"
        disabled
      >
        <template v-slot:trigger="{ open }">
          <a @click="open">
            {{ part.text }}
          </a>
        </template>
      </FlotoTaskDrawer>
      <a
        v-else-if="part.externalLink"
        :key="part.text"
        :href="part.externalLink"
        target="_blank"
      >
        {{ part.text }}
      </a>
      <template v-else>
        {{ part.text }}
      </template>
    </template>
  </div>
</template>

<script>
import Replace from 'lodash/replace'
import { getDetailLinkForModule } from '@utils/get-module-detail-link'

export default {
  name: 'NotificationTitle',
  props: {
    text: { type: String, required: true },
    links: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    notificationParts() {
      if ((this.links || []).length) {
        const markedString = this.links.reduce((result, link) => {
          return Replace(` ${result}`, ` ${link.text}`, ` ##${link.text}## `)
        }, this.text)
        const notificationParts = markedString
          .split('##')
          .filter(Boolean)
          .map((part) => {
            let obj = { text: part }
            const linkPart = this.links.find(({ text }) => text === part)
            if (linkPart) {
              obj = {
                ...obj,
                ...getDetailLinkForModule(
                  linkPart.model,
                  linkPart.id,
                  this.$modules,
                  linkPart.type
                ),
              }
            }
            return obj
          })
        return notificationParts
      }
      return [{ text: this.text }]
    },
  },
}
</script>
