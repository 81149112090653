import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __tc = getRootPluaralTranslator()

export const slaTypeOptions = () => [
  { text: __tc('sla'), key: 'sla' },
  { text: __tc('ola'), key: 'ola' },
  { text: __tc('slaModule.uc'), key: 'uc' },
]

export const slaTargateTypeOptions = () => [
  { text: __tc('first_response'), key: 'first_response' },
  { text: __tc('resolution'), key: 'resolution' },
]

export const slaTargateStatusOptions = () => [
  { text: __tc('running'), key: 'running' },
  { text: __tc('achieved'), key: 'achieved' },
  { text: __tc('breached'), key: 'breached' },
  { text: __tc('paused'), key: 'paused' },
  { text: __tc('canceled'), key: 'canceled' },
]

export const slaOperationalHourTypeOptions = () => [
  { text: __tc('slaModule.calendar_hours'), key: 'calendar_hours' },
  { text: __tc('slaModule.business_hours'), key: 'business_hours' },
]
