<template>
  <MCol>
    <div>
      <small class="text-neutral">{{ $t('service_catalog') }}</small>
    </div>
    <ServiceCatalogPicker
      v-model="formData.serviceCatalogId"
      :allow-clear="false"
      v-bind="$attrs"
      as-input
      @selected="handleSelectCatalog"
    />
    <div class="pt-2">
      <small class="text-neutral">{{ $tc('field') }}</small>
    </div>
    <FlotoDropdownPicker
      :value="formData.fieldId"
      v-bind="$attrs"
      searchable
      as-input
      :allow-clear="false"
      :options="fieldOptions"
      @change="handleFieldChange"
    />
    <ValueInput
      v-if="currentSelectedSource && Object.keys(currentSelectedSource).length"
      advanced-value-input
      :type="currentSelectedSource.inputType"
      :value-type="currentSelectedSource.valueType"
      :placeholder="currentSelectedSource.name"
      :param-name="currentSelectedSource.paramName"
      :options="
        (sourceOptions || []).filter((o) => o.archived !== true && !o.disabled)
      "
      :custom-field-type="currentSelectedSource.customFieldType"
      v-bind="$attrs"
      :operator="{}"
      :allow-decimal="currentSelectedSource.allowDecimal"
      :value="undefined"
      :to-value="undefined"
      :operators="currentSelectedSource.operators"
      :module-name="moduleName"
      :source="currentSelectedSource"
      @selected="handleValueSelected"
    />
  </MCol>
</template>

<script>
import Pick from 'lodash/pick'
import { dependentFieldOptionKeys } from '@data/dependent-field-options'
import { flattenRecursive } from '@data/recursive'
import { authComputed } from '@state/modules/auth'
import { getFormApi } from '@modules/form/form-api'
import ServiceCatalogPicker from '@components/data-picker/service-catalog-picker.vue'
import { getSearchSources } from '../sources'
import ValueInput from '../value-input.vue'
export default {
  name: 'ServiceCatalogCustomFiled',
  components: { ServiceCatalogPicker, ValueInput },
  inject: ['searchBarContext'],
  props: {
    transformSourcesFn: { type: Function, required: true },
    persistCriteria: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      fieldOptions: [],
      allCustomFiledSources: [],
      formData: {},
    }
  },
  computed: {
    ...authComputed,
    value: {
      get() {
        if (this.persistCriteria) {
          return this[`${this.moduleName}SearchCriterias`] || []
        } else {
          return this.searchCriterias || []
        }
      },
      set(data) {
        if (
          this[`${this.moduleName}UpdateSearchCriterias`] &&
          this.persistCriteria
        ) {
          return this[`${this.moduleName}UpdateSearchCriterias`](data)
        } else {
          this.searchCriterias = data.quals || data
        }
      },
    },
    currentSelectedSource() {
      if (this.formData.fieldId) {
        return this.allCustomFiledSources.find(
          (x) => `${x.paramName}` === `${this.formData.fieldId}`
        )
      }
      return undefined
    },
    sourceOptions() {
      const currentSelectedSource = this.currentSelectedSource || {}
      let options = currentSelectedSource.options
      if (
        currentSelectedSource.customFieldType === 'dropdown' &&
        !currentSelectedSource.fieldReferenceType
      ) {
        options = [{ text: this.$t('none'), key: '', id: '' }, ...options]
      }
      const archivedOpions =
        this.searchBarContext.archivedOptionsMap[
          currentSelectedSource.vuexOptionKey
        ]
      if (archivedOpions) {
        options = [...options, ...(archivedOpions || [])]
      }
      if (
        currentSelectedSource.fieldDetailsOptions &&
        currentSelectedSource.fieldDetailsOptions.length
      ) {
        options = [
          ...options,
          ...currentSelectedSource.fieldDetailsOptions.map((o) => ({
            ...o,
            disabled: true,
          })),
        ]
      }
      return options
    },
  },
  watch: {
    'formData.serviceCatalogId'(newValue) {
      this.getServiceForm(newValue)
    },
  },
  methods: {
    handleSelectCatalog({ text }) {
      this.formData = {
        ...this.formData,
        serviceCatalogName: text,
      }
    },
    handleValueSelected({ operator, value, toValue }) {
      const currentSelectedSource = {
        ...this.currentSelectedSource,
        name: `${this.currentSelectedSource.name} (${this.formData.serviceCatalogName})`,
      }
      if (currentSelectedSource.customFieldType === 'dependent') {
        const options = flattenRecursive(currentSelectedSource.options)
        currentSelectedSource.options = options.map((o) =>
          Pick(o, dependentFieldOptionKeys)
        )
        // delete currentSelectedSource.options
      }
      this.$emit('change', {
        displayName: currentSelectedSource.name,
        paramName: currentSelectedSource.paramName,
        valueType: currentSelectedSource.valueType,
        paramType: currentSelectedSource.paramType,
        operator,
        value,
        toValue,
        // add left operand additional params for dependentField is true
        ...(currentSelectedSource.inputType === 'dependent' ||
        currentSelectedSource.customFieldType === 'dependent'
          ? { leftOperandAdditionalParams: { dependentField: true } }
          : {}),
        qualContext: this.formData.serviceCatalogId,
        description: JSON.stringify(currentSelectedSource),
      })
    },
    handleFieldChange($event) {
      this.formData = {
        ...this.formData,
        fieldId: $event,
      }
    },
    getServiceForm(serviceCatalogId) {
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        serviceCatalogId,
        {
          archived: true,
        },
        this.isPortalLogin
      ).then((data) => {
        const fields = this.isPortalLogin
          ? data.fields.filter((f) => f.useOnPortal)
          : data.fields
        const onlyCustomFileds = true
        this.allCustomFiledSources = this.transformSourcesFn(
          getSearchSources(
            this.$constants.REQUEST,
            fields,
            this.searchBarContext.supportData,
            onlyCustomFileds
          )
        )
        this.fieldOptions = this.allCustomFiledSources.map((s) => ({
          key: s.paramName,
          text: s.name,
        }))
      })
    },
  },
}
</script>
