<template>
  <MPopover trigger="hover">
    <template v-slot:trigger="{ show }">
      <MButton
        variant="neutral-light"
        :shadow="false"
        shape="circle"
        class="text-neutral md-text-base mx-1"
      >
        A
      </MButton>
    </template>
    <div class="flex flex-1">
      <div class="font-handler" @click="setValueToVariables('decrease')"
        >A-</div
      >
      <div class="font-handler" @click="setValueToVariables('reset')">A</div>
      <div class="font-handler" @click="setValueToVariables('increase')"
        >A+</div
      >
    </div>
  </MPopover>
</template>

<script>
export default {
  name: 'FontSizeHandler',
  data() {
    this.topupValue = 0.25
    this.supportedVariables = [
      { key: '--text-lg', defaultValue: 1.5 },
      { key: '--text-regular', defaultValue: 1 },
      { key: '--text-sm', defaultValue: 0.8 },
      { key: '--text-xs', defaultValue: 0.6 },
    ]
    return {}
  },
  methods: {
    setValueToVariables(action) {
      if (!action) {
        return
      }
      const r = document.querySelector(':root')
      const rs = getComputedStyle(r)
      this.supportedVariables.forEach((variable) => {
        if (action === 'reset') {
          r.style.setProperty(variable.key, `${variable.defaultValue}rem`)
          return
        }
        const prevValue = +rs.getPropertyValue(variable.key).replace('rem', '')
        let newValue
        if (action === 'increase') {
          newValue = prevValue + this.topupValue
        } else if (action === 'decrease') {
          newValue = prevValue - this.topupValue
        }
        r.style.setProperty(variable.key, `${newValue}rem`)
      })
    },
    handleDecrease() {
      this.currentValue = this.currentValue - this.topupValue
    },
    handleReset() {
      this.currentValue = 0
    },
    handleIncrease() {
      // Get the root element
      const r = document.querySelector(':root')
      // Get the styles (properties and values) for the root
      const rs = getComputedStyle(r)
      const prevValue = +rs.getPropertyValue('--text-sm').replace('rem', '')
      const newValue = prevValue + this.topupValue
      r.style.setProperty('--text-sm', `${newValue}rem`)
    },
  },
}
</script>
<style lang="less" scoped>
.font-handler {
  @apply flex justify-center px-2 py-1 mx-1 rounded border border-solid border-neutral-lighter cursor-pointer;

  min-width: 30px;
  user-select: none;
}
</style>
