<template>
  <div class="flex flex-1 flex-col min-h-0">
    <div
      :class="[{ blur: isBlocked }, innerClass]"
      class="flex flex-1 flex-col min-h-0"
    >
      <slot />
    </div>
    <div
      v-if="isBlocked"
      class="blocker flex items-center justify-center flex-col flex-1"
    >
      <!-- <MLoader height="150" width="150" /> -->
      <h4 class="inline-block text-primary">{{ message }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenBlocker',
  provide() {
    const ScreenBlockerContext = {
      block: this.block,
      unblock: this.unblock,
    }
    Object.defineProperty(ScreenBlockerContext, 'isBlocked', {
      enumerable: true,
      get: () => {
        return this.isBlocked
      },
    })
    return { ScreenBlockerContext }
  },
  props: {
    innerClass: {
      type: [Object, String],
      default: undefined,
    },
  },
  data() {
    return {
      isBlocked: false,
      message: 'Loading...',
    }
  },
  methods: {
    block(message = 'Loading...') {
      this.isBlocked = true
      this.message = message
    },
    unblock() {
      this.isBlocked = false
    },
  },
}
</script>

<style lang="less" scoped>
.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  color: var(--neutral-dark) k;
  background: rgba(255, 255, 255, 0.3);

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--neutral-dark);
  }
}

.blur {
  filter: blur(3px);
}
</style>
