<template>
  <div>
    <MModal
      :open="Boolean(editingItem)"
      :width="width"
      overlay-class-name="scrollable-modal hide-header"
      @hide="$emit('cancel')"
    >
      <template v-slot:trigger>
        <span />
      </template>
      <template v-slot:title>
        <slot name="title"> {{ $t('edit') }} {{ editingItem.name }} </slot>
      </template>
      <component
        :is="detailComponent"
        :use-transition="false"
        :module-name="moduleName"
        :resource-id="editingItem.id"
        :on-change="handleChange"
        :sidebar-width="{ size: 2 }"
        :detail-width="{ size: 10 }"
        default-sidebar-visible
        @delete="handleDelete"
      />
      <template v-slot:footer>
        <MButton
          id="kanban-update-btn"
          outline
          class="mx-1"
          :loading="processing"
          @click="handleSubmit"
        >
          {{ $t('done') }}
        </MButton>
      </template>
    </MModal>
  </div>
</template>

<script>
import DetailView from '@modules/ticket/components/detail.vue'
import TaskDetailView from '@modules/my-tasks/components/detail.vue'
import ProjectDetailView from '@modules/project/components/detail.vue'
export default {
  name: 'KanbanEditModal',
  components: { DetailView, TaskDetailView, ProjectDetailView },
  props: {
    editingItem: { type: Object, default: undefined },
    moduleName: { type: String, required: true },
    processing: { type: Boolean, default: false },
  },
  data() {
    return {
      width: window.innerWidth - 300,
    }
  },
  computed: {
    detailComponent() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
          this.$constants.RELEASE,
        ].indexOf(this.moduleName) >= 0
      ) {
        return 'DetailView'
      }
      if (this.moduleName === this.$constants.PROJECT) {
        return 'ProjectDetailView'
      }
      if (this.moduleName === this.$constants.TASK) {
        return 'TaskDetailView'
      }
      return 'div'
    },
  },
  methods: {
    handleChange(data) {
      this.$emit('change', {
        ...this.editingItem,
        ...data,
      })
    },
    handleDelete() {
      this.$emit('delete')
    },
    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped></style>
