<template>
  <div>
    <ASlider
      id="slider"
      :value="value"
      :min="min"
      :max="max"
      :dots="dots"
      :disabled="disabled"
      :step="step"
      v-bind="$attrs"
      @change="handleChange"
    />
  </div>
</template>
<script>
import ASlider from 'ant-design-vue/es/slider'
import 'ant-design-vue/es/slider/style'
export default {
  name: 'SliderPicker',
  components: { ASlider },
  model: {
    event: 'update',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 10 },
    dots: { type: Boolean, default: false },
    step: { type: Number, default: 1 },
  },
  methods: {
    handleChange(v) {
      this.$emit('update', v)
    },
  },
}
</script>
<style scoped>
.code-box-demo .ant-slider {
  margin-bottom: 16px;
}
</style>
