<template>
  <div class="mt-1">
    <span v-if="slaData.name">
      {{ slaData.name }}
      {{ slaData.archived ? `(${$t('archived')})` : '' }}</span
    >
    <span v-else>{{ '---' }}</span>
    <MDivider class="mt-2 mb-0" />
  </div>
</template>
<script>
import { getSlaApi } from '@modules/sla/sla-api'
export default {
  name: 'SlaName',
  props: {
    slaId: { type: Number, default: undefined },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      slaData: {},
    }
  },
  watch: {
    slaId: {
      immediate: true,
      handler: 'getSlaData',
    },
  },
  methods: {
    getSlaData() {
      if (this.slaId) {
        return getSlaApi(this.moduleName, this.slaId, {
          params: {
            archived: true,
          },
        }).then((response) => {
          this.slaData = response
        })
      }
    },
  },
}
</script>
