<template>
  <FlotoDrawerForm
    :open="isDrawerOpen"
    @cancel="isDrawerOpen = false"
    @submit="handleFormSubmit"
  >
    <template v-slot:trigger>
      <MButton id="edit-profile" variant="neutral" @click="handleOpenProfile">
        {{ $t('edit_profile') }}
      </MButton>
    </template>
    <template v-slot:header>
      {{ user.name || user.email }}
    </template>
    <div class="flex flex-col flex-1">
      <FlotoFixedView :gutter="0">
        <div class="h-100 flex flex-col">
          <div class="flex flex-col">
            <div class="flex items-center">
              <div>
                <FlotoUserAvatar :size="70" :avatar="user.avatar" />
              </div>
              <div class="mx-4">
                <h3 class="mb-0">
                  {{ user.name }}
                </h3>
                <small v-if="user.email">
                  <a :href="`mailto:${user.email}`">
                    {{ user.email }}
                  </a>
                </small>
                <small v-else>
                  {{ user.userLogOnName }}
                </small>
                <MTag
                  v-if="user.vip"
                  size="large"
                  variant="orange"
                  :closable="false"
                  class="ml-2"
                >
                  {{ $t('vip') }}
                </MTag>
              </div>
            </div>
            <MTab v-model="currentTab">
              <MTabPane key="profile" :tab="$t('profile')" />
              <MTabPane key="preference" :tab="$tc('preference', 2)" />
              <MTabPane
                v-if="!user.ldapUser && user.loginSource !== 'sso_login'"
                key="security"
                :tab="$tc('change_password')"
              />
              <MTabPane
                v-if="!isPortalLogin && hasRequestModule"
                key="do_not_disturb"
                :tab="`${$tc('do_not_disturb')}`"
              />
              <MTabPane
                key="delegate_approval"
                :tab="`${$tc('delegate_approval')}`"
              />
            </MTab>
          </div>
          <div class="h-100 overflow-hidden">
            <FlotoScrollView>
              <div class="flex flex-col flex-wrap">
                <ProfileForm
                  v-if="currentTab === 'profile'"
                  v-model="userFormData"
                  :show-security="
                    Boolean(
                      user.ldapUser && tenantPrefrences.selfServiceSetting.value
                    )
                  "
                />

                <PreferenceForm
                  v-if="currentTab === 'preference'"
                  v-model="preferenceFormData"
                  @passwordChange="handlePasswordChange"
                />
                <ChangePasswordForm
                  v-if="currentTab === 'security'"
                  v-model="securityFormData"
                />
                <DoNotDisturbForm
                  v-if="
                    currentTab === 'do_not_disturb' &&
                    !isPortalLogin &&
                    hasRequestModule
                  "
                  v-model="userFormData"
                />
                <DelegateApprovalForm
                  v-if="currentTab === 'delegate_approval'"
                  v-model="delegateApprovalFormData"
                  :disabled="
                    !user.allowDelegateApproval &&
                    (!user.superAdminRole ||
                      myAllowedModules.indexOf('admin.users') === -1)
                  "
                />
              </div>
            </FlotoScrollView>
          </div>
        </div>
      </FlotoFixedView>
    </div>
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      :processing="false"
      @cancel="handleConfirmCancel"
    >
      <template v-slot:icon>
        <MIcon name="key" class="w-full text-primary" size="2x" />
      </template>
      <template v-slot:message>
        {{ $t('unprotected_attachment_password') }}
      </template>
      <template v-slot:actions="{ cancel }">
        <MButton id="ok-btn" @click.stop="cancel">
          {{ $t('ok') }}
        </MButton>
      </template>
    </FlotoConfirmModal>
    <template v-slot:actions="{ hide, submit }">
      <MButton
        id="update-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{ buttonText }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="hide">{{
        $t('cancel')
      }}</MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import { authComputed, authMethods } from '@state/modules/auth'
import { updateUserApi } from '@modules/users/users-api'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
import ProfileForm from './profile/profile-form'
import PreferenceForm from './profile/preference-form'
import ChangePasswordForm from './profile/change-password-form.vue'
import { changePasswordApi } from '@state/modules/auth/api'
import DelegateApprovalForm from './profile/delegate-approval-form'
import DoNotDisturbForm from './profile/do-not-disturb-form'
import { updateDelegateApprovalApi } from './profile/api'

export default {
  name: 'UserProfile',
  components: {
    ProfileForm,
    PreferenceForm,
    ChangePasswordForm,
    DelegateApprovalForm,
    DoNotDisturbForm,
  },
  data() {
    return {
      isDrawerOpen: false,
      userFormData: {},
      preferenceFormData: {},
      securityFormData: {},
      delegateApprovalFormData: {},
      currentTab: 'profile',
      processing: false,
      showModal: false,
      isPasswordChanged: false,
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...LicenseComputed,
    buttonText() {
      return this.$t('update')
    },
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
  },
  methods: {
    ...authMethods,
    ...PreferenceMethods,
    fillFormData() {
      this.userFormData = CloneDeep(this.user)
      this.securityFormData = {}
      this.delegateApprovalFormData = {}
      this.preferenceFormData = {
        pageSize: this.pageSize(),
        layout: this.defaultLayout(this.$constants.REQUEST),
        theme: this.theme,
        attachmentPassword: this.attachmentPassword,
      }
    },
    handleOpenProfile() {
      this.fillFormData()
      this.$emit('trigger')
      setTimeout(() => {
        if (this.currentTab === 'do_not_disturb') {
          this.refreshUser().then((data) => {
            if (this.userFormData.doNotDisturb !== data.doNotDisturb) {
              this.userFormData.doNotDisturb = data.doNotDisturb
            }
          })
        }
        this.isDrawerOpen = true
      }, 250)
    },
    handleFormSubmit() {
      if (
        this.currentTab === 'profile' ||
        this.currentTab === 'do_not_disturb'
      ) {
        this.processing = true
        updateUserApi(
          this.userFormData.userType,
          this.userFormData,
          this.$t('updated_successfully', { resource: this.$t('profile') })
        )
          .then((data) => {
            if (this.user.userLanguage !== data.userLanguage) {
              this.isDrawerOpen = false
              // refresh user for change language
              this.refreshUser({ pageReload: true })
            }
          })
          .finally(() => {
            this.processing = false
            this.refreshUser()
          })
      }
      if (this.currentTab === 'preference') {
        if (
          this.isPasswordChanged &&
          this.preferenceFormData.attachmentPassword === ''
        ) {
          this.showModal = true
          this.isPasswordChanged = false
        } else {
          this.handleConfirmCancel()
        }
      }
      if (this.currentTab === 'security') {
        this.processing = true
        changePasswordApi(this.securityFormData).finally(
          () => (this.processing = false)
        )
      }
      if (this.currentTab === 'delegate_approval') {
        this.processing = true
        updateDelegateApprovalApi(this.user.id, this.delegateApprovalFormData)
          .then((data) => {
            this.delegateApprovalFormData = data
          })
          .finally((this.processing = false))
      }
    },
    handleConfirmCancel() {
      this.showModal = false
      this.processing = true
      this.updatePreference({
        pageSize: this.preferenceFormData.pageSize,
        theme: this.preferenceFormData.theme,
        modulePreferences: {
          [this.$constants.REQUEST]: {
            defaultLayout: this.preferenceFormData.layout,
          },
        },
        attachmentPassword: this.preferenceFormData.attachmentPassword,
      })
        .then(() => {
          this.isDrawerOpen = false
          this.processing = false
        })
        .finally(() => (this.processing = false))
    },
    handlePasswordChange(password) {
      this.isPasswordChanged = true
    },
  },
}
</script>
