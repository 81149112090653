<template>
  <FlotoCrudContainer
    ref="crudContainerRef"
    :fetch-fn="getIntegration"
    :scrollable="false"
    :create-fn="addIntegration"
    :columns="tableColumns"
    :show-no-data="false"
    as-table
  >
    <template v-slot:add-controls="{ create }">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        v-if="!disabled"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div :class="{ 'pt-2': searchAffixed }">
          <MRow>
            <MCol class="text-right">
              <MButton variant="neutral" @click="create">
                {{ $tc('add') }} {{ $tc('integration') }}
              </MButton>
            </MCol>
          </MRow>
          <MDivider />
        </div>
      </Component>
    </template>
    <template v-slot:form-header>
      {{ $tc('add') }} {{ $tc('integration') }}
    </template>
    <template v-slot:form-items="{ item: integration }">
      <IntegrationForm
        :integration="integration"
        :group-id="groupId"
        :resource="resource"
      />
    </template>
    <template v-slot:form-actions="{ submit, cancel, item, processing }">
      <MButton :loading="processing" @click="submit">
        {{ $tc('add') }}
      </MButton>
      <MButton variant="default" class="ml-2" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:jiraIssueKey="{ item }">
      <td class="text-ellipsis">
        <a :href="item.browseUrl" target="_blank">
          {{ item.jiraIssueKey }}
        </a>
      </td>
    </template>
    <template v-slot:project="{ item }">
      <td class="text-ellipsis">{{ item.projectKey }}</td>
    </template>
    <template v-slot:issueType="{ item }">
      <td class="text-ellipsis">{{ item.issueType }}</td>
    </template>
    <template v-slot:priority="{ item }">
      <td class="text-ellipsis">{{ item.priority }}</td>
    </template>
    <template v-slot:subject="{ item }">
      <td class="text-ellipsis">{{ item.subject }}</td>
    </template>
    <template v-slot:integrationType="{ item }">
      <td class="text-ellipsis">{{ item.integrationType }}</td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'
import IntegrationForm from './integration-form'
import { getIntegrationApi, createJiraApi } from './api'

export default {
  name: 'IntegrationContainer',
  components: { IntegrationForm },
  props: {
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
    groupId: { type: Number, default: 0 },
    moduleName: { type: String, required: true },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
    resource: { type: Object, required: true },
  },
  data() {
    return {
      searchAffixed: false,
    }
  },
  computed: {
    ...authComputed,
    tableColumns() {
      return [
        { name: `${this.$tc('jira_id')}`, key: 'jiraIssueKey' },
        { name: `${this.$tc('project')}`, key: 'project' },
        { name: `${this.$t('issue_type')}`, key: 'issueType' },
        { name: `${this.$t('priority')}`, key: 'priority' },
        { name: `${this.$t('subject')}`, key: 'subject' },
        { name: `${this.$t('Application')}`, key: 'integrationType' },
      ]
    },
  },
  created() {
    const createIntegrationHandler = () => {
      this.$refs.crudContainerRef.showCreateForm()
    }
    Bus.$on('create-integration', createIntegrationHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('create-integration', createIntegrationHandler)
    })
  },
  methods: {
    getIntegration(limit, offset) {
      // @TODO Apply filters here
      return getIntegrationApi(this.resourceId, limit, offset)
    },
    addIntegration(data) {
      return createJiraApi(this.resourceId, {
        ...data,
      })
    },
  },
}
</script>
