<template>
  <FlotoDrawer :open="isOpen" :scrolled-content="false" @hide="isOpen = false">
    <template v-slot:trigger>
      <MButton
        variant="neutral-light"
        :shadow="false"
        shape="circle"
        class="text-neutral md-text-base"
        @click="isOpen = true"
      >
        <MIcon name="keyboard" />
      </MButton>
    </template>
    <template v-slot:title>
      {{ $t('shortcutHelpers.view_available') }}
    </template>
    <MRow class="h-100">
      <MCol class="flex flex-col h-100">
        <MRow>
          <MCol>
            <div class="text-neutral">
              <span class="mr-2">
                {{ $t('enable') }}
                {{ $tc('shortcutHelpers.keyboard_shortcuts') }}
              </span>
              <MSwitch
                size="small"
                :checked="shortcutEnabled"
                @change="handleToggleShortcuts"
              />
            </div>
          </MCol>
        </MRow>
        <MDivider class="my-2" />
        <MRow class="min-h-0">
          <MCol class="flex flex-col h-100">
            <FlotoScrollView>
              <table class="item-list-table w-full">
                <thead>
                  <tr>
                    <td class="sticky">
                      {{ $tc('shortcut') }}
                    </td>
                    <td class="sticky">{{ $t('description') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colspan="2"
                      class="sticky header-column"
                      style="top: 30px"
                    >
                      <b>{{ $t('shortcutHelpers.navigation') }}</b>
                    </td>
                  </tr>
                  <tr
                    v-for="(shortcut, index) in navigationShortcuts"
                    :key="`${index}-navigation`"
                  >
                    <!-- eslint-disable-next-line -->
                    <td v-html="shortcut.keyDescription" />
                    <td class="text-ellipsis">
                      {{ shortcut.description }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      hasRequestModule ||
                      hasProblemModule ||
                      hasChangeModule ||
                      (hasAssetModule && !isPortalLogin)
                    "
                  >
                    <td
                      colspan="2"
                      class="sticky header-column"
                      style="top: 30px"
                    >
                      <b>
                        {{
                          $t(
                            `shortcutHelpers.${
                              isPortalLogin
                                ? 'grid-helpers'
                                : 'grid-split-helpers'
                            }`,
                            {
                              resource: isPortalLogin ? $tc('request') : '',
                            }
                          )
                        }}
                      </b>
                    </td>
                  </tr>
                  <template
                    v-if="
                      hasRequestModule ||
                      hasProblemModule ||
                      hasChangeModule ||
                      (hasAssetModule && !isPortalLogin)
                    "
                  >
                    <tr
                      v-for="(shortcut, index) in listViewShortcuts"
                      :key="`${index}-list-view`"
                    >
                      <!-- eslint-disable-next-line -->
                      <td v-html="shortcut.keyDescription" />
                      <td class="text-ellipsis">
                        {{ shortcut.description }}
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-if="
                      hasRequestModule || hasProblemModule || hasChangeModule
                    "
                  >
                    <td
                      colspan="2"
                      class="sticky header-column"
                      style="top: 30px"
                    >
                      <b>
                        {{
                          $t('shortcutHelpers.detailed-view-helpers', {
                            resource: isPortalLogin
                              ? $tc('request')
                              : licenseWiseText(true),
                          })
                        }}
                      </b>
                    </td>
                  </tr>
                  <template
                    v-if="
                      hasRequestModule || hasProblemModule || hasChangeModule
                    "
                  >
                    <tr
                      v-for="(shortcut, index) in detailedViewShortcuts"
                      :key="`${index}-detail-view`"
                    >
                      <!-- eslint-disable-next-line -->
                      <td v-html="shortcut.keyDescription" />
                      <td class="text-ellipsis">
                        {{ shortcut.description }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </FlotoScrollView>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
    <template v-slot:actions="{ hide }">
      <MButton variant="default" @click="hide">
        {{ $t('close') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import { SHORTCUT_MAP } from '@utils/shortcuts-map'
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'

export default {
  name: 'KeyboardShortcutHelper',
  data() {
    return {
      isOpen: false,
      availableShortcuts: [],
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...authComputed,
    ...SupportPortalConfigComputed,
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
    hasProblemModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.PROBLEM) >= 0
      )
    },
    hasChangeModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.CHANGE) >= 0
    },
    hasReleaseModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.RELEASE) >= 0
      )
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    navigationShortcuts() {
      return this.availableShortcuts
        .filter((x) => {
          return x.groups.indexOf('navigate') >= 0
        })
        .map((x) => ({
          ...x,
          description:
            typeof x.description === 'function'
              ? x.description('navigate')
              : x.description,
        }))
    },
    listViewShortcuts() {
      return this.availableShortcuts
        .filter((x) => {
          return x.groups.indexOf('list-view') >= 0
        })
        .map((x) => ({
          ...x,
          description:
            typeof x.description === 'function'
              ? x.description('list-view')
              : x.description,
        }))
    },
    detailedViewShortcuts() {
      return this.availableShortcuts
        .filter((x) => {
          if (this.isPortalLogin) {
            return x.groups.indexOf('detail-page') >= 0 && x.groups.length === 1
          }
          return x.groups.indexOf('detail-page') >= 0
        })
        .map((x) => ({
          ...x,
          description:
            typeof x.description === 'function'
              ? x.description('detail-page')
              : x.description,
        }))
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setAvailableShortcuts()
        }
      },
    },
    availableModulesInLicense: {
      handler: 'setAvailableShortcuts',
    },
    myAllowedModules: {
      handler: 'setAvailableShortcuts',
    },
  },
  mounted() {
    this.setAvailableShortcuts()
    Bus.$on('show_shortcut_help', () => (this.isOpen = true))
  },
  beforeDestroy() {
    Bus.$off('show_shortcut_help')
  },
  methods: {
    ...PreferenceMethods,
    licenseWiseText(details = false) {
      const modules = []
      if (this.hasRequestModule) {
        modules.push(this.$constants.REQUEST)
      }
      if (this.hasProblemModule) {
        modules.push(this.$constants.PROBLEM)
      }
      if (this.hasChangeModule) {
        modules.push(this.$constants.CHANGE)
      }
      if (this.hasReleaseModule) {
        modules.push(this.$constants.RELEASE)
      }
      if (this.hasAssetModule && !details) {
        modules.push(this.$constants.ASSET)
      }
      return modules.map((m) => this.$tc(m)).join('/')
    },
    handleToggleShortcuts() {
      this.updatePreference({ shortcutEnabled: !this.shortcutEnabled })
    },
    setAvailableShortcuts() {
      this.availableShortcuts = Object.freeze(
        SHORTCUT_MAP()
          .filter((x) => {
            if (!x.supportedModules) {
              return true
            }
            return x.supportedModules(
              this.availableModulesInLicense,
              this.myAllowedModules,
              this.isPortalLogin,
              {
                allowRequesterToAccessKB: this.allowRequesterToAccessKB,
                allowRequesterToAccessServiceRequest:
                  this.allowRequesterToAccessServiceRequest,
                allowRequesterToAccessMyApprovals:
                  this.allowRequesterToAccessMyApprovals,
              }
            )
          })
          .map((x) => ({
            keyDescription: x.keyDescription,
            description: x.description,
            groups: x.groups,
          }))
      )
    },
  },
}
</script>

<style lang="less" scoped>
td.header-column {
  background: var(--grid-header-bg) !important;
}
</style>
